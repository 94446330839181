/*************************************************
// INCLUDE DES FONTS
*************************************************/

@import url("https://p.typekit.net/p.css?s=1&k=mtu6dvy&ht=tk&f=47839.47840.47844.47845&a=87216329&app=typekit&e=css");

@font-face {
    font-family: "transducer-extended";
    src: url("https://use.typekit.net/af/cadf16/00000000000000007735eccb/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
            format("woff2"),
        url("https://use.typekit.net/af/cadf16/00000000000000007735eccb/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
            format("woff"),
        url("https://use.typekit.net/af/cadf16/00000000000000007735eccb/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
            format("opentype");
    font-display: auto;
    font-style: italic;
    font-weight: 400;
    font-stretch: normal;
}

@font-face {
    font-family: "transducer-extended";
    src: url("https://use.typekit.net/af/f5b1f5/00000000000000007735ecce/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("woff2"),
        url("https://use.typekit.net/af/f5b1f5/00000000000000007735ecce/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("woff"),
        url("https://use.typekit.net/af/f5b1f5/00000000000000007735ecce/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
}

@font-face {
    font-family: "transducer-extended";
    src: url("https://use.typekit.net/af/d156da/00000000000000007735ecdb/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3")
            format("woff2"),
        url("https://use.typekit.net/af/d156da/00000000000000007735ecdb/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3")
            format("woff"),
        url("https://use.typekit.net/af/d156da/00000000000000007735ecdb/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3")
            format("opentype");
    font-display: auto;
    font-style: italic;
    font-weight: 600;
    font-stretch: normal;
}

@font-face {
    font-family: "transducer-extended";
    src: url("https://use.typekit.net/af/3a72c7/00000000000000007735ecd7/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
            format("woff2"),
        url("https://use.typekit.net/af/3a72c7/00000000000000007735ecd7/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
            format("woff"),
        url("https://use.typekit.net/af/3a72c7/00000000000000007735ecd7/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
            format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
}

// POLICE VARIABLE
@import url("https://p.typekit.net/p.css?s=1&k=eea0qli&ht=tk&f=51598&a=87161281&app=typekit&e=css");

@font-face {
    font-family: "transducer-variable";
    src: url("https://use.typekit.net/af/e88dea/0000000000000000774b97a8/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("woff2"),
        url("https://use.typekit.net/af/e88dea/0000000000000000774b97a8/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("woff"),
        url("https://use.typekit.net/af/e88dea/0000000000000000774b97a8/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
            format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
}

.tk-transducer-variable {
    font-family: "transducer-variable", sans-serif;
}

/*************************************************
// STYLE DES FONTS
*************************************************/

$font: "transducer-extended";

$font-variable: "transducer-variable";


$regular: 400;

$medium: 500;

$bold: 600;

$black: 900;

.regular {
    font-weight: $regular;
}

.medium {
    font-weight: $medium;
}

.bold {
    font-weight: $bold;
}

.black {
    font-weight: $black;
}

%font {
    font-family: $font;
    font-weight: normal;
    font-style: normal;
}

%font-bold {
    font-family: $font;
    font-weight: $bold;
    font-style: normal;
}

/*************************************************
// ICOMOON
*************************************************/
