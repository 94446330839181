.home {
    // VIDEO
    
    #v0 {
        z-index: -1;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        object-fit: cover;
        @media screen and (max-width:$screen-md) {
            display: none;
        }
    }

    
    position: relative;
    .p-header, .p-footer {
        display: none;
    }
    .p-nav_top_right {
        .bouton {
            height: 55px;
            p {
                font-size: 1.4rem;
            }
        }
    }
    .p-menu_nav {
        gap: 3.32vh;
    }

    &-section1 {
        .micro-big {
            height: 100vh;
            max-width: 40vw;
            object-fit: contain;
            width: auto;
            position: absolute;
            bottom: 0;
            left: 0;
            @media screen and (max-width:$screen-md) {
                width: 90vw;
                height: unset;
                max-width: unset;
            }
        }
        width: 100vw;
        @media screen and (max-width:$screen-md) {
            min-height: 100vh;
            padding-top: 100px;
        }
        .h1 {
            position: relative;
            @media screen and (max-width:$screen-md) {
                transform: none;
                @include fluid-text(18px, 23px, 1.1, 320px, 390px);
                margin: 6.5vh 0 34px;
            }
            &-container {
                @media screen and (min-width:$screen-md) {
                    position: absolute;
                    width: 100%;
                    transform: translateY(vh(300px) * -1);
                }
            }
        }
        .p-header_arrow {
            @media screen and (min-width:$screen-md) {
                transform: rotate(-90deg);
                bottom: 0;
                right: 0;
                left: unset;
            }
        }
        p {
            position: absolute;
            transform: translateY(vh(80px));
            @media screen and (max-width:$screen-md) {
                position: relative;
                transform: none;
                font-size: 14px;
            }
        }
    }

    &-section3D {
        display: flex;
        align-items: end;
        margin-left: vw(473px);
        margin-right: 10vw;
        gap: vw(473px);
        @media screen and (max-width:$screen-md) {
            flex-direction: column;
            align-items: center;
            margin: 70px 30px 100px;
            gap: 45px;
        }
        &_bloc {
            display: flex;
            align-items: center;
            flex-direction: column;
            text-align: center;
            p {
                margin: vh(20px) 0 vh(30px);
                text-align: center;
                white-space: nowrap;
                @media screen and (max-width:$screen-md) {
                    white-space: unset;
                    margin: 20px 0 37px;
                }
            }
            img {
                max-width: 70vw;
                max-height: 70vw;
                object-fit: contain;
                margin-bottom: 15px;
            }
        }
        &_lastBloc {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: vw(556px);
            height: 100%;
            @media screen and (max-width:$screen-md) {
                width: 100%;
                margin-top: 75px;
                align-items: center;
                text-align: center;
            }
            img {
                width: vw(110px);
                @media screen and (max-width:$screen-md) {
                    width: 110px;
                }
            }
            .h1 {
                margin: vh(110px) 0 vh(40px);
                @media screen and (max-width:$screen-md) {
                    margin: 40px 0 50px;
                }
            }
        }
    }
}