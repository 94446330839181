//======================================================
// ANIMATIONS
//======================================================

@keyframes upDown {
    from {
        transform: translateY(0%);
    }

    25% {
        transform: translateY(-20%);
    }

    75% {
        transform: translateY(23%);
    }

    100% {
        transform: translateY(0%);
    }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes height-100-0 {
    from {
        height: 100%;
    }

    to {
        height: 0;
    }
}

@keyframes opacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes trans-opacity-top-100 {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes trans-opacity-bot-100 {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes trans-opacity-left-100 {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes trans-opacity-right-100 {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes rebond-start {
    from {
        opacity: 0;
        transform: scale(1);
    }

    33% {
        opacity: 1;
        transform: scale(1.15);
    }

    66% {
        opacity: 1;
        transform: scale(0.95);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

//======================================================
// CHARGEMENT DU SITE + ANIM ENTRE PAGES
//======================================================

.c-transition {
    position: fixed;
    z-index: 10000;
    top: 0;
    right: 0;
    overflow: hidden;
    height: 100vh;
    width: 100%;
    transition: all 0.5s ease;

    &:after {
        display: block;
        content: "";
        height: 100%;
        width: 100%;
        background-color: $noir;
        position: absolute;
        opacity: 0;
        top: 0;
        right: 100%;
        z-index: 1;
    }

    &:before {
        display: block;
        content: "";
        height: 100%;
        width: 100%;
        background-color: $noir;
        position: absolute;
        opacity: 0;
        top: 0;
        right: 100%;
        z-index: 0;
    }

    &_wrapper {
        width: 100%;
        height: 100%;
        opacity: 1;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.5s ease;

        &_sigle {
            position: relative;
            width: 5vw;
            opacity: 0;
            z-index: 4;
            top: 0;

            @media screen and (max-width: $screen-md) {
                width: 60px;
                max-width: 80%;
            }

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    &.under {
        z-index: -999;
        opacity: 0;
    }

    &.first {
        &:after {
            background-color: $noir;
            top: 0;
            right: 0;
            opacity: 1;
        }

        &:before {
            top: 0;
            opacity: 1;
            right: 0;
            background-color: $noir;
        }

        &.anim {
            .c-transition_wrapper_sigle {
                animation: rebond-start 1s linear forwards 0.2s;
            }
        }

        &.remove {
            top: -100%;
            opacity: 0;
            transition: top 0.5s ease 0.9s, opacity 0.1s linear 1s;

            &:after {
                top: -100%;
                transition: top 0.8s ease;
            }

            &:before {
                top: -100%;
                transition: top 0.8s ease;
            }

            .c-transition_wrapper_sigle {
                top: -100%;
                transition: top 0.7s ease 0.15s;
            }
        }
    }
}

//======================================================
// PARALLAX SPEED
//======================================================

.parallax {
    transition: all 0.5s linear;

    &.speed2 {
        transition: all 0.6s linear;
    }
}

//======================================================
// WOOW EFFECT
//======================================================

.anim-opacity {
    position: relative;
    transition: all 1s;
}

.anim-opacity.masked {
    transition: none;
    opacity: 0;
}

.anim-bottom {
    position: relative;
    transition: all 1s;
}

.anim-bottom.masked {
    transition: none;
    transform: translateY(50%);
    opacity: 0;
}

.anim-right {
    position: relative;
    transition: all 1s;
}

.anim-right.masked {
    transition: none;
    transform: translateX(50%);
    opacity: 0;
}

.anim-left {
    position: relative;
    transition: all 1s;
}

.anim-left.masked {
    transition: none;
    transform: translateX(-50%);
    opacity: 0;
}

.anim-delay {
    @for $i from 1 through 5 {
        &--#{$i} {
            transition-delay: $i * 0.2s;
            z-index: $i;
        }
    }
}

// LOADER

.loader {
    z-index: 999;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    transition: all .5s ease;

    &.hide {
        opacity: 0;
        z-index: -999;
        pointer-events: none;
    }

    .barres {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        transition: all 1s ease 1s;

        &.hide {
            opacity: 0;
            gap: 5px;
        }

        .barre {
            /* will-change: transform; */
            background-color: $jaune;
            width: 4px;
            height: 10px;
            border-radius: 100vmax;
            animation: oscillation 0.8s linear alternate infinite;
            transition: all 1s ease;

            &.active {
                height: 2px !important;
            }

            &:nth-child(1) {
                transition-delay: 0.3s + 3 * 0.3s;
                animation-delay: 0.3s + 3 * 0.3s;
            }

            &:nth-child(2) {
                transition-delay: 0.3s + 2 * 0.3s;
                animation-delay: 0.3s + 2 * 0.3s;
            }

            &:nth-child(3) {
                transition-delay: 0.3s + 1 * 0.3s;
                animation-delay: 0.3s + 1 * 0.3s;
            }

            &:nth-child(4) {
                transition-delay: 0.3s + 0 * 0.3s;
                animation-delay: 0.3s + 0 * 0.3s;
            }

            &:nth-child(5) {
                transition-delay: 0.3s + 1 * 0.3s;
                animation-delay: 0.3s + 1 * 0.3s;
            }

            &:nth-child(6) {
                transition-delay: 0.3s + 2 * 0.3s;
                animation-delay: 0.3s + 2 * 0.3s;
            }

            &:nth-child(7) {
                transition-delay: 0.3s + 3 * 0.3s;
                animation-delay: 0.3s + 3 * 0.3s;
            }
        }
    }

    .loading-bar {
        will-change: transform;
        background-color: $jaune;
        width: 100%;
        transform: scaleX(0);
        transform-origin: top left;
        height: 5px;
        z-index: 1;
        border-radius: 100vmax;
        transition: transform 0s, opacity 1s ease;

        &_container {
            overflow: hidden;
            z-index: 0;
            background-color: rgba(255, 255, 0, 0.5);
            width: 30%;
            border-radius: 100vmax;
            height: 5px;
            position: absolute;
            top: 50%;
            transform: translateY(100px);
            transition: all 0.3s ease;

            &.hide {
                opacity: 0;
            }
        }
    }

    .button-enter {
        color: $jaune;
        border: 2px solid $jaune;
        border-radius: 20px;
        padding: 10px 20px;
        position: absolute;
        top: 50%;
        transform: translateY(calc(100px - 50%));
        transition: all 0.3s ease 0.2s;
        cursor: pointer;

        &.hide {
            opacity: 0;
            pointer-events: none;
        }
    }
}

@keyframes oscillation {
    from {
        height: 2px;
    }

    to {
        height: 40px;
    }
}
