.teambuilding {
    .p-header {
        background-image : url(../img/teambuilding/header.jpg);
        @media screen and (max-width:$screen-md) {
            background-image : url(../img/teambuilding/header-s.jpg);
        }
    }

    .p-nav_bottom{
        .social{
            visibility : hidden;
        }
    }

    .composant-1{
        .text-bottom{
            width : 32vw;

            @media screen and (max-width:$screen-md) {
                width : 100%;
            }

            @media screen and (max-width:1500px) {
                width : 40vw;
            }
        }
    }

    .composant-2{
        background-image : url(../img/teambuilding/header.jpg);
        @media screen and (max-width:$screen-md) {
            background-image : url(../img/teambuilding/header-s.jpg);
        }
        
        &_txt-p{
            white-space: normal;
            text-align : center;
        }
    } 
}