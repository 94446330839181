/*************************************************
// BOUTONS
*************************************************/

.bouton,
.bouton:focus {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    padding-right: 3rem;
    padding-left: 3rem;
    height: 5rem;
    font-family: $font;
    font-size: 1.8rem;
    font-weight: $bold;
    background-color: $jaune;
    border: 1px solid $jaune;
    border-radius: 100vmax;
    color: $noir;
    transition: all .3s ease;

    @media screen and (max-width:$screen-md) {
        height: 50px;
        padding-left: 40px;
        padding-right: 40px;
        font-size: 18px;
    }

    &.float-right {
        float: right;

        @media screen and (max-width:$screen-md) {
            float: none;
        }
    }

    &:hover {

        @media screen and (min-width:$screen-md) {
            background-color: $noir;
            color: $jaune;
            transition: all .3s ease;
        }
    }

    &.v-border {
        background-color: transparent;
        border: 1px solid $jaune;
        color: $blanc;

        &:hover {
            background-color: $jaune;
            color: $noir;
        }
    }
}