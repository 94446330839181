/*************************************************
// BOOTSTRAP & VARIABLES
*************************************************/
/*************************************************
// GRID
*************************************************/
@import url("https://p.typekit.net/p.css?s=1&k=mtu6dvy&ht=tk&f=47839.47840.47844.47845&a=87216329&app=typekit&e=css");
@import url("https://p.typekit.net/p.css?s=1&k=eea0qli&ht=tk&f=51598&a=87161281&app=typekit&e=css");
@import url(vendor/swiper-bundle.css);
@import url(vendor/OverlayScrollbars.css);
@import url("https://p.typekit.net/p.css?s=1&k=mtu6dvy&ht=tk&f=47839.47840.47844.47845&a=87216329&app=typekit&e=css");
@import url("https://p.typekit.net/p.css?s=1&k=eea0qli&ht=tk&f=51598&a=87161281&app=typekit&e=css");
.simple-container {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
}

@media screen and (min-width: 992px) {
  .simple-container {
    width: 79.6875vw;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }
}

.grid-container {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
  position: relative;
  z-index: 5;
}

@media screen and (min-width: 992px) {
  .grid-container {
    width: 79.6875vw;
    column-gap: 30px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (min-width: 992px) {
  .grid-container.no-padding {
    column-gap: 0;
  }
}

.grid {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
  position: relative;
  z-index: 5;
}

@media screen and (min-width: 992px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding-left: 0;
    padding-right: 0;
  }
}

.col-xs-1-1 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: span 2;
  grid-column-end: 2;
}

.col-xs-1-2 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: span 2;
  grid-column-end: 3;
}

.col-xs-1-3 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: span 2;
  grid-column-end: 4;
}

.col-xs-1-4 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: span 2;
  grid-column-end: 5;
}

.col-xs-1-5 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: span 2;
  grid-column-end: 6;
}

.col-xs-1-6 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: span 2;
  grid-column-end: 7;
}

.col-xs-1-7 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: span 2;
  grid-column-end: 8;
}

.col-xs-1-8 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: span 2;
  grid-column-end: 9;
}

.col-xs-1-9 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: span 2;
  grid-column-end: 10;
}

.col-xs-1-10 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: span 2;
  grid-column-end: 11;
}

.col-xs-1-11 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: span 2;
  grid-column-end: 12;
}

.col-xs-1-12 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: span 2;
  grid-column-end: 13;
}

.col-xs-2-1 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: span 2;
  grid-column-end: 2;
}

.col-xs-2-2 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: span 2;
  grid-column-end: 3;
}

.col-xs-2-3 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: span 2;
  grid-column-end: 4;
}

.col-xs-2-4 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: span 2;
  grid-column-end: 5;
}

.col-xs-2-5 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: span 2;
  grid-column-end: 6;
}

.col-xs-2-6 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: span 2;
  grid-column-end: 7;
}

.col-xs-2-7 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: span 2;
  grid-column-end: 8;
}

.col-xs-2-8 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: span 2;
  grid-column-end: 9;
}

.col-xs-2-9 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: span 2;
  grid-column-end: 10;
}

.col-xs-2-10 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: span 2;
  grid-column-end: 11;
}

.col-xs-2-11 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: span 2;
  grid-column-end: 12;
}

.col-xs-2-12 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: span 2;
  grid-column-end: 13;
}

.col-xs-3-1 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: span 2;
  grid-column-end: 2;
}

.col-xs-3-2 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: span 2;
  grid-column-end: 3;
}

.col-xs-3-3 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: span 2;
  grid-column-end: 4;
}

.col-xs-3-4 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: span 2;
  grid-column-end: 5;
}

.col-xs-3-5 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: span 2;
  grid-column-end: 6;
}

.col-xs-3-6 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: span 2;
  grid-column-end: 7;
}

.col-xs-3-7 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: span 2;
  grid-column-end: 8;
}

.col-xs-3-8 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: span 2;
  grid-column-end: 9;
}

.col-xs-3-9 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: span 2;
  grid-column-end: 10;
}

.col-xs-3-10 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: span 2;
  grid-column-end: 11;
}

.col-xs-3-11 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: span 2;
  grid-column-end: 12;
}

.col-xs-3-12 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: span 2;
  grid-column-end: 13;
}

.col-xs-4-1 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 4;
  grid-row-end: span 2;
  grid-column-end: 2;
}

.col-xs-4-2 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 4;
  grid-row-end: span 2;
  grid-column-end: 3;
}

.col-xs-4-3 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 4;
  grid-row-end: span 2;
  grid-column-end: 4;
}

.col-xs-4-4 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 4;
  grid-row-end: span 2;
  grid-column-end: 5;
}

.col-xs-4-5 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 4;
  grid-row-end: span 2;
  grid-column-end: 6;
}

.col-xs-4-6 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 4;
  grid-row-end: span 2;
  grid-column-end: 7;
}

.col-xs-4-7 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 4;
  grid-row-end: span 2;
  grid-column-end: 8;
}

.col-xs-4-8 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 4;
  grid-row-end: span 2;
  grid-column-end: 9;
}

.col-xs-4-9 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 4;
  grid-row-end: span 2;
  grid-column-end: 10;
}

.col-xs-4-10 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 4;
  grid-row-end: span 2;
  grid-column-end: 11;
}

.col-xs-4-11 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 4;
  grid-row-end: span 2;
  grid-column-end: 12;
}

.col-xs-4-12 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 4;
  grid-row-end: span 2;
  grid-column-end: 13;
}

.col-xs-5-1 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 5;
  grid-row-end: span 2;
  grid-column-end: 2;
}

.col-xs-5-2 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 5;
  grid-row-end: span 2;
  grid-column-end: 3;
}

.col-xs-5-3 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 5;
  grid-row-end: span 2;
  grid-column-end: 4;
}

.col-xs-5-4 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 5;
  grid-row-end: span 2;
  grid-column-end: 5;
}

.col-xs-5-5 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 5;
  grid-row-end: span 2;
  grid-column-end: 6;
}

.col-xs-5-6 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 5;
  grid-row-end: span 2;
  grid-column-end: 7;
}

.col-xs-5-7 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 5;
  grid-row-end: span 2;
  grid-column-end: 8;
}

.col-xs-5-8 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 5;
  grid-row-end: span 2;
  grid-column-end: 9;
}

.col-xs-5-9 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 5;
  grid-row-end: span 2;
  grid-column-end: 10;
}

.col-xs-5-10 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 5;
  grid-row-end: span 2;
  grid-column-end: 11;
}

.col-xs-5-11 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 5;
  grid-row-end: span 2;
  grid-column-end: 12;
}

.col-xs-5-12 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 5;
  grid-row-end: span 2;
  grid-column-end: 13;
}

.col-xs-6-1 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 6;
  grid-row-end: span 2;
  grid-column-end: 2;
}

.col-xs-6-2 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 6;
  grid-row-end: span 2;
  grid-column-end: 3;
}

.col-xs-6-3 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 6;
  grid-row-end: span 2;
  grid-column-end: 4;
}

.col-xs-6-4 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 6;
  grid-row-end: span 2;
  grid-column-end: 5;
}

.col-xs-6-5 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 6;
  grid-row-end: span 2;
  grid-column-end: 6;
}

.col-xs-6-6 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 6;
  grid-row-end: span 2;
  grid-column-end: 7;
}

.col-xs-6-7 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 6;
  grid-row-end: span 2;
  grid-column-end: 8;
}

.col-xs-6-8 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 6;
  grid-row-end: span 2;
  grid-column-end: 9;
}

.col-xs-6-9 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 6;
  grid-row-end: span 2;
  grid-column-end: 10;
}

.col-xs-6-10 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 6;
  grid-row-end: span 2;
  grid-column-end: 11;
}

.col-xs-6-11 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 6;
  grid-row-end: span 2;
  grid-column-end: 12;
}

.col-xs-6-12 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 6;
  grid-row-end: span 2;
  grid-column-end: 13;
}

.col-xs-7-1 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 7;
  grid-row-end: span 2;
  grid-column-end: 2;
}

.col-xs-7-2 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 7;
  grid-row-end: span 2;
  grid-column-end: 3;
}

.col-xs-7-3 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 7;
  grid-row-end: span 2;
  grid-column-end: 4;
}

.col-xs-7-4 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 7;
  grid-row-end: span 2;
  grid-column-end: 5;
}

.col-xs-7-5 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 7;
  grid-row-end: span 2;
  grid-column-end: 6;
}

.col-xs-7-6 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 7;
  grid-row-end: span 2;
  grid-column-end: 7;
}

.col-xs-7-7 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 7;
  grid-row-end: span 2;
  grid-column-end: 8;
}

.col-xs-7-8 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 7;
  grid-row-end: span 2;
  grid-column-end: 9;
}

.col-xs-7-9 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 7;
  grid-row-end: span 2;
  grid-column-end: 10;
}

.col-xs-7-10 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 7;
  grid-row-end: span 2;
  grid-column-end: 11;
}

.col-xs-7-11 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 7;
  grid-row-end: span 2;
  grid-column-end: 12;
}

.col-xs-7-12 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 7;
  grid-row-end: span 2;
  grid-column-end: 13;
}

.col-xs-8-1 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 8;
  grid-row-end: span 2;
  grid-column-end: 2;
}

.col-xs-8-2 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 8;
  grid-row-end: span 2;
  grid-column-end: 3;
}

.col-xs-8-3 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 8;
  grid-row-end: span 2;
  grid-column-end: 4;
}

.col-xs-8-4 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 8;
  grid-row-end: span 2;
  grid-column-end: 5;
}

.col-xs-8-5 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 8;
  grid-row-end: span 2;
  grid-column-end: 6;
}

.col-xs-8-6 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 8;
  grid-row-end: span 2;
  grid-column-end: 7;
}

.col-xs-8-7 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 8;
  grid-row-end: span 2;
  grid-column-end: 8;
}

.col-xs-8-8 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 8;
  grid-row-end: span 2;
  grid-column-end: 9;
}

.col-xs-8-9 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 8;
  grid-row-end: span 2;
  grid-column-end: 10;
}

.col-xs-8-10 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 8;
  grid-row-end: span 2;
  grid-column-end: 11;
}

.col-xs-8-11 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 8;
  grid-row-end: span 2;
  grid-column-end: 12;
}

.col-xs-8-12 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 8;
  grid-row-end: span 2;
  grid-column-end: 13;
}

.col-xs-9-1 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 9;
  grid-row-end: span 2;
  grid-column-end: 2;
}

.col-xs-9-2 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 9;
  grid-row-end: span 2;
  grid-column-end: 3;
}

.col-xs-9-3 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 9;
  grid-row-end: span 2;
  grid-column-end: 4;
}

.col-xs-9-4 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 9;
  grid-row-end: span 2;
  grid-column-end: 5;
}

.col-xs-9-5 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 9;
  grid-row-end: span 2;
  grid-column-end: 6;
}

.col-xs-9-6 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 9;
  grid-row-end: span 2;
  grid-column-end: 7;
}

.col-xs-9-7 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 9;
  grid-row-end: span 2;
  grid-column-end: 8;
}

.col-xs-9-8 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 9;
  grid-row-end: span 2;
  grid-column-end: 9;
}

.col-xs-9-9 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 9;
  grid-row-end: span 2;
  grid-column-end: 10;
}

.col-xs-9-10 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 9;
  grid-row-end: span 2;
  grid-column-end: 11;
}

.col-xs-9-11 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 9;
  grid-row-end: span 2;
  grid-column-end: 12;
}

.col-xs-9-12 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 9;
  grid-row-end: span 2;
  grid-column-end: 13;
}

.col-xs-10-1 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 10;
  grid-row-end: span 2;
  grid-column-end: 2;
}

.col-xs-10-2 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 10;
  grid-row-end: span 2;
  grid-column-end: 3;
}

.col-xs-10-3 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 10;
  grid-row-end: span 2;
  grid-column-end: 4;
}

.col-xs-10-4 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 10;
  grid-row-end: span 2;
  grid-column-end: 5;
}

.col-xs-10-5 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 10;
  grid-row-end: span 2;
  grid-column-end: 6;
}

.col-xs-10-6 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 10;
  grid-row-end: span 2;
  grid-column-end: 7;
}

.col-xs-10-7 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 10;
  grid-row-end: span 2;
  grid-column-end: 8;
}

.col-xs-10-8 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 10;
  grid-row-end: span 2;
  grid-column-end: 9;
}

.col-xs-10-9 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 10;
  grid-row-end: span 2;
  grid-column-end: 10;
}

.col-xs-10-10 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 10;
  grid-row-end: span 2;
  grid-column-end: 11;
}

.col-xs-10-11 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 10;
  grid-row-end: span 2;
  grid-column-end: 12;
}

.col-xs-10-12 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 10;
  grid-row-end: span 2;
  grid-column-end: 13;
}

.col-xs-11-1 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 11;
  grid-row-end: span 2;
  grid-column-end: 2;
}

.col-xs-11-2 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 11;
  grid-row-end: span 2;
  grid-column-end: 3;
}

.col-xs-11-3 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 11;
  grid-row-end: span 2;
  grid-column-end: 4;
}

.col-xs-11-4 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 11;
  grid-row-end: span 2;
  grid-column-end: 5;
}

.col-xs-11-5 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 11;
  grid-row-end: span 2;
  grid-column-end: 6;
}

.col-xs-11-6 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 11;
  grid-row-end: span 2;
  grid-column-end: 7;
}

.col-xs-11-7 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 11;
  grid-row-end: span 2;
  grid-column-end: 8;
}

.col-xs-11-8 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 11;
  grid-row-end: span 2;
  grid-column-end: 9;
}

.col-xs-11-9 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 11;
  grid-row-end: span 2;
  grid-column-end: 10;
}

.col-xs-11-10 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 11;
  grid-row-end: span 2;
  grid-column-end: 11;
}

.col-xs-11-11 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 11;
  grid-row-end: span 2;
  grid-column-end: 12;
}

.col-xs-11-12 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 11;
  grid-row-end: span 2;
  grid-column-end: 13;
}

.col-xs-12-1 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 12;
  grid-row-end: span 2;
  grid-column-end: 2;
}

.col-xs-12-2 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 12;
  grid-row-end: span 2;
  grid-column-end: 3;
}

.col-xs-12-3 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 12;
  grid-row-end: span 2;
  grid-column-end: 4;
}

.col-xs-12-4 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 12;
  grid-row-end: span 2;
  grid-column-end: 5;
}

.col-xs-12-5 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 12;
  grid-row-end: span 2;
  grid-column-end: 6;
}

.col-xs-12-6 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 12;
  grid-row-end: span 2;
  grid-column-end: 7;
}

.col-xs-12-7 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 12;
  grid-row-end: span 2;
  grid-column-end: 8;
}

.col-xs-12-8 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 12;
  grid-row-end: span 2;
  grid-column-end: 9;
}

.col-xs-12-9 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 12;
  grid-row-end: span 2;
  grid-column-end: 10;
}

.col-xs-12-10 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 12;
  grid-row-end: span 2;
  grid-column-end: 11;
}

.col-xs-12-11 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 12;
  grid-row-end: span 2;
  grid-column-end: 12;
}

.col-xs-12-12 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 12;
  grid-row-end: span 2;
  grid-column-end: 13;
}

@media (min-width: 760px) {
  .col-sm-1-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-sm-1-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-sm-1-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-sm-1-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-sm-1-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-sm-1-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-sm-1-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-sm-1-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-sm-1-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-sm-1-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-sm-1-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-sm-1-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-sm-2-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-sm-2-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-sm-2-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-sm-2-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-sm-2-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-sm-2-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-sm-2-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-sm-2-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-sm-2-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-sm-2-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-sm-2-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-sm-2-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-sm-3-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-sm-3-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-sm-3-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-sm-3-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-sm-3-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-sm-3-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-sm-3-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-sm-3-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-sm-3-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-sm-3-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-sm-3-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-sm-3-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-sm-4-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-sm-4-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-sm-4-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-sm-4-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-sm-4-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-sm-4-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-sm-4-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-sm-4-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-sm-4-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-sm-4-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-sm-4-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-sm-4-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-sm-5-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-sm-5-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-sm-5-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-sm-5-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-sm-5-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-sm-5-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-sm-5-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-sm-5-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-sm-5-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-sm-5-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-sm-5-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-sm-5-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-sm-6-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-sm-6-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-sm-6-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-sm-6-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-sm-6-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-sm-6-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-sm-6-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-sm-6-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-sm-6-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-sm-6-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-sm-6-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-sm-6-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-sm-7-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-sm-7-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-sm-7-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-sm-7-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-sm-7-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-sm-7-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-sm-7-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-sm-7-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-sm-7-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-sm-7-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-sm-7-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-sm-7-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-sm-8-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-sm-8-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-sm-8-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-sm-8-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-sm-8-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-sm-8-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-sm-8-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-sm-8-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-sm-8-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-sm-8-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-sm-8-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-sm-8-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-sm-9-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-sm-9-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-sm-9-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-sm-9-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-sm-9-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-sm-9-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-sm-9-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-sm-9-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-sm-9-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-sm-9-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-sm-9-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-sm-9-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-sm-10-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-sm-10-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-sm-10-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-sm-10-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-sm-10-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-sm-10-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-sm-10-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-sm-10-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-sm-10-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-sm-10-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-sm-10-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-sm-10-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-sm-11-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-sm-11-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-sm-11-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-sm-11-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-sm-11-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-sm-11-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-sm-11-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-sm-11-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-sm-11-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-sm-11-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-sm-11-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-sm-11-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-sm-12-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-sm-12-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-sm-12-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-sm-12-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-sm-12-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-sm-12-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-sm-12-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-sm-12-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-sm-12-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-sm-12-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-sm-12-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-sm-12-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
}

@media (min-width: 992px) {
  .col-md-1-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-md-1-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-md-1-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-md-1-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-md-1-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-md-1-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-md-1-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-md-1-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-md-1-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-md-1-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-md-1-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-md-1-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-md-2-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-md-2-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-md-2-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-md-2-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-md-2-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-md-2-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-md-2-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-md-2-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-md-2-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-md-2-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-md-2-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-md-2-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-md-3-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-md-3-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-md-3-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-md-3-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-md-3-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-md-3-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-md-3-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-md-3-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-md-3-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-md-3-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-md-3-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-md-3-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-md-4-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-md-4-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-md-4-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-md-4-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-md-4-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-md-4-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-md-4-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-md-4-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-md-4-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-md-4-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-md-4-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-md-4-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-md-5-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-md-5-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-md-5-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-md-5-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-md-5-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-md-5-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-md-5-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-md-5-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-md-5-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-md-5-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-md-5-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-md-5-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-md-6-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-md-6-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-md-6-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-md-6-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-md-6-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-md-6-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-md-6-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-md-6-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-md-6-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-md-6-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-md-6-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-md-6-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-md-7-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-md-7-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-md-7-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-md-7-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-md-7-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-md-7-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-md-7-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-md-7-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-md-7-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-md-7-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-md-7-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-md-7-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-md-8-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-md-8-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-md-8-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-md-8-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-md-8-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-md-8-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-md-8-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-md-8-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-md-8-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-md-8-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-md-8-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-md-8-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-md-9-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-md-9-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-md-9-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-md-9-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-md-9-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-md-9-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-md-9-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-md-9-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-md-9-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-md-9-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-md-9-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-md-9-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-md-10-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-md-10-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-md-10-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-md-10-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-md-10-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-md-10-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-md-10-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-md-10-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-md-10-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-md-10-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-md-10-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-md-10-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-md-11-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-md-11-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-md-11-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-md-11-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-md-11-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-md-11-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-md-11-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-md-11-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-md-11-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-md-11-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-md-11-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-md-11-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-md-12-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-md-12-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-md-12-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-md-12-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-md-12-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-md-12-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-md-12-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-md-12-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-md-12-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-md-12-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-md-12-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-md-12-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
}

@media (min-width: 1200px) {
  .col-lg-1-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-lg-1-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-lg-1-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-lg-1-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-lg-1-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-lg-1-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-lg-1-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-lg-1-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-lg-1-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-lg-1-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-lg-1-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-lg-1-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-lg-2-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-lg-2-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-lg-2-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-lg-2-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-lg-2-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-lg-2-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-lg-2-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-lg-2-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-lg-2-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-lg-2-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-lg-2-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-lg-2-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-lg-3-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-lg-3-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-lg-3-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-lg-3-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-lg-3-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-lg-3-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-lg-3-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-lg-3-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-lg-3-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-lg-3-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-lg-3-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-lg-3-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-lg-4-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-lg-4-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-lg-4-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-lg-4-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-lg-4-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-lg-4-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-lg-4-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-lg-4-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-lg-4-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-lg-4-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-lg-4-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-lg-4-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-lg-5-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-lg-5-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-lg-5-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-lg-5-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-lg-5-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-lg-5-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-lg-5-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-lg-5-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-lg-5-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-lg-5-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-lg-5-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-lg-5-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-lg-6-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-lg-6-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-lg-6-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-lg-6-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-lg-6-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-lg-6-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-lg-6-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-lg-6-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-lg-6-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-lg-6-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-lg-6-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-lg-6-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-lg-7-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-lg-7-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-lg-7-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-lg-7-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-lg-7-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-lg-7-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-lg-7-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-lg-7-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-lg-7-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-lg-7-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-lg-7-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-lg-7-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-lg-8-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-lg-8-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-lg-8-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-lg-8-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-lg-8-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-lg-8-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-lg-8-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-lg-8-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-lg-8-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-lg-8-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-lg-8-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-lg-8-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-lg-9-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-lg-9-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-lg-9-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-lg-9-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-lg-9-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-lg-9-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-lg-9-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-lg-9-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-lg-9-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-lg-9-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-lg-9-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-lg-9-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-lg-10-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-lg-10-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-lg-10-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-lg-10-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-lg-10-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-lg-10-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-lg-10-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-lg-10-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-lg-10-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-lg-10-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-lg-10-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-lg-10-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-lg-11-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-lg-11-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-lg-11-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-lg-11-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-lg-11-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-lg-11-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-lg-11-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-lg-11-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-lg-11-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-lg-11-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-lg-11-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-lg-11-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-lg-12-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-lg-12-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-lg-12-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-lg-12-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-lg-12-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-lg-12-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-lg-12-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-lg-12-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-lg-12-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-lg-12-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-lg-12-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-lg-12-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
}

@media (min-width: 1500px) {
  .col-xl-1-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-xl-1-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-xl-1-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-xl-1-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-xl-1-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-xl-1-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-xl-1-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-xl-1-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-xl-1-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-xl-1-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-xl-1-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-xl-1-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-xl-2-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-xl-2-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-xl-2-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-xl-2-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-xl-2-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-xl-2-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-xl-2-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-xl-2-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-xl-2-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-xl-2-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-xl-2-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-xl-2-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-xl-3-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-xl-3-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-xl-3-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-xl-3-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-xl-3-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-xl-3-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-xl-3-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-xl-3-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-xl-3-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-xl-3-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-xl-3-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-xl-3-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-xl-4-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-xl-4-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-xl-4-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-xl-4-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-xl-4-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-xl-4-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-xl-4-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-xl-4-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-xl-4-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-xl-4-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-xl-4-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-xl-4-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-xl-5-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-xl-5-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-xl-5-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-xl-5-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-xl-5-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-xl-5-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-xl-5-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-xl-5-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-xl-5-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-xl-5-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-xl-5-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-xl-5-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-xl-6-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-xl-6-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-xl-6-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-xl-6-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-xl-6-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-xl-6-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-xl-6-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-xl-6-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-xl-6-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-xl-6-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-xl-6-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-xl-6-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-xl-7-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-xl-7-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-xl-7-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-xl-7-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-xl-7-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-xl-7-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-xl-7-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-xl-7-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-xl-7-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-xl-7-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-xl-7-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-xl-7-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-xl-8-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-xl-8-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-xl-8-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-xl-8-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-xl-8-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-xl-8-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-xl-8-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-xl-8-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-xl-8-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-xl-8-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-xl-8-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-xl-8-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-xl-9-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-xl-9-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-xl-9-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-xl-9-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-xl-9-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-xl-9-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-xl-9-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-xl-9-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-xl-9-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-xl-9-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-xl-9-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-xl-9-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-xl-10-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-xl-10-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-xl-10-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-xl-10-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-xl-10-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-xl-10-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-xl-10-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-xl-10-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-xl-10-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-xl-10-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-xl-10-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-xl-10-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-xl-11-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-xl-11-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-xl-11-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-xl-11-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-xl-11-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-xl-11-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-xl-11-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-xl-11-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-xl-11-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-xl-11-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-xl-11-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-xl-11-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-xl-12-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-xl-12-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-xl-12-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-xl-12-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-xl-12-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-xl-12-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-xl-12-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-xl-12-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-xl-12-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-xl-12-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-xl-12-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-xl-12-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
}

/*************************************************
// FONTS
*************************************************/
/*************************************************
// INCLUDE DES FONTS
*************************************************/
@font-face {
  font-family: "transducer-extended";
  src: url("https://use.typekit.net/af/cadf16/00000000000000007735eccb/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"), url("https://use.typekit.net/af/cadf16/00000000000000007735eccb/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"), url("https://use.typekit.net/af/cadf16/00000000000000007735eccb/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "transducer-extended";
  src: url("https://use.typekit.net/af/f5b1f5/00000000000000007735ecce/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/f5b1f5/00000000000000007735ecce/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/f5b1f5/00000000000000007735ecce/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "transducer-extended";
  src: url("https://use.typekit.net/af/d156da/00000000000000007735ecdb/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff2"), url("https://use.typekit.net/af/d156da/00000000000000007735ecdb/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff"), url("https://use.typekit.net/af/d156da/00000000000000007735ecdb/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: "transducer-extended";
  src: url("https://use.typekit.net/af/3a72c7/00000000000000007735ecd7/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/3a72c7/00000000000000007735ecd7/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/3a72c7/00000000000000007735ecd7/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: "transducer-variable";
  src: url("https://use.typekit.net/af/e88dea/0000000000000000774b97a8/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/e88dea/0000000000000000774b97a8/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/e88dea/0000000000000000774b97a8/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

.tk-transducer-variable {
  font-family: "transducer-variable", sans-serif;
}

/*************************************************
// STYLE DES FONTS
*************************************************/
.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.bold {
  font-weight: 600;
}

.black {
  font-weight: 900;
}

span.tarteaucitronH3, #tarteaucitron #tarteaucitronServices_mandatory .tarteaucitronH3 {
  font-family: "transducer-extended";
  font-weight: normal;
  font-style: normal;
}

.mag_content_title, .mag_content_title_sub, .c-actu__title, .c-actu_similar__title, .c-articles--no-articles, .c-article__title {
  font-family: "transducer-extended";
  font-weight: 600;
  font-style: normal;
}

/*************************************************
// ICOMOON
*************************************************/
/*************************************************
// MIXINS
*************************************************/
.flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex:after, .flex:before {
  content: normal;
  display: none;
}

.flex.align-start {
  align-items: flex-start;
}

.flex.align-end {
  align-items: flex-end;
}

.flex.align-stretch {
  align-items: stretch;
}

.flex.align-center {
  align-items: center;
}

.flex.justify-center {
  justify-content: center;
}

.flex.justify-end {
  justify-content: flex-end;
}

.flex.justify-start {
  justify-content: flex-start;
}

.flex.justify-between {
  justify-content: space-between;
}

.flex.column {
  flex-direction: column;
}

.flex.nowrap {
  flex-wrap: nowrap;
}

.flex.wrap {
  flex-wrap: wrap;
}

@media (max-width: 1200px) {
  .flex.align-start-lg {
    align-items: flex-start;
  }
}

@media (max-width: 992px) {
  .flex.align-start-md {
    align-items: flex-start;
  }
}

@media (max-width: 760px) {
  .flex.align-start-sm {
    align-items: flex-start;
  }
}

@media (max-width: 480px) {
  .flex.align-start-xs {
    align-items: flex-start;
  }
}

@media (max-width: 1200px) {
  .flex.align-end-lg {
    align-items: flex-end;
  }
}

@media (max-width: 992px) {
  .flex.align-end-md {
    align-items: flex-end;
  }
}

@media (max-width: 760px) {
  .flex.align-end-sm {
    align-items: flex-end;
  }
}

@media (max-width: 480px) {
  .flex.align-end-xs {
    align-items: flex-end;
  }
}

@media (max-width: 1200px) {
  .flex.align-stretch-lg {
    align-items: stretch;
  }
}

@media (max-width: 992px) {
  .flex.align-stretch-md {
    align-items: stretch;
  }
}

@media (max-width: 760px) {
  .flex.align-stretch-sm {
    align-items: stretch;
  }
}

@media (max-width: 480px) {
  .flex.align-stretch-xs {
    align-items: stretch;
  }
}

@media (max-width: 1200px) {
  .flex.align-center-lg {
    align-items: center;
  }
}

@media (max-width: 992px) {
  .flex.align-center-md {
    align-items: center;
  }
}

@media (max-width: 760px) {
  .flex.align-center-sm {
    align-items: center;
  }
}

@media (max-width: 480px) {
  .flex.align-center-xs {
    align-items: stretch;
  }
}

@media (max-width: 1200px) {
  .flex.justify-center-lg {
    justify-content: center;
  }
}

@media (max-width: 992px) {
  .flex.justify-center-md {
    justify-content: center;
  }
}

@media (max-width: 760px) {
  .flex.justify-center-sm {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .flex.justify-center-xs {
    justify-content: center;
  }
}

@media (max-width: 1200px) {
  .flex.justify-end-lg {
    justify-content: flex-end;
  }
}

@media (max-width: 992px) {
  .flex.justify-end-md {
    justify-content: flex-end;
  }
}

@media (max-width: 760px) {
  .flex.justify-end-sm {
    justify-content: flex-end;
  }
}

@media (max-width: 480px) {
  .flex.justify-end-xs {
    justify-content: flex-end;
  }
}

@media (max-width: 1200px) {
  .flex.justify-start-lg {
    justify-content: flex-start;
  }
}

@media (max-width: 992px) {
  .flex.justify-start-md {
    justify-content: flex-start;
  }
}

@media (max-width: 760px) {
  .flex.justify-start-sm {
    justify-content: flex-start;
  }
}

@media (max-width: 480px) {
  .flex.justify-start-xs {
    justify-content: flex-start;
  }
}

@media (max-width: 1200px) {
  .flex.justify-between-lg {
    justify-content: space-between;
  }
}

@media (max-width: 992px) {
  .flex.justify-between-md {
    justify-content: space-between;
  }
}

@media (max-width: 760px) {
  .flex.justify-between-sm {
    justify-content: space-between;
  }
}

@media (max-width: 480px) {
  .flex.justify-between-xs {
    justify-content: space-between;
  }
}

@media (max-width: 1200px) {
  .flex.column-lg {
    flex-direction: column;
  }
}

@media (max-width: 992px) {
  .flex.column-md {
    flex-direction: column;
  }
}

@media (max-width: 760px) {
  .flex.column-sm {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .flex.column-xs {
    flex-direction: column;
  }
}

@media (max-width: 1200px) {
  .flex.column-reverse-lg {
    flex-direction: column-reverse;
  }
}

@media (max-width: 992px) {
  .flex.column-reverse-md {
    flex-direction: column-reverse;
  }
}

@media (max-width: 760px) {
  .flex.column-reverse-sm {
    flex-direction: column-reverse;
  }
}

@media (max-width: 480px) {
  .flex.column-reverse-xs {
    flex-direction: column;
  }
}

@media (max-width: 1200px) {
  .flex.nowrap-lg {
    flex-wrap: nowrap;
  }
}

@media (max-width: 992px) {
  .flex.nowrap-md {
    flex-wrap: nowrap;
  }
}

@media (max-width: 760px) {
  .flex.nowrap-sm {
    flex-wrap: nowrap;
  }
}

@media (max-width: 480px) {
  .flex.nowrap-xs {
    flex-wrap: nowrap;
  }
}

@media (max-width: 1200px) {
  .flex.wrap-lg {
    flex-wrap: wrap;
  }
}

@media (max-width: 992px) {
  .flex.wrap-md {
    flex-wrap: wrap;
  }
}

@media (max-width: 760px) {
  .flex.wrap-sm {
    flex-wrap: wrap;
  }
}

@media (max-width: 480px) {
  .flex.wrap-xs {
    flex-wrap: wrap;
  }
}

/*************************************************
// COULEURS DU SITE
*************************************************/
/*************************************************
// COULEURS DU SITE
*************************************************/
/*************************************************
// CLASS COLORS
*************************************************/
.jaune {
  color: #FFF852 !important;
}

.jaune-bg {
  background: #FFF852;
  color: inherit !important;
}

.blanc {
  color: #ffffff !important;
}

.blanc-bg {
  background: #ffffff;
  color: inherit !important;
}

.gris {
  color: #7a8285 !important;
}

.gris-bg {
  background: #7a8285;
  color: inherit !important;
}

.noir {
  color: #000000 !important;
}

.noir-bg {
  background: #000000;
  color: inherit !important;
}

/*************************************************
// BASE
*************************************************/
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant-ligatures: common-ligatures;
  -webkit-font-variant-ligatures: common-ligatures;
}

/*************************************************
// BASE
*************************************************/
html {
  font-size: 5.2px;
  font-family: "transducer-extended";
  color: #ffffff;
  background-color: #000000;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  font-kerning: none;
}

html.noscroll {
  overflow: hidden;
}

@media (min-width: 760px) {
  html {
    font-size: 6.1px;
  }
}

@media (min-width: 992px) {
  html {
    font-size: 7.2px;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 8.5px;
  }
}

@media (min-width: 1500px) {
  html {
    font-size: 10px;
  }
}

body {
  min-height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 992px) {
  body {
    overflow: scroll;
  }
}

#wrapper {
  overflow: hidden;
  height: 100vh;
}

@media screen and (max-width: 992px) {
  #wrapper {
    overflow: scroll;
    height: auto;
  }
}

#wrapper-scroll {
  z-index: 1;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 0;
  margin-left: 0;
  white-space: nowrap;
  height: 100vh;
  position: relative;
  overflow: visible;
  transition: all 0.85s cubic-bezier(0, 0, 0.25, 1);
}

@media screen and (max-width: 992px) {
  #wrapper-scroll {
    height: auto;
    white-space: initial;
  }
}

#wrapper-scroll .section {
  position: relative;
  display: inline-flex;
  align-items: center;
  vertical-align: top;
  white-space: nowrap;
  height: 100vh;
  overflow: visible;
}

@media screen and (max-width: 992px) {
  #wrapper-scroll .section {
    height: auto;
    width: 100%;
    overflow: hidden;
    display: block;
  }
}

#wrapper-scroll .section > section {
  white-space: normal;
}

button,
input,
textarea {
  border: none;
  background: none;
}

a,
button {
  transition: all .3s ease;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

a img {
  border: 0;
}

a:hover,
button:hover {
  text-decoration: none;
}

img,
svg {
  vertical-align: middle;
  border: none;
}

b,
strong {
  font-family: "transducer-extended";
}

ul,
li {
  list-style: none;
  list-style-type: none;
}

textarea {
  overflow: auto;
  resize: none;
}

/*************************************************
// STYLES DE CARATERES
*************************************************/
/*************************************************
// STYLES DE CARATERES
*************************************************/
.paragr, .c-actu__preview, .c-actu__text,
p {
  font-size: 13px;
  line-height: 1.5;
  font-family: "transducer-extended";
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

@media (min-width: 991px) {
  .paragr, .c-actu__preview, .c-actu__text,
  p {
    font-size: calc(0.32293vw + 9.79978px);
  }
}

@media (min-width: 1920px) {
  .paragr, .c-actu__preview, .c-actu__text,
  p {
    font-size: 16px;
  }
}

.paragr strong, .c-actu__preview strong, .c-actu__text strong,
p strong {
  font-weight: 600;
}

@media (max-width: 992px) {
  .paragr, .c-actu__preview, .c-actu__text,
  p {
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .paragr, .c-actu__preview, .c-actu__text,
  p {
    font-size: 12px;
  }
}

/*************************************************
// TITRES H1/H2/H3
*************************************************/
.h1, .articlesUne_title {
  margin: 0;
  font-family: "transducer-extended";
  font-weight: 600;
  font-size: 30px;
  line-height: 1.1;
  font-size: 23px;
  line-height: 1.1;
}

@media (min-width: 991px) {
  .h1, .articlesUne_title {
    font-size: calc(1.61464vw + 13.99892px);
  }
}

@media (min-width: 1920px) {
  .h1, .articlesUne_title {
    font-size: 45px;
  }
}

@media (min-width: 320px) {
  .h1, .articlesUne_title {
    font-size: calc(14.28571vw + -22.71429px);
  }
}

@media (min-width: 390px) {
  .h1, .articlesUne_title {
    font-size: 33px;
  }
}

.h2 {
  margin: 0;
  font-family: "transducer-extended";
  font-weight: 600;
  font-size: 20px;
  line-height: 1.1;
  font-size: 20px;
  line-height: 1.1;
}

@media (min-width: 991px) {
  .h2 {
    font-size: calc(1.07643vw + 9.33262px);
  }
}

@media (min-width: 1920px) {
  .h2 {
    font-size: 30px;
  }
}

@media (min-width: 320px) {
  .h2 {
    font-size: calc(14.28571vw + -25.71429px);
  }
}

@media (min-width: 390px) {
  .h2 {
    font-size: 30px;
  }
}

.h2-variable {
  font-family: "transducer-variable";
  margin: 0;
  font-size: 50px;
  line-height: 1.1;
  font-size: 20px;
  line-height: 1.1;
  color: #FFF852;
  font-variation-settings: "wght" 600, "wdth" 200;
  transition: all .5s linear;
}

@media (min-width: 991px) {
  .h2-variable {
    font-size: calc(6.99677vw + -19.338px);
  }
}

@media (min-width: 1920px) {
  .h2-variable {
    font-size: 115px;
  }
}

@media (min-width: 320px) {
  .h2-variable {
    font-size: calc(14.28571vw + -25.71429px);
  }
}

@media (min-width: 390px) {
  .h2-variable {
    font-size: 30px;
  }
}

.h2-variable.masked {
  font-variation-settings: "wght" 100, "wdth" 200;
}

.h3 {
  margin: 0;
  font-family: "transducer-extended";
  font-weight: 600;
  font-size: 45px;
  line-height: 1.1;
  font-size: 20px;
  line-height: 1.4;
}

@media (min-width: 991px) {
  .h3 {
    font-size: calc(2.15285vw + 23.66523px);
  }
}

@media (min-width: 1920px) {
  .h3 {
    font-size: 65px;
  }
}

@media (min-width: 320px) {
  .h3 {
    font-size: calc(14.28571vw + -25.71429px);
  }
}

@media (min-width: 390px) {
  .h3 {
    font-size: 30px;
  }
}

.h4 {
  margin: 0;
  font-family: "transducer-extended";
  font-weight: 600;
  font-size: 19px;
  line-height: 1.4;
  font-size: 18px;
  line-height: 1.4;
}

@media (min-width: 991px) {
  .h4 {
    font-size: calc(0.64586vw + 12.59957px);
  }
}

@media (min-width: 1920px) {
  .h4 {
    font-size: 25px;
  }
}

@media (min-width: 320px) {
  .h4 {
    font-size: calc(2.85714vw + 8.85714px);
  }
}

@media (min-width: 390px) {
  .h4 {
    font-size: 20px;
  }
}

/*************************************************
// PROPRIETES TEXTE
*************************************************/
.underline {
  text-decoration: underline;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.justify {
  text-align: justify;
}

.uppercase {
  text-transform: uppercase;
}

@media screen and (max-width: 992px) {
  br:not(.keep) {
    display: none;
  }
}

/*************************************************
// BOUTONS
*************************************************/
/*************************************************
// BOUTONS
*************************************************/
.bouton,
.bouton:focus {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  padding-right: 3rem;
  padding-left: 3rem;
  height: 5rem;
  font-family: "transducer-extended";
  font-size: 1.8rem;
  font-weight: 600;
  background-color: #FFF852;
  border: 1px solid #FFF852;
  border-radius: 100vmax;
  color: #000000;
  transition: all .3s ease;
}

@media screen and (max-width: 992px) {
  .bouton,
  .bouton:focus {
    height: 50px;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 18px;
  }
}

.bouton.float-right,
.bouton:focus.float-right {
  float: right;
}

@media screen and (max-width: 992px) {
  .bouton.float-right,
  .bouton:focus.float-right {
    float: none;
  }
}

@media screen and (min-width: 992px) {
  .bouton:hover,
  .bouton:focus:hover {
    background-color: #000000;
    color: #FFF852;
    transition: all .3s ease;
  }
}

.bouton.v-border,
.bouton:focus.v-border {
  background-color: transparent;
  border: 1px solid #FFF852;
  color: #ffffff;
}

.bouton.v-border:hover,
.bouton:focus.v-border:hover {
  background-color: #FFF852;
  color: #000000;
}

/*************************************************
// CLASS UTILITAIRE
*************************************************/
.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  background: black !important;
}

.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle.noscroll {
  opacity: 0 !important;
  pointer-events: none;
}

@media screen and (max-width: 992px) {
  .os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
    opacity: 0 !important;
    pointer-events: none;
  }
}

@media screen and (min-width: 992px) {
  .no-link-desktop {
    pointer-events: none;
    cursor: initial;
  }
}

.hidden {
  display: none;
}

@media screen and (min-width: 992px) {
  .hidden-pc {
    display: none !important;
  }
}

@media screen and (max-width: 992px) {
  .hidden-mobile {
    display: none !important;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.page-attente {
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
}

@media screen and (max-width: 992px) {
  .page-attente {
    overflow: scroll;
    display: block;
    padding-bottom: 50px;
    background-color: #000000;
  }
}

.page-attente_left, .page-attente_right {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 992px) {
  .page-attente_left, .page-attente_right {
    width: 100%;
    height: auto;
    padding: 0 30px;
  }
}

.page-attente_left {
  background-color: #FFF852;
  padding-right: calc(17.447916666666668vw / 2);
}

@media screen and (max-width: 992px) {
  .page-attente_left {
    padding-top: 60px;
    height: 425px;
    padding-bottom: calc(235px / 2 + 40px);
  }
}

.page-attente_left .content {
  width: 43rem;
}

@media screen and (max-width: 992px) {
  .page-attente_left .content {
    width: 100%;
  }
}

.page-attente_left .content_title {
  margin-bottom: 2.60417vw;
  transform: translateY(100%);
  opacity: 0;
  animation: trans-opacity-top-100 0.4s linear forwards 0.2s;
}

@media screen and (max-width: 992px) {
  .page-attente_left .content_title {
    margin-bottom: 25px;
  }
  .page-attente_left .content_title br {
    display: block !important;
  }
}

.page-attente_left .content_paragraphe {
  transform: translateY(100%);
  opacity: 0;
  animation: trans-opacity-top-100 0.5s ease forwards 0.2s;
}

.page-attente_right {
  background-color: #000000;
  padding-left: calc(17.447916666666668vw / 2);
}

@media screen and (max-width: 992px) {
  .page-attente_right {
    min-height: calc(var(--heightJs) - 425px);
    padding-top: calc(235px / 2 + 20px);
    padding-bottom: 50px;
    align-items: center;
  }
}

.page-attente_right .content {
  width: 49rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 3.90625vw;
}

@media screen and (max-width: 992px) {
  .page-attente_right .content {
    width: 100%;
    row-gap: 35px;
  }
}

.page-attente_right .content_row {
  width: 50%;
  display: flex;
  align-items: center;
  transform: translateY(100%);
  opacity: 0;
  animation: trans-opacity-top-100 0.5s ease forwards 0.4s;
}

@media screen and (max-width: 992px) {
  .page-attente_right .content_row {
    flex-direction: column;
  }
}

.page-attente_right .content_row .icon {
  width: 8rem;
}

@media screen and (max-width: 992px) {
  .page-attente_right .content_row .icon {
    width: auto;
    margin-bottom: 15px;
  }
}

.page-attente_right .content_row .icon img {
  height: 2.60417vw;
}

@media screen and (max-width: 992px) {
  .page-attente_right .content_row .icon img {
    height: 50px;
  }
}

.page-attente_right .content_row .icon img img {
  height: 100%;
  width: auto;
}

.page-attente_right .content_row .title {
  font-family: "transducer-extended";
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  color: #ffffff;
}

@media (min-width: 991px) {
  .page-attente_right .content_row .title {
    font-size: calc(0.43057vw + 9.73305px);
  }
}

@media (min-width: 1920px) {
  .page-attente_right .content_row .title {
    font-size: 18px;
  }
}

@media screen and (max-width: 992px) {
  .page-attente_right .content_row .title {
    font-size: 16px;
    text-align: center;
  }
  .page-attente_right .content_row .title br {
    display: block;
  }
}

.page-attente_middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  overflow: hidden;
  width: 17.447916666666668vw;
  height: 17.447916666666668vw;
  border-radius: 100%;
}

@media screen and (max-width: 992px) {
  .page-attente_middle {
    width: 235px;
    height: 235px;
    top: 425px;
  }
}

.page-attente_middle .video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
  object-position: center;
  object-fit: cover;
}

@media screen and (max-width: 991px) {
  canvas {
    pointer-events: none;
    display: none;
  }
}

/*************************************************
// IMPORT DEPENDENCIES
*************************************************/
.p-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  animation: opacity 1.5s linear forwards;
  z-index: 100;
}

@media screen and (max-width: 992px) {
  .p-header {
    height: var(--heightJs);
    padding: 0 30px;
  }
}

.p-header_titre {
  display: flex;
  flex-direction: column;
  gap: 2.3vh;
}

.p-header_titre-subtitle {
  font-size: 20px;
  line-height: 1.1;
  font-size: 12px;
  line-height: 1.1;
  font-weight: 400;
  text-align: center;
  opacity: 0;
  animation: trans-opacity-bot-100 .7s ease .3s forwards;
}

@media (min-width: 991px) {
  .p-header_titre-subtitle {
    font-size: calc(1.07643vw + 9.33262px);
  }
}

@media (min-width: 1920px) {
  .p-header_titre-subtitle {
    font-size: 30px;
  }
}

@media (min-width: 320px) {
  .p-header_titre-subtitle {
    font-size: calc(0.29851vw + 11.04478px);
  }
}

@media (min-width: 990px) {
  .p-header_titre-subtitle {
    font-size: 14px;
  }
}

.p-header_titre-title {
  font-size: 70px;
  line-height: 1.1;
  font-size: 30px;
  line-height: 1.1;
  font-weight: 900;
  color: #FFF852;
  text-align: center;
  opacity: 0;
  animation: trans-opacity-top-100 .7s ease .8s forwards;
}

@media (min-width: 991px) {
  .p-header_titre-title {
    font-size: calc(3.22928vw + 37.99785px);
  }
}

@media (min-width: 1920px) {
  .p-header_titre-title {
    font-size: 100px;
  }
}

@media (min-width: 320px) {
  .p-header_titre-title {
    font-size: calc(0.89552vw + 27.13433px);
  }
}

@media (min-width: 990px) {
  .p-header_titre-title {
    font-size: 36px;
  }
}

.p-header_arrow {
  position: absolute;
  bottom: 11.1vh;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 992px) {
  .p-header_arrow {
    left: 30px;
    bottom: 5vh;
    transform: none;
  }
}

.p-header_arrow .icon {
  display: inline-block;
}

@media screen and (min-width: 992px) {
  .p-header_arrow .icon {
    animation: upDown 2s linear infinite;
  }
}

.p-header_arrow img {
  width: 3.6vw;
  transform: scale(1);
  transition: all .3s ease;
}

@media screen and (max-width: 992px) {
  .p-header_arrow img {
    width: 70px;
  }
}

.p-header_arrow:hover img {
  transform: scale(1.1);
  transition: all .3s ease;
}

.p-footer {
  position: relative;
  z-index: 106;
  padding: 4.01042vw 0;
  background-color: #000000;
  border-top: 1px solid #FFF852;
}

@media screen and (max-width: 992px) {
  .p-footer {
    padding: 80px 0 180px;
  }
}

.p-footer_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 992px) {
  .p-footer_flex {
    flex-direction: column;
    gap: 48px;
  }
}

.p-footer_links {
  display: flex;
  align-items: center;
  gap: 18px;
}

@media screen and (max-width: 992px) {
  .p-footer_links {
    flex-direction: column;
    gap: 48px;
  }
}

.p-footer_links .bouton {
  background-color: transparent;
  color: #ffffff;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
  display: inline-flex;
  gap: 0.72917vw;
  height: 55px;
  font-size: 1.4rem;
}

.p-footer_links .bouton:hover {
  background-color: #FFF852;
  color: #000000;
}

.p-footer_links .bouton:hover p {
  color: #000000;
}

.p-footer_links .bouton:hover svg g {
  fill: #000000;
}

.p-footer_links .social {
  display: flex;
  align-items: center;
  gap: 18px;
}

.p-footer_links .item {
  border: 1px solid #FFF852;
  border-radius: 100vmax;
  width: 2.91667vw;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  min-width: 40px;
}

.p-footer_links .item span {
  font-size: 14px;
  line-height: 1;
}

@media (min-width: 991px) {
  .p-footer_links .item span {
    font-size: calc(0.53821vw + 8.66631px);
  }
}

@media (min-width: 1920px) {
  .p-footer_links .item span {
    font-size: 19px;
  }
}

.p-footer_links .item:hover {
  background-color: #FFF852;
  color: #000000;
}

.p-footer .copyright {
  font-size: 8px;
  line-height: 1.5;
}

@media (min-width: 991px) {
  .p-footer .copyright {
    font-size: calc(0.21529vw + 5.86652px);
  }
}

@media (min-width: 1920px) {
  .p-footer .copyright {
    font-size: 10px;
  }
}

@media screen and (max-width: 992px) {
  .p-footer .copyright {
    font-size: 10px;
  }
}

.p-footer .copyright a:hover {
  color: #FFF852;
}

.p-footer .logo {
  width: 5.20833vw;
  min-width: 70px;
}

@media screen and (max-width: 992px) {
  .p-footer .logo {
    min-width: 100px;
  }
}

.p-nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 104;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
}

.p-nav .logo img {
  width: 6.5625vw;
  min-width: 72px;
}

.p-nav_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.19792vw 0;
  pointer-events: all;
}

@media screen and (max-width: 992px) {
  .p-nav_top {
    padding: 25px 0;
  }
}

.p-nav_top_container {
  border-bottom: 1px solid #FFF852;
  width: 100vw;
  background-color: #000000;
  opacity: 0;
  transform: translateY(-100%);
  animation: trans-opacity-bot-100 1s ease .6s forwards;
}

.p-nav_top_right {
  display: flex;
  align-items: center;
  gap: 1.35417vw;
}

@media screen and (max-width: 992px) {
  .p-nav_top_right {
    gap: 20px;
  }
}

.p-nav_top_right .bouton {
  background-color: transparent;
  color: #ffffff;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
  display: inline-flex;
  gap: 0.72917vw;
  font-size: 1.4rem;
}

.p-nav_top_right .bouton:hover {
  background-color: #FFF852;
  color: #000000;
}

.p-nav_top_right .bouton:hover p {
  color: #000000;
}

.p-nav_top_right .bouton:hover svg g {
  fill: #000000;
}

.p-nav_top_right .bouton.v-index-blog {
  padding: 7px;
}

.p-nav_top_right .bouton.v-index-blog span {
  height: 100%;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFF852;
  color: #000000;
  border-radius: 2.8rem;
}

.p-nav_top_right .phone {
  border: 1px solid #FFF852;
  border-radius: 100vmax;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  aspect-ratio: 1;
}

.p-nav_top_center {
  display: flex;
  align-items: center;
  gap: 2.23958vw;
  font-size: 1.4rem;
}

@media screen and (max-width: 992px) {
  .p-nav_top_center {
    display: none;
  }
}

.p-nav_top_center li a {
  transition: all .3s ease !important;
}

.p-nav_top_center li:hover {
  color: #FFF852;
}

.p-nav_bottom {
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding-bottom: 2.91667vw;
  opacity: 0;
  transform: translateY(100%);
  animation: trans-opacity-top-100 1s ease .6s forwards;
}

@media screen and (max-width: 992px) {
  .p-nav_bottom {
    padding-bottom: 5vh;
  }
}

.p-nav_bottom .social {
  display: flex;
  align-items: center;
  gap: 18px;
  pointer-events: all;
}

.p-nav_bottom .social .item {
  border: 1px solid #FFF852;
  border-radius: 100vmax;
  width: 2.91667vw;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  min-width: 40px;
}

@media screen and (max-width: 992px) {
  .p-nav_bottom .social .item {
    display: none;
  }
}

.p-nav_bottom .social .item span {
  font-size: 14px;
  line-height: 1;
}

@media (min-width: 991px) {
  .p-nav_bottom .social .item span {
    font-size: calc(0.53821vw + 8.66631px);
  }
}

@media (min-width: 1920px) {
  .p-nav_bottom .social .item span {
    font-size: 19px;
  }
}

.p-nav_bottom .social .item:hover {
  background-color: #FFF852;
  color: #000000;
}

.p-nav_bottom .contact {
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
}

@media screen and (max-width: 992px) {
  .p-nav_bottom .contact {
    border: 1px solid #FFF852;
    background-color: #000000;
    border-radius: 100vmax;
    width: 70px;
    aspect-ratio: 1;
  }
}

.p-nav_bottom .contact-logo {
  position: absolute;
  width: 3.90625vw;
}

@media screen and (max-width: 992px) {
  .p-nav_bottom .contact-logo {
    width: 39px;
  }
}

.p-nav_bottom .contact-logo img {
  width: 100%;
  height: auto;
}

.p-nav_bottom .contact-text {
  width: 7.29167vw;
}

@media screen and (max-width: 992px) {
  .p-nav_bottom .contact-text {
    display: none;
  }
}

.p-nav_bottom .contact-text img {
  width: 100%;
  height: auto;
}

.p-nav_bottom .contact .contact-text {
  animation: rotation 20s linear forwards infinite;
}

.p-nav .bt-menu {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 992px) {
  .p-nav .bt-menu {
    display: none;
  }
}

.p-nav .bt-menu:after {
  padding-top: 100%;
}

.p-nav .bt-menu .barre {
  transform: rotate(0deg);
  width: 32px;
  height: 2px;
  position: relative;
  transition: all 0.3s ease 0s;
}

.p-nav .bt-menu .barre-mid {
  position: absolute;
  width: 44%;
  height: 2px;
  background: #ffffff;
  border-radius: 2px;
}

.p-nav .bt-menu .barre-mid_1 {
  left: 0;
}

.p-nav .bt-menu .barre-mid_2 {
  right: 0;
}

.p-nav .bt-menu .barre::before, .p-nav .bt-menu .barre::after {
  display: inline-block;
  width: 100%;
  height: 2px;
  top: -10px;
  background: #ffffff;
  border-radius: 2px;
  content: "";
  position: absolute;
  left: 0%;
  transition: top 0.3s ease 0.3s, transform 0.3s ease;
}

.p-nav .bt-menu .barre::after {
  top: 10px;
}

.p-nav .bt-menu.open .barre {
  transform: rotate(-45deg);
  transition: all 0.3s ease 0.3s;
}

.p-nav .bt-menu.open .barre-mid {
  transition: all 0.3s ease 0.3s;
}

.p-nav .bt-menu.open .barre-mid_1 {
  width: 40%;
}

.p-nav .bt-menu.open .barre-mid_2 {
  width: 50%;
}

.p-nav .bt-menu.open .barre::after, .p-nav .bt-menu.open .barre::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.3s ease 0s, transform 0.3s ease 0.3s;
}

.p-menu {
  position: fixed;
  top: -140%;
  left: 0;
  z-index: 103;
  width: 100%;
  transition: all .4s ease .3s;
  background-color: #FFF852;
  padding-top: 100px;
}

.p-menu_content {
  height: 100%;
  padding: 9.5vh;
}

.p-menu_nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 5.9vh;
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
  color: #000000;
}

@media (min-width: 320px) {
  .p-menu_nav {
    font-size: calc(9.61538vw + -14.76923px);
  }
}

@media (min-width: 424px) {
  .p-menu_nav {
    font-size: 26px;
  }
}

.p-menu_nav .bouton {
  background-color: #000000;
  color: #ffffff;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
  display: inline-flex;
  justify-content: center;
  gap: 14px;
  font-size: 14px !important;
  width: 270px;
  height: 55px;
}

.p-menu_nav .bouton img {
  width: 21.6px;
}

.p-menu_nav .bouton p {
  width: 145px;
  font-size: 14px !important;
}

.p-menu .anim-link {
  transform: translateY(100%);
  opacity: 0;
  transition: all 0.3s ease;
}

.p-menu.open {
  top: 0;
  transition: all .4s ease .2s;
}

.p-menu.open .anim-link {
  transform: translateY(0);
  opacity: 1;
  transition: all 0.3s ease;
}

.p-menu.open .anim-link:nth-child(1) {
  transition-delay: 0.4s;
}

.p-menu.open .anim-link:nth-child(2) {
  transition-delay: 0.5s;
}

.p-menu.open .anim-link:nth-child(3) {
  transition-delay: 0.6s;
}

.p-menu.open .anim-link:nth-child(4) {
  transition-delay: 0.7s;
}

.p-menu.open .anim-link:nth-child(5) {
  transition-delay: 0.8s;
}

.p-menu.open .anim-link:nth-child(6) {
  transition-delay: 0.9s;
}

.p-menu.open .anim-link:nth-child(7) {
  transition-delay: 1s;
}

.p-menu.open .anim-link:nth-child(8) {
  transition-delay: 1.1s;
}

.p-menu.open .anim-link:nth-child(9) {
  transition-delay: 1.2s;
}

.p-menu.open .anim-link:nth-child(10) {
  transition-delay: 1.3s;
}

.p-menu.open .anim-link:nth-child(11) {
  transition-delay: 1.4s;
}

.p-menu.open .anim-link:nth-child(12) {
  transition-delay: 1.5s;
}

.p-menu.open .anim-link:nth-child(13) {
  transition-delay: 1.6s;
}

.p-menu.open .anim-link:nth-child(14) {
  transition-delay: 1.7s;
}

.p-menu.open .anim-link:nth-child(15) {
  transition-delay: 1.8s;
}

.p-menu.open .anim-link:nth-child(16) {
  transition-delay: 1.9s;
}

.p-menu.open .anim-link:nth-child(17) {
  transition-delay: 2s;
}

.p-menu.open .anim-link:nth-child(18) {
  transition-delay: 2.1s;
}

.p-menu.open .anim-link:nth-child(19) {
  transition-delay: 2.2s;
}

.p-menu.open .anim-link:nth-child(20) {
  transition-delay: 2.3s;
}

.p-menu.open .anim-link:nth-child(21) {
  transition-delay: 2.4s;
}

.p-menu.open .anim-link:nth-child(22) {
  transition-delay: 2.5s;
}

.p-menu.open .anim-link:nth-child(23) {
  transition-delay: 2.6s;
}

.p-menu.open .anim-link:nth-child(24) {
  transition-delay: 2.7s;
}

.p-menu.open .anim-link:nth-child(25) {
  transition-delay: 2.8s;
}

.p-menu.open .anim-link:nth-child(26) {
  transition-delay: 2.9s;
}

.p-menu.open .anim-link:nth-child(27) {
  transition-delay: 3s;
}

.p-menu.open .anim-link:nth-child(28) {
  transition-delay: 3.1s;
}

.p-menu.open .anim-link:nth-child(29) {
  transition-delay: 3.2s;
}

.p-menu.open .anim-link:nth-child(30) {
  transition-delay: 3.3s;
}

.home {
  position: relative;
}

.home #v0 {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

@media screen and (max-width: 992px) {
  .home #v0 {
    display: none;
  }
}

.home .p-header, .home .p-footer {
  display: none;
}

.home .p-nav_top_right .bouton {
  height: 55px;
}

.home .p-nav_top_right .bouton p {
  font-size: 1.4rem;
}

.home .p-menu_nav {
  gap: 3.32vh;
}

.home-section1 {
  width: 100vw;
}

.home-section1 .micro-big {
  height: 100vh;
  max-width: 40vw;
  object-fit: contain;
  width: auto;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media screen and (max-width: 992px) {
  .home-section1 .micro-big {
    width: 90vw;
    height: unset;
    max-width: unset;
  }
}

@media screen and (max-width: 992px) {
  .home-section1 {
    min-height: 100vh;
    padding-top: 100px;
  }
}

.home-section1 .h1, .home-section1 .articlesUne_title {
  position: relative;
}

@media screen and (max-width: 992px) {
  .home-section1 .h1, .home-section1 .articlesUne_title {
    transform: none;
    font-size: 18px;
    line-height: 1.1;
    margin: 6.5vh 0 34px;
  }
}

@media screen and (max-width: 992px) and (min-width: 320px) {
  .home-section1 .h1, .home-section1 .articlesUne_title {
    font-size: calc(7.14286vw + -4.85714px);
  }
}

@media screen and (max-width: 992px) and (min-width: 390px) {
  .home-section1 .h1, .home-section1 .articlesUne_title {
    font-size: 23px;
  }
}

@media screen and (min-width: 992px) {
  .home-section1 .h1-container {
    position: absolute;
    width: 100%;
    transform: translateY(-30.7377vh);
  }
}

@media screen and (min-width: 992px) {
  .home-section1 .p-header_arrow {
    transform: rotate(-90deg);
    bottom: 0;
    right: 0;
    left: unset;
  }
}

.home-section1 p {
  position: absolute;
  transform: translateY(8.19672vh);
}

@media screen and (max-width: 992px) {
  .home-section1 p {
    position: relative;
    transform: none;
    font-size: 14px;
  }
}

.home-section3D {
  display: flex;
  align-items: end;
  margin-left: 24.63542vw;
  margin-right: 10vw;
  gap: 24.63542vw;
}

@media screen and (max-width: 992px) {
  .home-section3D {
    flex-direction: column;
    align-items: center;
    margin: 70px 30px 100px;
    gap: 45px;
  }
}

.home-section3D_bloc {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.home-section3D_bloc p {
  margin: 2.04918vh 0 3.07377vh;
  text-align: center;
  white-space: nowrap;
}

@media screen and (max-width: 992px) {
  .home-section3D_bloc p {
    white-space: unset;
    margin: 20px 0 37px;
  }
}

.home-section3D_bloc img {
  max-width: 70vw;
  max-height: 70vw;
  object-fit: contain;
  margin-bottom: 15px;
}

.home-section3D_lastBloc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 28.95833vw;
  height: 100%;
}

@media screen and (max-width: 992px) {
  .home-section3D_lastBloc {
    width: 100%;
    margin-top: 75px;
    align-items: center;
    text-align: center;
  }
}

.home-section3D_lastBloc img {
  width: 5.72917vw;
}

@media screen and (max-width: 992px) {
  .home-section3D_lastBloc img {
    width: 110px;
  }
}

.home-section3D_lastBloc .h1, .home-section3D_lastBloc .articlesUne_title {
  margin: 11.27049vh 0 4.09836vh;
}

@media screen and (max-width: 992px) {
  .home-section3D_lastBloc .h1, .home-section3D_lastBloc .articlesUne_title {
    margin: 40px 0 50px;
  }
}

.concept p {
  white-space: normal;
}

.concept .p-header {
  background-image: url(../img/concept/header.jpg);
}

@media screen and (max-width: 992px) {
  .concept .p-header {
    background-image: url(../img/concept/header-s.jpg);
  }
}

.concept .p-nav_bottom .social {
  visibility: hidden;
}

.concept .composant-2 {
  background-image: url(../img/concept/header.jpg);
}

@media screen and (max-width: 992px) {
  .concept .composant-2 {
    background-image: url(../img/concept/header-s.jpg);
  }
}

.concept .sticky {
  position: relative;
  z-index: 100;
  overflow: hidden;
}

@media screen and (max-width: 992px) {
  .concept .sticky {
    padding: 100px 30px 30px;
  }
}

.concept .sticky audio {
  pointer-events: all;
  max-width: 100%;
}

.concept .sticky .logomark {
  width: 51.04167vw;
  position: absolute;
  left: 30px;
}

@media screen and (max-width: 992px) {
  .concept .sticky .logomark {
    display: none;
  }
}

.concept .sticky_etapes {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 992px) {
  .concept .sticky .fixed-elem {
    position: absolute;
    top: 0;
    bottom: auto;
    width: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .concept .sticky .fixed-elem.fixed {
    position: fixed;
    pointer-events: none;
    top: auto;
    bottom: 0;
  }
}

@media screen and (min-width: 992px) and (max-width: 992px) {
  .concept .sticky .fixed-elem.fixed {
    width: calc(100vw - 30px * 2);
  }
}

@media screen and (min-width: 992px) {
  .concept .sticky .fixed-elem.absolute-end {
    position: absolute;
    top: auto;
    bottom: 0;
  }
}

@media screen and (max-width: 992px) {
  .concept .sticky .fixed-elem {
    max-width: 100%;
  }
}

.concept .sticky .fixed-elem .item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  transform-origin: center 200vw;
  transform: rotate(20deg);
}

@media screen and (max-width: 992px) {
  .concept .sticky .fixed-elem .item {
    transform-origin: center 200vh;
    transform: none !important;
    flex-direction: column;
    height: unset;
    gap: 80px;
  }
}

.concept .sticky .fixed-elem .item .personne {
  display: flex;
  gap: 3.2vw;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  margin: 0;
  transform-origin: center;
  transform: rotate(-20deg);
}

@media screen and (max-width: 992px) {
  .concept .sticky .fixed-elem .item .personne {
    flex-direction: column;
    align-items: start;
    transform: none !important;
    height: unset;
  }
}

.concept .sticky .fixed-elem .item .personne_container {
  position: absolute;
  transform-origin: center;
}

@media screen and (max-width: 992px) {
  .concept .sticky .fixed-elem .item .personne_container {
    transform: none !important;
    position: relative;
    max-width: 100%;
  }
}

.concept .sticky .fixed-elem .item .personne_container:nth-child(1) {
  transform: translate(-58vw, 10vw);
}

.concept .sticky .fixed-elem .item .personne_container:nth-child(3) {
  transform: translate(58vw, 10vw);
}

.concept .sticky .fixed-elem .item .personne .img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  border: 1px solid #FFF852;
  padding: 1.30208vw;
  border-radius: 100vmax;
}

@media screen and (max-width: 992px) {
  .concept .sticky .fixed-elem .item .personne .img {
    padding: 17px;
  }
}

.concept .sticky .fixed-elem .item .personne .img img {
  width: 23.28vw;
  min-width: 235px;
}

.concept .sticky .fixed-elem .item .personne .txt {
  width: 21.8vw;
  transition: 0.5s ease;
}

.concept .sticky .fixed-elem .item .personne .txt p {
  margin-top: 2.08333vw;
}

@media screen and (max-width: 992px) {
  .concept .sticky .fixed-elem .item .personne .txt p {
    margin-top: 40px;
  }
}

@media screen and (max-width: 992px) {
  .concept .sticky .fixed-elem .item .personne .txt {
    max-width: calc(100vw - 60px);
    width: 300px;
    border-left: none;
    padding-left: 0;
    margin-top: 5.9vh;
  }
}

.concept .sticky .fixed-elem .item .personne .txt-container {
  display: flex;
  flex-direction: column;
  gap: 2.08333vw;
}

@media screen and (max-width: 992px) {
  .concept .sticky .fixed-elem .item .personne .txt-container {
    gap: 15px;
    max-width: 100%;
  }
}

.concept .sticky .etape {
  overflow: hidden;
}

.concept .sticky .etape-part {
  position: relative;
  height: 100vh;
  z-index: 2;
}

@media screen and (max-width: 992px) {
  .concept .sticky .etape-part {
    height: unset;
  }
}

.podcast .p-header {
  background-image: url(../img/podcast/header.jpg);
}

@media screen and (max-width: 992px) {
  .podcast .p-header {
    background-image: url(../img/podcast/header-s.jpg);
  }
}

.podcast .p-nav_bottom .social {
  visibility: hidden;
}

.podcast .composant-2 {
  background-image: url(../img/podcast/header.jpg);
}

@media screen and (max-width: 992px) {
  .podcast .composant-2 {
    background-image: url(../img/podcast/header-s.jpg);
  }
}

.podcast .composant-2_txt-p {
  white-space: normal;
  text-align: center;
}

.podcast .derniers-podcasts {
  position: relative;
  overflow: hidden;
  padding-top: 3.64583vw;
  padding-bottom: 3.64583vw;
}

@media screen and (max-width: 992px) {
  .podcast .derniers-podcasts {
    padding-bottom: 40px;
    padding-top: 40px;
  }
}

.podcast .derniers-podcasts_title {
  margin-bottom: 6.77083vw;
}

@media screen and (max-width: 992px) {
  .podcast .derniers-podcasts_title {
    margin-bottom: 40px;
  }
}

.podcast .derniers-podcasts_iframe {
  height: 480px;
}

.radio .p-header {
  background-image: url(../img/radio/header.jpg);
}

@media screen and (max-width: 992px) {
  .radio .p-header {
    background-image: url(../img/radio/header-s.jpg);
  }
}

.radio .p-nav_bottom .social {
  visibility: hidden;
}

.radio .composant-2 {
  background-image: url(../img/radio/header.jpg);
}

@media screen and (max-width: 992px) {
  .radio .composant-2 {
    background-image: url(../img/radio/header-s.jpg);
  }
}

.radio .composant-2_txt-p {
  white-space: normal;
  text-align: center;
}

.radio .creations-radios {
  position: relative;
  overflow: hidden;
  padding-top: 3.64583vw;
  padding-bottom: 1.5625vw;
}

@media screen and (max-width: 992px) {
  .radio .creations-radios {
    padding-bottom: 40px;
    padding-top: 40px;
  }
}

.radio .creations-radios_title {
  margin-bottom: 6.77083vw;
}

@media screen and (max-width: 992px) {
  .radio .creations-radios_title {
    margin-bottom: 40px;
  }
}

.radio .creations-radios_iframe {
  width: 100%;
}

.radio .creations-radios_iframe iframe {
  border: 1px solid #1A1A1A;
  height: 145px;
  width: 100%;
  margin-bottom: 50px;
}

@media screen and (max-width: 992px) {
  .radio .creations-radios_iframe iframe {
    height: 365px;
  }
}

.radio .savoir-faire {
  position: relative;
  padding-top: 7.55208vw;
  padding-bottom: 8.33333vw;
  overflow: hidden;
}

.radio .savoir-faire_title {
  margin-bottom: 6.77083vw;
}

@media screen and (max-width: 992px) {
  .radio .savoir-faire_title {
    margin-bottom: 40px;
    text-align: center;
  }
}

@media screen and (min-width: 992px) {
  .radio .savoir-faire_content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 7.8125vw;
    row-gap: 5.20833vw;
  }
}

@media screen and (max-width: 992px) {
  .radio .savoir-faire_content_item:not(:last-child) {
    margin-bottom: 50px;
  }
}

.radio .savoir-faire_content_item p {
  margin-top: 2rem;
}

@media screen and (max-width: 992px) {
  .radio .savoir-faire_content_item p {
    margin-top: 20px;
  }
}

.formation .p-header {
  background-image: url(../img/formation/header.jpg);
}

@media screen and (max-width: 992px) {
  .formation .p-header {
    background-image: url(../img/formation/header-s.jpg);
  }
}

.formation .p-nav_bottom .social {
  visibility: hidden;
}

.formation .composant-1 .text-bottom {
  width: 32vw;
}

@media screen and (max-width: 992px) {
  .formation .composant-1 .text-bottom {
    width: 100%;
  }
}

@media screen and (max-width: 1500px) {
  .formation .composant-1 .text-bottom {
    width: 40vw;
  }
}

.formation .composant-2 {
  background-image: url(../img/formation/header.jpg);
}

@media screen and (max-width: 992px) {
  .formation .composant-2 {
    background-image: url(../img/formation/header-s.jpg);
  }
}

.formation .composant-2_txt-p {
  white-space: normal;
  text-align: center;
}

.animation .p-header {
  background-image: url(../img/animation/header.jpg);
}

@media screen and (max-width: 992px) {
  .animation .p-header {
    background-image: url(../img/animation/header-s.jpg);
  }
}

.animation .p-nav_bottom .social {
  visibility: hidden;
}

.animation .composant-2 {
  background-image: url(../img/animation/header.jpg);
}

@media screen and (max-width: 992px) {
  .animation .composant-2 {
    background-image: url(../img/animation/header-s.jpg);
  }
}

.animation .composant-2_txt-p {
  white-space: normal;
  text-align: center;
}

.animation .c-formats {
  overflow: visible;
}

.animation .croire {
  padding: 26.3vh 16.9vw;
  background-color: #000000;
}

@media screen and (max-width: 992px) {
  .animation .croire {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    padding: 10vh 0;
  }
}

.animation .croire_title {
  font-size: 20px;
  line-height: 1.8;
  font-size: 20px;
  line-height: 1.8;
  font-weight: 600;
  width: 25.26042vw;
  margin-bottom: 14.8vh;
  margin-left: 6.77083vw;
}

@media (min-width: 991px) {
  .animation .croire_title {
    font-size: calc(0.53821vw + 14.66631px);
  }
}

@media (min-width: 1920px) {
  .animation .croire_title {
    font-size: 25px;
  }
}

@media (min-width: 320px) {
  .animation .croire_title {
    font-size: calc(0.74627vw + 17.61194px);
  }
}

@media (min-width: 990px) {
  .animation .croire_title {
    font-size: 25px;
  }
}

@media screen and (max-width: 992px) {
  .animation .croire_title {
    width: 100%;
    padding: 0 30px;
    margin-left: 0;
  }
}

@media screen and (max-width: 1500px) {
  .animation .croire_title {
    width: 35vw;
  }
}

.animation .croire_title span {
  color: #FFF852;
}

.animation .croire_img {
  position: relative;
  width: fit-content;
}

.animation .croire_img-container {
  overflow: hidden;
}

.animation .croire_img-container.container1 {
  margin-left: 6.77083vw;
}

@media screen and (max-width: 992px) {
  .animation .croire_img-container.container1 {
    margin-left: 0;
  }
}

.animation .croire_img-container.container2 {
  margin-top: -17.1vh;
  margin-left: 27.08333vw;
}

@media screen and (max-width: 992px) {
  .animation .croire_img-container.container2 {
    display: flex;
    justify-content: flex-end;
    margin-left: 0;
    margin-top: 0;
  }
}

.animation .croire_img-container.container3 {
  margin-top: -7.4vh;
}

@media screen and (max-width: 992px) {
  .animation .croire_img-container.container3 {
    margin-top: 0;
  }
}

.animation .croire_img-container img {
  position: relative;
  height: auto;
  transition: all 1s;
}

.animation .croire_img-container img.masked {
  transform: scale(1.2);
  transition: none;
}

.animation .croire_img-container img.img1 {
  width: 25.52083vw;
}

@media screen and (max-width: 992px) {
  .animation .croire_img-container img.img1 {
    width: calc(100% - 30px);
  }
}

.animation .croire_img-container img.img2 {
  width: 39.0625vw;
}

@media screen and (max-width: 992px) {
  .animation .croire_img-container img.img2 {
    width: calc(100% - 30px);
  }
}

.animation .croire_img-container img.img3 {
  width: 39.0625vw;
}

@media screen and (max-width: 992px) {
  .animation .croire_img-container img.img3 {
    width: calc(100% - 30px);
  }
}

.animation .croire_img-pastille {
  position: absolute;
  right: -3.28125vw;
  top: -3.28125vw;
}

@media screen and (max-width: 992px) {
  .animation .croire_img-pastille {
    right: 30px;
    top: -13.2vw;
  }
}

.animation .croire_img-pastille img {
  width: 6.5625vw;
  height: 6.5625vw;
}

@media screen and (max-width: 992px) {
  .animation .croire_img-pastille img {
    width: 26.4vw;
    height: 26.4vw;
  }
}

.animation .croire_img-pastille img.croire_img-pastille--v {
  position: absolute;
  top: 0;
  left: 0;
}

.animation .simple-reference_logos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-radius: 5px;
  background-color: #1A1A1A;
  padding: 2.60417vw;
}

@media screen and (max-width: 992px) {
  .animation .simple-reference_logos {
    flex-direction: row;
    padding: 0;
    padding-left: 30px;
    padding-top: 45px;
    padding-bottom: 45px;
    width: 100%;
    margin: 0 auto;
  }
}

.animation .simple-reference_logos img {
  max-width: initial;
  max-height: initial;
  width: auto;
  height: 100%;
}

@media screen and (max-width: 992px) {
  .animation .simple-reference_logos img {
    height: 100%;
  }
}

.teambuilding .p-header {
  background-image: url(../img/teambuilding/header.jpg);
}

@media screen and (max-width: 992px) {
  .teambuilding .p-header {
    background-image: url(../img/teambuilding/header-s.jpg);
  }
}

.teambuilding .p-nav_bottom .social {
  visibility: hidden;
}

.teambuilding .composant-1 .text-bottom {
  width: 32vw;
}

@media screen and (max-width: 992px) {
  .teambuilding .composant-1 .text-bottom {
    width: 100%;
  }
}

@media screen and (max-width: 1500px) {
  .teambuilding .composant-1 .text-bottom {
    width: 40vw;
  }
}

.teambuilding .composant-2 {
  background-image: url(../img/teambuilding/header.jpg);
}

@media screen and (max-width: 992px) {
  .teambuilding .composant-2 {
    background-image: url(../img/teambuilding/header-s.jpg);
  }
}

.teambuilding .composant-2_txt-p {
  white-space: normal;
  text-align: center;
}

.contact .p-nav_bottom .social {
  visibility: hidden;
}

.contact_container .simple-container {
  display: flex;
  padding-top: 26vh;
  padding-bottom: 20.5vh;
}

@media screen and (max-width: 992px) {
  .contact_container .simple-container {
    flex-direction: column;
    gap: 4.6vh;
    padding-bottom: 11.8vh;
  }
}

.contact_container-titre {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4.6vh;
  background-color: #000000;
  width: 30vw;
  padding-right: 1.3vw;
}

@media screen and (max-width: 992px) {
  .contact_container-titre {
    width: 100%;
  }
}

.contact_container-titre--title {
  font-size: 20px;
  line-height: 1.1;
  font-size: 30px;
  line-height: 1.1;
  color: #FFF852;
}

@media (min-width: 991px) {
  .contact_container-titre--title {
    font-size: calc(2.69107vw + -6.66846px);
  }
}

@media (min-width: 1920px) {
  .contact_container-titre--title {
    font-size: 45px;
  }
}

@media (min-width: 320px) {
  .contact_container-titre--title {
    font-size: calc(0.74627vw + 27.61194px);
  }
}

@media (min-width: 990px) {
  .contact_container-titre--title {
    font-size: 35px;
  }
}

@media screen and (max-width: 992px) {
  .contact_container-titre--title {
    text-align: center;
  }
}

.contact_container-titre--subtitle {
  font-size: 16px;
  line-height: 1.5;
  font-size: 14px;
  line-height: 1.5;
  width: 18.4vw;
}

@media (min-width: 991px) {
  .contact_container-titre--subtitle {
    font-size: calc(0.96878vw + 6.39935px);
  }
}

@media (min-width: 1920px) {
  .contact_container-titre--subtitle {
    font-size: 25px;
  }
}

@media (min-width: 320px) {
  .contact_container-titre--subtitle {
    font-size: calc(0vw + 14px);
  }
}

@media (min-width: 990px) {
  .contact_container-titre--subtitle {
    font-size: 14px;
  }
}

@media screen and (max-width: 992px) {
  .contact_container-titre--subtitle {
    width: 100%;
    text-align: center;
  }
}

.contact_container-titre--arrow {
  margin-top: auto;
}

@media screen and (max-width: 992px) {
  .contact_container-titre--arrow {
    display: none;
  }
}

.contact_container-titre--arrow .icon {
  display: inline-block;
  position: relative;
  animation: upDown 2.5s linear infinite;
}

.contact_container-titre--arrow img {
  width: 3.6vw;
}

@media screen and (max-width: 992px) {
  .contact_container-titre--arrow img {
    width: 70px;
  }
}

.contact_map {
  display: flex;
  width: 100%;
  background-color: #FFF852;
}

@media screen and (max-width: 992px) {
  .contact_map {
    flex-direction: column;
  }
}

.contact_map-titre {
  display: flex;
  flex-direction: column;
  gap: 4.1vh;
  padding: 12vh 7vw 12vh 10.1vw;
  width: 34.5vw;
}

@media screen and (max-width: 1500px) {
  .contact_map-titre {
    width: 42vw;
  }
}

@media screen and (max-width: 992px) {
  .contact_map-titre {
    width: 100%;
    padding: 7.6vh 30px;
  }
}

.contact_map-titre--forme img {
  width: 5.2vw;
  height: auto;
}

@media screen and (max-width: 992px) {
  .contact_map-titre--forme img {
    width: 23.5vw;
  }
}

.contact_map-titre--title {
  font-size: 25px;
  line-height: 1.1;
  font-size: 25px;
  line-height: 1.1;
  color: #000000;
}

@media (min-width: 991px) {
  .contact_map-titre--title {
    font-size: calc(2.15285vw + 3.66523px);
  }
}

@media (min-width: 1920px) {
  .contact_map-titre--title {
    font-size: 45px;
  }
}

@media (min-width: 320px) {
  .contact_map-titre--title {
    font-size: calc(0.74627vw + 22.61194px);
  }
}

@media (min-width: 990px) {
  .contact_map-titre--title {
    font-size: 30px;
  }
}

.contact_map-titre--adresse {
  font-size: 14px;
  line-height: 1.5;
  color: #000000;
}

@media (min-width: 991px) {
  .contact_map-titre--adresse {
    font-size: calc(0.21529vw + 11.86652px);
  }
}

@media (min-width: 1920px) {
  .contact_map-titre--adresse {
    font-size: 16px;
  }
}

.contact_map-titre--adresse span {
  font-weight: 600;
}

.contact_map-map {
  display: block;
  width: 65.5vw;
}

@media screen and (max-width: 1500px) {
  .contact_map-map {
    width: 58vw;
    height: 59.2vh;
  }
}

@media screen and (max-width: 992px) {
  .contact_map-map {
    width: 100%;
  }
}

.legals #section-top {
  padding: 20vh 0;
}

.legals .simple-container {
  display: flex;
  flex-direction: column;
  gap: 20vh;
}

.legals_container {
  display: flex;
  flex-direction: column;
  gap: 10vh;
}

.legals_titre {
  font-weight: 600;
  font-size: 50px;
  color: #FFF852;
  margin-bottom: 5vh;
  text-align: center;
}

@media screen and (max-width: 992px) {
  .legals_titre {
    font-size: 30px;
  }
}

.legals_subtitle {
  font-weight: 600;
  font-size: 35px;
  color: #ffffff;
  margin-bottom: 2.5vh;
}

@media screen and (max-width: 992px) {
  .legals_subtitle {
    font-size: 25px;
  }
}

.legals_bloc {
  display: flex;
  flex-direction: column;
  gap: 2.5vh;
}

.legals_bloc p {
  color: #ffffff;
}

.legals_bloc p.legals_bloc-code {
  font-weight: 600;
  color: #ffffff;
  font-size: 25px;
  font-style: italic;
}

@media screen and (max-width: 992px) {
  .legals_bloc p.legals_bloc-code {
    font-size: 20px;
  }
}

.legals_bloc p span.strong {
  font-weight: 600;
}

.error404 .p-header,
.error404 .p-nav,
.error404 .p-footer {
  display: none;
}

.error404 .error {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-image: url(../img/concept/header.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 992px) {
  .error404 .error {
    background-image: url(../img/concept/header-s.jpg);
  }
}

.error404 .error_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4vh;
}

@media screen and (max-width: 992px) {
  .error404 .error_container {
    padding: 0 30px;
  }
}

.error404 .error_container-title {
  font-size: 200px;
  line-height: 1.1;
  font-size: 90px;
  line-height: 1.1;
  color: #FFF852;
  text-align: center;
}

@media (min-width: 991px) {
  .error404 .error_container-title {
    font-size: calc(2.58342vw + 174.39828px);
  }
}

@media (min-width: 1920px) {
  .error404 .error_container-title {
    font-size: 224px;
  }
}

@media (min-width: 320px) {
  .error404 .error_container-title {
    font-size: calc(5.97015vw + 70.89552px);
  }
}

@media (min-width: 990px) {
  .error404 .error_container-title {
    font-size: 130px;
  }
}

.error404 .error_container-subtitle {
  font-size: 30px;
  line-height: 1.5;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 5vh;
  text-align: center;
}

@media (min-width: 991px) {
  .error404 .error_container-subtitle {
    font-size: calc(0vw + 30px);
  }
}

@media (min-width: 1920px) {
  .error404 .error_container-subtitle {
    font-size: 30px;
  }
}

@media (min-width: 320px) {
  .error404 .error_container-subtitle {
    font-size: calc(0.29851vw + 15.04478px);
  }
}

@media (min-width: 990px) {
  .error404 .error_container-subtitle {
    font-size: 18px;
  }
}

.error404 .error_container .bouton {
  background-color: transparent;
  color: #ffffff;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
}

.error404 .error_container .bouton span {
  font-size: 18px;
  line-height: 1.1;
  font-size: 14px;
  line-height: 1.1;
  font-weight: 600;
}

@media (min-width: 991px) {
  .error404 .error_container .bouton span {
    font-size: calc(0vw + 18px);
  }
}

@media (min-width: 1920px) {
  .error404 .error_container .bouton span {
    font-size: 18px;
  }
}

@media (min-width: 320px) {
  .error404 .error_container .bouton span {
    font-size: calc(0.29851vw + 13.04478px);
  }
}

@media (min-width: 990px) {
  .error404 .error_container .bouton span {
    font-size: 16px;
  }
}

.error404 .error_container .bouton:hover {
  background-color: #FFF852;
  color: #000000;
}

.u-banner-cookies {
  position: fixed;
  bottom: 30px;
  left: 30px;
  transform: translateY(170%);
  z-index: 100000;
  overflow: hidden;
  opacity: 0;
  transition: all .5s ease .25s;
}

@media screen and (max-width: 992px) {
  .u-banner-cookies {
    bottom: 15px;
    left: 30px;
    width: 100%;
  }
}

.u-banner-cookies_content {
  width: 48rem;
  background-color: #FFF852;
  padding: 3rem 4rem;
  border: 2px solid #000000;
  display: flex;
  flex-direction: column;
  white-space: normal;
}

@media screen and (max-width: 992px) {
  .u-banner-cookies_content {
    width: calc(100vw - 60px);
    padding: 15px;
    display: block;
  }
}

.u-banner-cookies .bot {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.u-banner-cookies .bot .bouton {
  min-width: 16rem;
  padding: 0 2rem;
  font-size: 1.4rem;
  height: 3.5rem;
  border-radius: 2.5rem;
  border: 1px solid #000000;
}

@media screen and (max-width: 992px) {
  .u-banner-cookies .bot {
    width: 100%;
    margin-left: 0;
    flex-direction: row;
  }
  .u-banner-cookies .bot .bouton {
    width: calc(50% - 5px);
    min-width: 0;
    margin: 0;
    height: 40px;
    font-size: 14px;
    border-radius: 25px;
  }
}

.u-banner-cookies_parametre {
  font-family: "transducer-extended";
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  color: #000000;
  text-align: left;
  margin-top: 2rem;
  text-decoration: underline;
  margin-bottom: 2rem;
}

@media screen and (max-width: 992px) {
  .u-banner-cookies_parametre {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.u-banner-cookies.active {
  transform: translateY(0);
  opacity: 1;
}

@media screen and (max-width: 992px) {
  .u-banner-cookies.active {
    transform: translateY(0) translateX(0);
  }
}

#tarteaucitronAlertBig {
  display: none !important;
}

/** BETTER MOBILE MODE **/
@media screen and (max-width: 767px) {
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder ul .tarteaucitronLine {
    padding: 16px !important;
  }
  html body #tarteaucitronRoot #tarteaucitron div#tarteaucitronMainLineOffset .tarteaucitronName {
    display: none !important;
  }
  #tarteaucitronServices_mandatory li.tarteaucitronLine .tarteaucitronName span {
    width: 100% !important;
    display: inline-block;
  }
  li.tarteaucitronLine .tarteaucitronName span {
    width: 100% !important;
    display: inline-block;
  }
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group {
    width: 10% !important;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 0px;
    padding: 10px 0;
  }
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group:before {
    content: '\0025BE';
    font-weight: 700;
    font-size: 14px;
  }
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder .tarteaucitronIsExpanded button.tarteaucitron-toggle-group:before {
    content: '\0025B4';
  }
}

@media screen and (min-width: 768px) {
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group:after {
    content: '\0025BE';
    font-weight: 700;
    font-size: 14px;
    margin-left: 15px;
  }
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder .tarteaucitronIsExpanded button.tarteaucitron-toggle-group:after {
    content: '\0025B4';
    margin-left: 15px;
  }
}

/****/
/***
* Reset CSS
*/
#tarteaucitronRoot div,
#tarteaucitronRoot span,
#tarteaucitronRoot applet,
#tarteaucitronRoot object,
#tarteaucitronRoot iframe,
#tarteaucitronRoot h1,
#tarteaucitronRoot h2,
#tarteaucitronRoot h3,
#tarteaucitronRoot h4,
#tarteaucitronRoot h5,
#tarteaucitronRoot h6,
#tarteaucitronRoot p,
#tarteaucitronRoot blockquote,
#tarteaucitronRoot pre,
#tarteaucitronRoot a,
#tarteaucitronRoot abbr,
#tarteaucitronRoot acronym,
#tarteaucitronRoot address,
#tarteaucitronRoot big,
#tarteaucitronRoot cite,
#tarteaucitronRoot code,
#tarteaucitronRoot del,
#tarteaucitronRoot dfn,
#tarteaucitronRoot em,
#tarteaucitronRoot img,
#tarteaucitronRoot ins,
#tarteaucitronRoot kbd,
#tarteaucitronRoot q,
#tarteaucitronRoot s,
#tarteaucitronRoot samp,
#tarteaucitronRoot small,
#tarteaucitronRoot strike,
#tarteaucitronRoot strong,
#tarteaucitronRoot sub,
#tarteaucitronRoot sup,
#tarteaucitronRoot tt,
#tarteaucitronRoot var,
#tarteaucitronRoot b,
#tarteaucitronRoot u,
#tarteaucitronRoot i,
#tarteaucitronRoot center,
#tarteaucitronRoot dl,
#tarteaucitronRoot dt,
#tarteaucitronRoot dd,
#tarteaucitronRoot ol,
#tarteaucitronRoot ul,
#tarteaucitronRoot li,
#tarteaucitronRoot fieldset,
#tarteaucitronRoot form,
#tarteaucitronRoot label,
#tarteaucitronRoot legend,
#tarteaucitronRoot table,
#tarteaucitronRoot caption,
#tarteaucitronRoot tbody,
#tarteaucitronRoot tfoot,
#tarteaucitronRoot thead,
#tarteaucitronRoot tr,
#tarteaucitronRoot th,
#tarteaucitronRoot td,
#tarteaucitronRoot article,
#tarteaucitronRoot aside,
#tarteaucitronRoot canvas,
#tarteaucitronRoot details,
#tarteaucitronRoot embed,
#tarteaucitronRoot figure,
#tarteaucitronRoot figcaption,
#tarteaucitronRoot footer,
#tarteaucitronRoot header,
#tarteaucitronRoot hgroup,
#tarteaucitronRoot menu,
#tarteaucitronRoot nav,
#tarteaucitronRoot output,
#tarteaucitronRoot ruby,
#tarteaucitronRoot section,
#tarteaucitronRoot summary,
#tarteaucitronRoot time,
#tarteaucitronRoot mark,
#tarteaucitronRoot audio,
#tarteaucitronRoot video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  /*background: initial;*/
  text-align: initial;
  text-shadow: initial;
}

/* Animation */
#tarteaucitronRoot * {
  transition: border 300ms, background 300ms, opacity 200ms, box-shadow 400ms;
}

/* HTML5 display-role reset for older browsers */
#tarteaucitronRoot article,
#tarteaucitronRoot aside,
#tarteaucitronRoot details,
#tarteaucitronRoot figcaption,
#tarteaucitronRoot figure,
#tarteaucitronRoot footer,
#tarteaucitronRoot header,
#tarteaucitronRoot hgroup,
#tarteaucitronRoot menu,
#tarteaucitronRoot nav,
#tarteaucitronRoot section {
  display: block;
}

#tarteaucitronRoot ol,
#tarteaucitronRoot ul {
  list-style: none;
}

#tarteaucitronRoot blockquote,
#tarteaucitronRoot q {
  quotes: none;
}

#tarteaucitronRoot blockquote:before,
#tarteaucitronRoot blockquote:after,
#tarteaucitronRoot q:before,
#tarteaucitronRoot q:after {
  content: '';
  content: none;
}

#tarteaucitronRoot table {
  border-collapse: collapse;
  border-spacing: 0;
}

#tarteaucitronRoot a:focus-visible,
#tarteaucitronRoot button:focus-visible {
  outline: 3px dashed #3d86d8;
}

/***
 * Better scroll management
 */
div#tarteaucitronMainLineOffset {
  margin-top: 0 !important;
}

div#tarteaucitronServices {
  margin-top: 26px !important;
  height: auto !important;
}

#tarteaucitronServices::-webkit-scrollbar {
  width: 5px;
}

#tarteaucitronServices::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
}

#tarteaucitronServices::-webkit-scrollbar-thumb {
  background-color: #ddd;
  outline: 0px solid slategrey;
}

/***
 * Responsive layout for the control panel
 */
@media screen and (max-width: 479px) {
  #tarteaucitron .tarteaucitronLine .tarteaucitronName {
    width: 90% !important;
  }
  #tarteaucitron .tarteaucitronLine .tarteaucitronAsk {
    float: left !important;
    margin: 10px 15px 5px;
  }
}

@media screen and (max-width: 767px) {
  #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer,
  #tarteaucitron {
    background: #fff;
    border: 0 !important;
    bottom: 0 !important;
    height: 100% !important;
    left: 0 !important;
    margin: 0 !important;
    max-height: 100% !important;
    max-width: 100% !important;
    top: 0 !important;
    width: 100% !important;
  }
  #tarteaucitron .tarteaucitronBorder {
    border: 0 !important;
  }
  #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList {
    border: 0 !important;
  }
  #tarteaucitron #tarteaucitronServices .tarteaucitronTitle {
    text-align: left !important;
  }
  .tarteaucitronName .tarteaucitronH2 {
    max-width: 80%;
  }
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
    text-align: center !important;
  }
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk button {
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  #tarteaucitron {
    border: 0 !important;
    left: 0 !important;
    margin: 0 5% !important;
    max-height: 80% !important;
    width: 90% !important;
  }
}

#tarteaucitron {
  border-radius: 0 !important;
}

#tarteaucitronRoot div#tarteaucitron {
  left: 0;
  right: 0;
  top: 50% !important;
  transform: translateY(-50%);
  margin: auto;
}

@media screen and (max-width: 1500px) {
  #tarteaucitronRoot div#tarteaucitron {
    overflow: scroll;
  }
}

#tarteaucitron .clear {
  clear: both;
}

.tarteaucitronListCookies {
  display: block;
}

#tarteaucitron a {
  color: #000000;
  font-size: 11px;
  font-weight: 400;
  text-decoration: none;
}

#tarteaucitronRoot button {
  background: transparent;
  border: 0;
}

#tarteaucitronAlertBig strong,
#tarteaucitronAlertSmall strong,
#tarteaucitronAlertBig a,
#tarteaucitronAlertSmall a {
  color: #fff;
}

#tarteaucitron strong {
  font-size: 22px;
  font-weight: 500;
}

#tarteaucitron ul {
  padding: 0;
}

#tarteaucitron .tarteaucitronH1,
#tarteaucitron .tarteaucitronH2,
#tarteaucitron .tarteaucitronH3,
#tarteaucitron .tarteaucitronH4,
#tarteaucitron .tarteaucitronH5,
#tarteaucitron .tarteaucitronH6 {
  display: block;
}

span.tarteaucitronH3 {
  font-weight: 600 !important;
  color: #ffffff !important;
  font-size: 16px !important;
}

#tarteaucitron #tarteaucitronServices_mandatory .tarteaucitronH3 {
  font-size: 16px !important;
}

#tarteaucitronRoot * {
  color: #000000;
  background-color: #ffffff;
}

#tarteaucitronRoot .tarteaucitronH1 {
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
  color: #000000;
  margin: 0 25px;
  margin-bottom: 4rem;
}

@media screen and (max-width: 992px) {
  #tarteaucitronRoot .tarteaucitronH1 {
    font-size: 25px;
    margin-bottom: 40px;
  }
}

#tarteaucitronRoot .tarteaucitronH2 {
  display: inline-block;
  margin: 12px 0 0 10px;
  color: #000000;
}

#tarteaucitronRoot button#tarteaucitronBack {
  background: #000000;
}

#tarteaucitronBack {
  background: #000000;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0.7;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2147483646;
}

#tarteaucitron {
  display: none;
  max-height: 80%;
  left: 50%;
  margin: 0 auto 0 -430px;
  padding: 0;
  position: fixed;
  top: 6%;
  width: 860px;
  z-index: 2147483647;
  border-radius: 3rem;
}

#tarteaucitron .tarteaucitronBorder {
  background: #fff;
  height: auto;
  overflow: auto;
}

#tarteaucitron #tarteaucitronClosePanel {
  background: #000000;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  text-decoration: none;
  padding: 4px 0;
  position: absolute;
  right: 0;
  text-align: center;
  width: 70px;
  z-index: 10000;
}

#tarteaucitron br {
  display: none;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
  display: none;
  position: relative;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronTitle button,
#tarteaucitron #tarteaucitronInfo,
#tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
  color: #ffffff;
  display: block;
  font-size: 16px;
  font-weight: 700;
  padding: 5px 20px;
  text-align: left;
  width: 100%;
  background: #000000;
}

#tarteaucitron #tarteaucitronInfo,
#tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
  color: #ffffff;
  display: none;
  font-size: 12px;
  font-weight: 500;
  margin-top: 0;
  max-width: 290px;
  padding: 20px;
  position: absolute;
  z-index: 2147483647;
  line-height: normal;
  background: #000000;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine {
  background: #ffffff;
  margin: 0;
  overflow: hidden;
  padding: 15px;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine {
  background: #ffffff;
  margin-bottom: 0;
  margin-top: 21px;
  position: relative;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName {
  margin-left: 15px;
  margin-top: 2px;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName {
  display: inline-block;
  float: left;
  margin-left: 0px;
  text-align: left;
  width: 50%;
  font-family: "transducer-extended";
  font-weight: 600;
  font-size: 18px;
  line-height: 1.4;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
  display: inline-block;
  float: right;
  margin: 7px 15px 0;
  text-align: right;
}

@media screen and (max-width: 992px) {
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    margin-top: 5px;
  }
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronAllow,
#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronDeny {
  background: transparent;
  border: 2px solid #000000;
  color: #000000;
  font-family: "transducer-extended";
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1.2rem;
  border-radius: 2.5rem;
  height: 4rem;
  width: 16rem;
  margin: 10px;
  cursor: pointer;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronAllow:hover,
#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronDeny:hover {
  background-color: #000000;
  color: #ffffff;
}

@media screen and (max-width: 992px) {
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronAllow,
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronDeny {
    height: 40px;
    width: 120px;
    border-radius: 25px;
    font-size: 12px;
  }
}

#tarteaucitron #tarteaucitronServices #tarteaucitronAllAllowed.tarteaucitronIsSelected {
  background: #1B870B;
  opacity: 1;
}

#tarteaucitron #tarteaucitronServices #tarteaucitronAllAllowed.tarteaucitronIsSelected:hover {
  background: #1B870B;
  opacity: 1;
}

#tarteaucitron #tarteaucitronServices #tarteaucitronAllDenied.tarteaucitronIsSelected,
#tarteaucitron #tarteaucitronServices #tarteaucitronAllDenied2.tarteaucitronIsSelected {
  background: red;
  color: #ffffff;
  opacity: 1;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed .tarteaucitronAllow {
  background: #1B870B;
  color: #ffffff;
  border: none;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed .tarteaucitronAllow:hover {
  background: #1B870B;
  color: #ffffff;
  border: none;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied .tarteaucitronDeny {
  background: red;
  color: #ffffff;
  border: none;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied .tarteaucitronDeny:hover {
  color: #ffffff;
  background: red;
}

#tarteaucitron .tarteaucitronH3 {
  font-size: 18px;
  color: #000000 !important;
}

div#tarteaucitronInfo {
  display: block !important;
  position: relative !important;
  text-align: center !important;
  max-width: 80% !important;
  padding: 15px 30px !important;
  margin: -10px auto 20px !important;
  font-size: 12px !important;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: #000000;
  line-height: 1.4 !important;
  background-color: #000000 !important;
}

.tarteaucitronIsAllowed .tarteaucitronDeny {
  opacity: 0.4 !important;
}

.tarteaucitronIsDenied .tarteaucitronAllow {
  opacity: 0.4 !important;
}

.tarteaucitronLine .tarteaucitronAllow,
.tarteaucitronLine .tarteaucitronDeny {
  opacity: 1;
}

.tarteaucitronIsAllowed .tarteaucitronAllow {
  opacity: 1 !important;
}

.tarteaucitronIsDenied .tarteaucitronDeny {
  opacity: 1 !important;
}

#tarteaucitronServices_mandatory button.tarteaucitronAllow {
  opacity: 1;
}

.tarteaucitron-display-block {
  display: block;
}

.tarteaucitron-display-none {
  display: none;
}

.c-formulaire {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5vh;
  overflow: hidden;
  width: 70vw;
  /*************************************************
    // FORMULAIRE / VALIDATION
    *************************************************/
}

@media screen and (max-width: 992px) {
  .c-formulaire {
    gap: 4.6vh;
    width: 100%;
  }
}

.c-formulaire_bloc {
  display: flex;
  justify-content: space-between;
  gap: 1.5vw;
  width: 100%;
}

@media screen and (max-width: 992px) {
  .c-formulaire_bloc {
    flex-direction: column;
    gap: 4.6vh;
  }
}

.c-formulaire_bloc-content {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 3.2vh;
  width: 50%;
}

@media screen and (max-width: 992px) {
  .c-formulaire_bloc-content {
    gap: 4.6vh;
    width: 100%;
  }
}

.c-formulaire .bouton {
  font-size: 14px;
  line-height: 1.1;
  font-size: 14px;
  line-height: 1.1;
  font-weight: 600;
  background-color: transparent;
  color: #ffffff;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (min-width: 991px) {
  .c-formulaire .bouton {
    font-size: calc(0.43057vw + 9.73305px);
  }
}

@media (min-width: 1920px) {
  .c-formulaire .bouton {
    font-size: 18px;
  }
}

@media (min-width: 320px) {
  .c-formulaire .bouton {
    font-size: calc(0.29851vw + 13.04478px);
  }
}

@media (min-width: 990px) {
  .c-formulaire .bouton {
    font-size: 16px;
  }
}

@media screen and (max-width: 992px) {
  .c-formulaire .bouton {
    width: fit-content;
    align-self: center;
  }
}

.c-formulaire .bouton:hover {
  background-color: #FFF852;
  color: #000000;
}

.c-formulaire_field {
  position: relative;
  display: flex;
  flex-direction: column;
}

.c-formulaire_field.message {
  height: 100%;
}

.c-formulaire_field_label {
  font-size: 14px;
  line-height: 1.5;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}

@media (min-width: 991px) {
  .c-formulaire_field_label {
    font-size: calc(0.43057vw + 9.73305px);
  }
}

@media (min-width: 1920px) {
  .c-formulaire_field_label {
    font-size: 18px;
  }
}

@media (min-width: 320px) {
  .c-formulaire_field_label {
    font-size: calc(0.29851vw + 15.04478px);
  }
}

@media (min-width: 990px) {
  .c-formulaire_field_label {
    font-size: 18px;
  }
}

.c-formulaire_field .form-radio {
  position: relative;
  padding-left: 50px;
  margin-bottom: 10px;
}

.c-formulaire_field .form-radio input[type=radio],
.c-formulaire_field .form-radio input[type=checkbox] {
  appearance: none;
  opacity: 0;
  width: 0;
  height: 0;
}

.c-formulaire_field .form-radio label {
  font-size: 10px;
  line-height: 1.5;
  display: inline-block;
  font-family: "transducer-extended";
  font-weight: 400;
  cursor: pointer;
  margin: 0;
  width: 70%;
  color: #ffffff;
}

@media (min-width: 991px) {
  .c-formulaire_field .form-radio label {
    font-size: calc(0vw + 10px);
  }
}

@media (min-width: 1920px) {
  .c-formulaire_field .form-radio label {
    font-size: 10px;
  }
}

@media screen and (max-width: 1500px) {
  .c-formulaire_field .form-radio label {
    width: 100%;
  }
}

@media screen and (max-width: 992px) {
  .c-formulaire_field .form-radio label {
    width: 100%;
  }
}

.c-formulaire_field .form-radio label:after {
  display: block;
  content: '';
  width: 20px;
  height: 20px;
  border: solid 2px #FFF852;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
  transition: all .2s ease;
}

.c-formulaire_field .form-radio label:before {
  display: block;
  content: '';
  width: 12px;
  height: 12px;
  background: #FFF852;
  position: absolute;
  left: 6px;
  top: 0;
  bottom: 0;
  z-index: 2;
  margin: auto;
  transition: all .2s ease;
  opacity: 0;
}

.c-formulaire_field .form-radio input[type=radio]:checked + label:before,
.c-formulaire_field .form-radio input[type=checkbox]:checked + label:before {
  opacity: 1;
}

.c-formulaire_field ::-webkit-input-placeholder {
  color: #000000;
  opacity: 1;
}

.c-formulaire_field ::-moz-placeholder {
  color: #000000;
  opacity: 1;
}

.c-formulaire_field ::-ms-placeholder {
  color: #000000;
  opacity: 1;
}

.c-formulaire_field ::-ms-input-placeholder {
  color: #000000;
  opacity: 1;
}

.c-formulaire_field :-ms-input-placeholder {
  color: #000000;
  opacity: 1;
}

.c-formulaire_field input[type=tel],
.c-formulaire_field input[type=email],
.c-formulaire_field input[type=text],
.c-formulaire_field textarea {
  width: 100%;
  transition: all .5s ease;
  border: solid 1px #FFF852;
  border-radius: 100vmax;
  color: #FFF852;
  font-family: "transducer-extended";
  font-size: 14px;
  line-height: 1.5;
  padding: 0 15px;
  height: 50px;
}

@media (min-width: 991px) {
  .c-formulaire_field input[type=tel],
  .c-formulaire_field input[type=email],
  .c-formulaire_field input[type=text],
  .c-formulaire_field textarea {
    font-size: calc(0vw + 14px);
  }
}

@media (min-width: 1920px) {
  .c-formulaire_field input[type=tel],
  .c-formulaire_field input[type=email],
  .c-formulaire_field input[type=text],
  .c-formulaire_field textarea {
    font-size: 14px;
  }
}

.c-formulaire_field input[type=tel]:focus,
.c-formulaire_field input[type=email]:focus,
.c-formulaire_field input[type=text]:focus,
.c-formulaire_field textarea:focus {
  background: rgba(255, 255, 255, 0.15);
}

.c-formulaire_field textarea {
  padding-top: 10px;
  height: 100%;
  border-radius: 25px;
}

@media screen and (max-width: 992px) {
  .c-formulaire_field textarea {
    height: 200px;
    border-radius: 15px;
  }
}

.c-formulaire_field .dropdown-toggle {
  display: block;
  width: 100%;
  position: relative;
}

.c-formulaire_field .dropdown-toggle .dropdown-toggle-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  justify-content: space-between;
  width: 100%;
  vertical-align: middle;
  transition: all .5s ease 0s;
  border: solid 1px #000000;
  color: #000000;
  font-size: 14px;
  height: 8rem;
  max-height: 60px;
  padding: 0 42px 0 15px;
  position: relative;
}

@media (min-width: 1200px) {
  .c-formulaire_field .dropdown-toggle .dropdown-toggle-btn {
    font-size: 16px;
  }
}

.c-formulaire_field .dropdown-toggle .dropdown-toggle-btn:focus {
  color: #ffffff;
  background: #000000;
  border-color: #000000;
}

.c-formulaire_field .dropdown-toggle .dropdown-toggle-btn .icon-cursor {
  display: inline-flex;
  font-size: 2rem;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
}

.c-formulaire_field .dropdown-toggle ul.dropdown {
  position: absolute;
  top: 100%;
  bottom: auto;
  left: 0;
  right: 0;
  z-index: 2;
  background: #000000;
  overflow: hidden;
  max-height: 0;
  transition: all .5s ease;
  font-size: 14px;
}

@media (min-width: 1200px) {
  .c-formulaire_field .dropdown-toggle ul.dropdown {
    font-size: 16px;
  }
}

.c-formulaire_field .dropdown-toggle ul.dropdown li {
  max-height: 0;
  padding: 0 15px;
  line-height: 50px;
  transition: all .5s ease;
  color: #ffffff;
}

.c-formulaire_field .dropdown-toggle ul.dropdown li a:hover {
  opacity: .5;
}

.c-formulaire_field .dropdown-toggle.open a.toggle {
  transition-delay: 0s;
}

.c-formulaire_field .dropdown-toggle.open ul.dropdown {
  max-height: 300px;
  overflow: auto;
  transition: all .5s ease 0s;
}

.c-formulaire_field .dropdown-toggle.open ul.dropdown li {
  max-height: 50px;
  transition-delay: 0s;
}

.c-formulaire_field::after {
  margin-top: 0;
  display: block;
  content: "";
  height: 0;
  opacity: 0;
  transition: 0.5s all ease-out;
}

.c-formulaire_field.error::after {
  height: 15px;
  margin: 5px;
  content: attr(data-message);
  color: #FFF852;
  opacity: 1;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.05em;
  top: 0;
  text-align: left;
}

.c-formulaire_file input[type=file] {
  opacity: 0;
  width: 0;
  height: 0;
  appearance: none;
  position: absolute;
}

.c-formulaire_file label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #000000;
  color: #ffffff;
  cursor: pointer;
  height: 40px;
  padding: 0 20px;
  font-size: 14px;
}

@media (min-width: 760px) {
  .c-formulaire_file label {
    font-size: 15px;
    padding: 0 25px;
    height: 50px;
  }
}

@media (min-width: 1200px) {
  .c-formulaire_file label {
    font-size: 16px;
    padding: 0 30px;
    height: 60px;
  }
}

.c-formulaire_file label:after, .c-formulaire_file label:before {
  content: normal;
  display: none;
}

.c-formulaire_file .name-file {
  display: block;
  max-width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.c-formulaire_overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.8);
  width: auto;
  transition: all .6s ease;
  cursor: pointer;
}

@media (max-width: 992px) and (min-width: 760px) {
  .c-formulaire_overlay {
    width: calc(100% - 30px);
    margin: auto;
  }
}

.c-formulaire_overlay_text {
  position: absolute;
  top: 40%;
  bottom: auto;
  left: 0;
  right: 0;
  z-index: 100;
  margin: auto;
  width: 100%;
  height: auto;
  min-height: auto;
  display: block;
  opacity: 0;
  transform: translateY(-50%);
  transition: all .5s ease;
}

@media (min-width: 760px) {
  .c-formulaire_overlay_text {
    width: 75%;
  }
}

@media (min-width: 1200px) {
  .c-formulaire_overlay_text {
    width: 60%;
  }
}

.c-formulaire_overlay_text p {
  line-height: 1.33;
  text-align: center;
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  padding: 5vw 30px;
  background: rgba(255, 255, 255, 0.85);
}

@media (min-width: 992px) {
  .c-formulaire_overlay_text p {
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  .c-formulaire_overlay_text p {
    font-size: 20px;
  }
}

@media (min-width: 1500px) {
  .c-formulaire_overlay_text p {
    font-size: 24px;
  }
}

.c-formulaire_overlay_text p .icon {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 25px;
  height: auto;
}

.c-formulaire_overlay_text.active {
  opacity: 1;
  top: 50%;
}

.c-formulaire_overlay.close {
  opacity: 0;
}

.c-formulaire_overlay.close .form-msg {
  opacity: 0;
  top: 60%;
}

.c-formulaire_overlay.under {
  z-index: -1;
}

[js-collapse-wrapper] > [js-collapse-content] {
  max-height: 0;
  opacity: 0;
  transition: opacity 0.3s ease, max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

[js-collapse-wrapper] > [js-collapse-content].open {
  max-height: 100vh;
  opacity: 1;
  transition: opacity .3s ease, max-height 1s ease-in-out;
}

.accelerateurs {
  display: flex;
  align-items: end;
  justify-content: center;
  gap: 6.77083vw;
  padding: 4.16667vw 0 8.33333vw;
}

@media screen and (max-width: 992px) {
  .accelerateurs {
    padding: 45px 0 90px;
    flex-direction: column;
    align-items: center;
  }
}

.accelerateurs-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 190px;
}

.accelerateurs-item img {
  max-width: 70vw;
  max-height: 70vw;
  object-fit: contain;
  margin-top: 60px;
}

.accelerateurs-item .h2 {
  font-size: 16px;
  line-height: 1.1;
  white-space: nowrap;
}

@media (min-width: 991px) {
  .accelerateurs-item .h2 {
    font-size: calc(0.43057vw + 11.73305px);
  }
}

@media (min-width: 1920px) {
  .accelerateurs-item .h2 {
    font-size: 20px;
  }
}

@media screen and (max-width: 992px) {
  .accelerateurs-item .h2 {
    font-size: 20px;
  }
}

.accelerateurs-item .play {
  border: 1px solid #FFF852;
  border-radius: 100vmax;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  aspect-ratio: 1;
  transition: all 0.3s ease;
  margin: 30px 0;
  background-color: #000000;
}

.accelerateurs-item .play:hover {
  background-color: #FFF852;
}

.accelerateurs-item .play:hover svg path {
  fill: #000000;
}

.accelerateurs-item .play svg {
  width: 20px;
  margin-left: 6px;
}

@media screen and (max-width: 992px) {
  .accelerateurs-item .play::before {
    position: absolute;
    z-index: -1;
    content: "";
    height: 1px;
    width: 100vw;
    left: 0;
    background-color: #FFF852;
  }
}

.accelerateurs-item:first-child .play::before {
  position: absolute;
  z-index: -1;
  content: "";
  height: 1px;
  width: 100vw;
  left: 0;
  background-color: #FFF852;
}

.accelerateurs_big {
  display: flex;
  flex-direction: column;
}

.accelerateurs_big-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1.5625vw 0;
}

.accelerateurs_big-item img {
  max-width: 70vw;
  max-height: 70vw;
  object-fit: contain;
}

.accelerateurs_big-item + .accelerateurs_big-item {
  border-top: 1px solid #FFF852;
}

@media screen and (max-width: 992px) {
  .accelerateurs_big-item {
    flex-direction: column;
    text-align: center;
    padding: 30px 0;
  }
}

.accelerateurs_big-item_left {
  display: flex;
  align-items: center;
  gap: 3.64583vw;
}

@media screen and (max-width: 992px) {
  .accelerateurs_big-item_left {
    flex-direction: column;
    gap: 35px;
  }
}

.accelerateurs_big-item_left .text {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media screen and (max-width: 992px) {
  .accelerateurs_big-item_left .text {
    align-items: center;
  }
}

.accelerateurs_big-item_left .text p {
  width: 27.70833vw;
  white-space: normal;
}

@media screen and (max-width: 992px) {
  .accelerateurs_big-item_left .text p {
    width: 100%;
    text-align: center;
  }
}

.accelerateurs_big-item .play {
  border: 1px solid #FFF852;
  border-radius: 100vmax;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  aspect-ratio: 1;
  transition: all 0.3s ease;
  margin: 30px 0;
  background-color: #000000;
}

.accelerateurs_big-item .play:hover {
  background-color: #FFF852;
}

.accelerateurs_big-item .play:hover svg path {
  fill: #000000;
}

.accelerateurs_big-item .play svg {
  width: 20px;
  margin-left: 6px;
}

.composant-1 {
  display: flex;
  flex-direction: column;
  gap: 6.9vh;
  width: 100%;
  padding: 27.7vh 23.6vw;
}

@media screen and (max-width: 992px) {
  .composant-1 {
    align-items: center;
    gap: 4.6vh;
    padding: 11.8vh 30px;
  }
}

.composant-1 .text-top {
  position: relative;
  font-size: 20px;
  line-height: 1.8;
  font-size: 16px;
  line-height: 1.8;
  margin-left: 6.7vw;
  transition: all 1s;
}

@media (min-width: 991px) {
  .composant-1 .text-top {
    font-size: calc(0.53821vw + 14.66631px);
  }
}

@media (min-width: 1920px) {
  .composant-1 .text-top {
    font-size: 25px;
  }
}

@media (min-width: 320px) {
  .composant-1 .text-top {
    font-size: calc(0vw + 16px);
  }
}

@media (min-width: 990px) {
  .composant-1 .text-top {
    font-size: 16px;
  }
}

@media screen and (max-width: 992px) {
  .composant-1 .text-top {
    text-align: center;
    margin-left: 0;
  }
}

.composant-1 .text-top.masked {
  transform: translateY(50%);
  opacity: 0;
  transition: none;
}

.composant-1 .text-bottom {
  position: relative;
  font-size: 16px;
  line-height: 1.5;
  font-size: 14px;
  line-height: 1.5;
  margin-left: 20.3vw;
  width: 26.1vw;
  transition: all 1s;
}

@media (min-width: 991px) {
  .composant-1 .text-bottom {
    font-size: calc(0.21529vw + 13.86652px);
  }
}

@media (min-width: 1920px) {
  .composant-1 .text-bottom {
    font-size: 18px;
  }
}

@media (min-width: 320px) {
  .composant-1 .text-bottom {
    font-size: calc(0vw + 14px);
  }
}

@media (min-width: 990px) {
  .composant-1 .text-bottom {
    font-size: 14px;
  }
}

@media screen and (max-width: 992px) {
  .composant-1 .text-bottom {
    text-align: center;
    margin-left: 0;
    width: 100%;
  }
}

@media screen and (max-width: 1500px) {
  .composant-1 .text-bottom {
    width: 36vw;
  }
}

.composant-1 .text-bottom.masked {
  transform: translateY(50%);
  opacity: 0;
  transition: none;
}

.composant-1 .title {
  position: relative;
}

@media screen and (max-width: 992px) {
  .composant-1 .title {
    text-align: center;
  }
}

.composant-2 {
  width: 100%;
  padding: 27.7vh 16.9vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

@media screen and (max-width: 992px) {
  .composant-2 {
    padding: 16.2vh 30px;
  }
}

@media screen and (max-width: 992px) {
  .composant-2_txt {
    display: flex;
    flex-direction: column;
    gap: 5.9vh;
  }
}

.composant-2_txt-p {
  position: relative;
  font-size: 25px;
  line-height: 1.5;
  font-size: 25px;
  line-height: 1.5;
  font-weight: 600;
  color: #FFF852;
  white-space: nowrap;
  transition: all 1s;
}

@media (min-width: 991px) {
  .composant-2_txt-p {
    font-size: calc(2.15285vw + 3.66523px);
  }
}

@media (min-width: 1920px) {
  .composant-2_txt-p {
    font-size: 45px;
  }
}

@media (min-width: 320px) {
  .composant-2_txt-p {
    font-size: calc(0vw + 25px);
  }
}

@media (min-width: 990px) {
  .composant-2_txt-p {
    font-size: 25px;
  }
}

@media screen and (max-width: 992px) {
  .composant-2_txt-p {
    text-align: center;
    white-space: inherit;
  }
}

.composant-2_txt-p.masked {
  transform: translateY(50%);
  opacity: 0;
  transition: none;
}

.composant-3 {
  display: flex;
}

@media screen and (max-width: 992px) {
  .composant-3 {
    flex-direction: column;
  }
}

.composant-3_txt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 7.8vh;
  width: 50%;
  padding: 22.2vh 8vw;
  background-color: #FFF852;
}

@media screen and (max-width: 992px) {
  .composant-3_txt {
    gap: 4.6vh;
    width: 100%;
    padding: 13.1vh 30px;
  }
}

.composant-3_txt .text-top {
  position: relative;
  font-size: 25px;
  line-height: 1.3;
  font-size: 30px;
  line-height: 1.3;
  font-weight: 600;
  color: #000000;
  transition: all 1s;
}

@media (min-width: 991px) {
  .composant-3_txt .text-top {
    font-size: calc(2.15285vw + 3.66523px);
  }
}

@media (min-width: 1920px) {
  .composant-3_txt .text-top {
    font-size: 45px;
  }
}

@media (min-width: 320px) {
  .composant-3_txt .text-top {
    font-size: calc(0vw + 30px);
  }
}

@media (min-width: 990px) {
  .composant-3_txt .text-top {
    font-size: 30px;
  }
}

.composant-3_txt .text-top.masked {
  transform: translateY(50%);
  opacity: 0;
  transition: none;
}

.composant-3_txt .text-bottom {
  position: relative;
  width: calc(100% - 3vw);
  color: #000000;
  line-height: 2;
  transition: all 1s;
}

@media screen and (max-width: 992px) {
  .composant-3_txt .text-bottom {
    width: 100%;
  }
}

.composant-3_txt .text-bottom span {
  font-weight: 600;
}

.composant-3_txt .text-bottom.masked {
  transform: translateY(50%);
  opacity: 0;
  transition: none;
}

.composant-3_img {
  width: 50%;
  overflow: hidden;
}

@media screen and (max-width: 992px) {
  .composant-3_img {
    width: 100%;
  }
}

.composant-3_img img {
  position: relative;
  width: 100%;
  height: 90.3vh;
  min-height: 100%;
  object-fit: cover;
  object-position: center;
  transition: all 1s;
}

@media screen and (max-width: 992px) {
  .composant-3_img img {
    height: 75.2vh;
  }
}

.composant-3_img img.masked {
  transform: scale(1.2);
  transition: none;
}

.c-references {
  position: relative;
  overflow: hidden;
  padding-top: 7.8125vw;
  padding-bottom: 7.8125vw;
}

@media screen and (max-width: 992px) {
  .c-references {
    padding-top: 45px;
    padding-bottom: 45px;
  }
}

.c-references_title {
  margin-bottom: 1.30208vw;
}

@media screen and (max-width: 992px) {
  .c-references_title {
    margin-bottom: 35px;
  }
}

.c-references_slider {
  position: relative;
  width: 100%;
  margin-top: 5.46875vw;
  display: flex;
  align-items: center;
  padding: 0 6.77083vw;
}

@media screen and (max-width: 992px) {
  .c-references_slider {
    padding: 0;
    width: 100vw;
    left: -30px;
    margin-top: 45px;
  }
}

.c-references_slider_arrows {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%) rotate(90deg);
  width: 3.64583vw;
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .c-references_slider_arrows {
    display: none;
  }
}

.c-references_slider_arrows.slider-next {
  left: auto;
  right: 0;
  transform: translateY(-50%) rotate(-90deg);
}

.c-references_slider_arrows.swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}

.c-references_slider_arrows img {
  width: 100%;
  height: auto;
  transform: scale(1);
  transition: all .3s ease;
}

.c-references_slider_arrows:hover img {
  transform: scale(1.1);
  transition: all .3s ease;
}

.c-references_slider .swiper-references {
  width: 100%;
  overflow: hidden;
}

@media screen and (max-width: 992px) {
  .c-references_slider .swiper-references {
    padding-left: 30px;
    padding-right: 30px;
    overflow: visible;
  }
}

.c-references_slider .swiper-references .swiper-slide {
  height: auto;
}

.c-references_slider .swiper-references .swiper-slide .item {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 2rem;
  border-radius: 5px;
  background-color: #1A1A1A;
}

@media screen and (max-width: 992px) {
  .c-references_slider .swiper-references .swiper-slide .item {
    padding: 15px;
  }
}

.c-references_slider .swiper-references .swiper-slide .item .img {
  width: 100%;
  aspect-ratio: 1 / 1;
  margin-bottom: 2.5rem;
  z-index: 1;
}

@media screen and (min-width: 992px) {
  .c-references_slider .swiper-references .swiper-slide .item .img {
    position: relative;
  }
}

@media screen and (max-width: 992px) {
  .c-references_slider .swiper-references .swiper-slide .item .img {
    margin-bottom: 10px;
  }
}

.c-references_slider .swiper-references .swiper-slide .item .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.c-references_slider .swiper-references .swiper-slide .item .play {
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 2;
  display: block;
  width: 2.5vw;
  opacity: 0;
  transition: all .3s ease;
}

@media screen and (max-width: 992px) {
  .c-references_slider .swiper-references .swiper-slide .item .play {
    opacity: 1;
    width: 35px;
    bottom: calc(-35px / 2);
  }
}

.c-references_slider .swiper-references .swiper-slide .item .play img {
  width: 100%;
  height: auto;
}

.c-references_slider .swiper-references .swiper-slide .item .title,
.c-references_slider .swiper-references .swiper-slide .item .subtitle {
  margin: 0;
  font-family: "transducer-extended";
  font-weight: 600;
  font-size: 11px;
  line-height: 1.6;
}

@media (min-width: 991px) {
  .c-references_slider .swiper-references .swiper-slide .item .title,
  .c-references_slider .swiper-references .swiper-slide .item .subtitle {
    font-size: calc(0.21529vw + 8.86652px);
  }
}

@media (min-width: 1920px) {
  .c-references_slider .swiper-references .swiper-slide .item .title,
  .c-references_slider .swiper-references .swiper-slide .item .subtitle {
    font-size: 13px;
  }
}

@media screen and (max-width: 992px) {
  .c-references_slider .swiper-references .swiper-slide .item .title,
  .c-references_slider .swiper-references .swiper-slide .item .subtitle {
    font-size: 11px;
  }
}

.c-references_slider .swiper-references .swiper-slide .item .subtitle {
  color: #FFF852;
  margin-bottom: 5px;
}

@media screen and (min-width: 992px) {
  .c-references_slider .swiper-references .swiper-slide .item:hover:not(.modal-empty) .play {
    opacity: 1;
    transition: all .3s ease;
  }
}

.c-references_slider .swiper-references .swiper-slide .item.no-link {
  pointer-events: none;
}

.c-references_slider .swiper-references .swiper-slide .item.no-link .play {
  opacity: 0;
}

.modal-reference {
  display: block;
  bottom: calc(100% + 20px);
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.modal-reference_content {
  background-color: #000000;
  border: 1px solid #FFF852;
  padding: 2.60417vw 5.20833vw;
  display: flex;
  border-radius: 2rem;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.modal-reference_content .h2 {
  width: 13.02083vw;
  padding-right: 1.82292vw;
  border-right: 1px solid #FFF852;
}

.modal-reference_content p {
  width: 26.5625vw;
  margin-left: 1.82292vw;
}

.modal-reference.open {
  opacity: 1;
  z-index: 10000;
  transition: all .3s ease;
}

.simple-reference {
  position: relative;
  overflow: hidden;
  padding-top: 7.8125vw;
  padding-bottom: 4.6875vw;
}

@media screen and (max-width: 992px) {
  .simple-reference {
    padding-top: 45px;
    padding-bottom: 50px;
  }
}

.simple-reference_title {
  margin-bottom: 3rem;
}

@media screen and (max-width: 992px) {
  .simple-reference_title {
    margin-bottom: 25px;
  }
}

.simple-reference_subtitle {
  margin-bottom: 2.60417vw;
}

@media screen and (max-width: 992px) {
  .simple-reference_subtitle {
    margin-bottom: 50px;
  }
}

.simple-reference_logos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-radius: 5px;
  background-color: #1A1A1A;
  padding: 2.60417vw;
}

@media screen and (max-width: 992px) {
  .simple-reference_logos {
    flex-direction: column;
    padding: 50px 0;
    width: 215px;
    margin: 0 auto;
  }
}

.simple-reference_logos img {
  max-width: 18rem;
  max-height: 6rem;
  width: auto;
  height: auto;
}

@media screen and (max-width: 992px) {
  .simple-reference_logos img {
    margin-bottom: 80px;
    max-width: 145px;
    max-height: 60px;
  }
  .simple-reference_logos img:last-child {
    margin-bottom: 0;
  }
}

.simple-reference_logos.v-radio img {
  max-width: 100%;
  max-height: 8.6rem;
  width: auto;
  height: auto;
}

@media screen and (max-width: 992px) {
  .simple-reference_logos.v-radio img {
    margin-bottom: 80px;
    max-height: 80px;
  }
  .simple-reference_logos.v-radio img:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 992px) {
  .simple-reference_arrows {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
  }
}

.simple-reference_arrows img {
  position: absolute;
  top: 50%;
  left: 0;
  width: 3.64583vw;
  height: auto;
  transform: translateY(-50%) rotate(90deg);
  opacity: 1;
  cursor: pointer;
  transition: all .3s ease;
}

@media screen and (max-width: 992px) {
  .simple-reference_arrows img {
    position: relative;
    top: 0;
    transform: rotate(90deg);
    width: 70px;
  }
}

.simple-reference_arrows img.slider-next {
  left: auto;
  right: 0;
  transform: translateY(-50%) rotate(-90deg);
}

@media screen and (max-width: 992px) {
  .simple-reference_arrows img.slider-next {
    transform: rotate(-90deg);
    margin-left: 15px;
  }
}

.simple-reference_arrows img:hover {
  opacity: .6;
  transition: all .3s ease;
}

.simple-reference .swiper-references {
  width: 100%;
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
}

.simple-reference .swiper-references .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 992px) {
  .simple-reference .grid-container.width-slider {
    padding: 0;
  }
}

.c-formats {
  position: relative;
  overflow: hidden;
  padding-top: 8.85417vw;
  padding-bottom: 4.6875vw;
}

@media screen and (max-width: 992px) {
  .c-formats {
    padding-top: 70px;
    padding-bottom: 45px;
  }
}

.c-formats_bigTitle {
  margin-bottom: 4.6875vw;
}

@media screen and (max-width: 992px) {
  .c-formats_bigTitle {
    margin-bottom: 40px;
    text-align: center;
  }
}

.c-formats .forme {
  position: absolute;
  top: -2.60417vw;
  right: -5.72917vw;
  width: 51.04167vw;
  z-index: 0;
}

@media screen and (max-width: 992px) {
  .c-formats .forme {
    width: 420px;
    top: -120px;
    right: -90px;
  }
}

.c-formats .forme img {
  width: 100%;
  height: auto;
}

.c-formats_content {
  margin: 0 auto;
  position: relative;
  z-index: 5;
  padding: 0 30px;
}

@media screen and (min-width: 992px) {
  .c-formats_content {
    width: 79.6875vw;
    column-gap: 30px;
    row-gap: 2.34375vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-left: 0;
    padding-right: 0;
  }
}

.c-formats_content .item {
  position: relative;
  border-radius: 2rem;
  border: 1px solid #FFF852;
  padding: 2.86458vw 3.90625vw;
}

@media screen and (max-width: 992px) {
  .c-formats_content .item {
    padding: 35px 25px;
    margin-bottom: 30px;
    border-radius: 20px;
  }
  .c-formats_content .item:last-child {
    margin-bottom: 0;
  }
}

.c-formats_content .item_title {
  margin-bottom: 2.08333vw;
}

@media screen and (max-width: 992px) {
  .c-formats_content .item_title {
    margin-bottom: 25px;
  }
}

.c-blocQuestion {
  position: relative;
  overflow: hidden;
  padding-top: 5.20833vw;
  padding-bottom: 5.20833vw;
}

@media screen and (max-width: 992px) {
  .c-blocQuestion {
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #000000;
  }
}

.c-blocQuestion_content {
  position: relative;
  width: 100%;
  display: flex;
  border-radius: 2.5rem;
  overflow: hidden;
}

@media screen and (max-width: 992px) {
  .c-blocQuestion_content {
    display: block;
    border-radius: 20px;
  }
}

.c-blocQuestion_content_img {
  width: 33.07292vw;
  position: relative;
}

@media screen and (max-width: 992px) {
  .c-blocQuestion_content_img {
    width: 100%;
    height: 68.23529411764706vw;
  }
}

.c-blocQuestion_content_img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.c-blocQuestion_content_textes {
  width: calc(100% - 33.072916666666664vw);
  padding: 3.64583vw 3.125vw;
  min-height: 29rem;
}

@media screen and (max-width: 992px) {
  .c-blocQuestion_content_textes {
    width: 100%;
    min-height: 0;
    padding: 40px 25px;
  }
}

.c-blocQuestion_content_textes .title {
  margin-bottom: 3rem;
}

@media screen and (max-width: 992px) {
  .c-blocQuestion_content_textes .title {
    margin-bottom: 30px;
  }
}

/*************************************************
// INCLUDE DES FONTS
*************************************************/
@font-face {
  font-family: "transducer-extended";
  src: url("https://use.typekit.net/af/cadf16/00000000000000007735eccb/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"), url("https://use.typekit.net/af/cadf16/00000000000000007735eccb/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"), url("https://use.typekit.net/af/cadf16/00000000000000007735eccb/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "transducer-extended";
  src: url("https://use.typekit.net/af/f5b1f5/00000000000000007735ecce/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/f5b1f5/00000000000000007735ecce/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/f5b1f5/00000000000000007735ecce/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "transducer-extended";
  src: url("https://use.typekit.net/af/d156da/00000000000000007735ecdb/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff2"), url("https://use.typekit.net/af/d156da/00000000000000007735ecdb/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff"), url("https://use.typekit.net/af/d156da/00000000000000007735ecdb/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: "transducer-extended";
  src: url("https://use.typekit.net/af/3a72c7/00000000000000007735ecd7/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/3a72c7/00000000000000007735ecd7/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/3a72c7/00000000000000007735ecd7/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: "transducer-variable";
  src: url("https://use.typekit.net/af/e88dea/0000000000000000774b97a8/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/e88dea/0000000000000000774b97a8/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/e88dea/0000000000000000774b97a8/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

.tk-transducer-variable {
  font-family: "transducer-variable", sans-serif;
}

/*************************************************
// STYLE DES FONTS
*************************************************/
.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.bold {
  font-weight: 600;
}

.black {
  font-weight: 900;
}

span.tarteaucitronH3, #tarteaucitron #tarteaucitronServices_mandatory .tarteaucitronH3 {
  font-family: "transducer-extended";
  font-weight: normal;
  font-style: normal;
}

.mag_content_title, .mag_content_title_sub, .c-actu__title, .c-actu_similar__title, .c-articles--no-articles, .c-article__title {
  font-family: "transducer-extended";
  font-weight: 600;
  font-style: normal;
}

/*************************************************
// ICOMOON
*************************************************/
@keyframes upDown {
  from {
    transform: translateY(0%);
  }
  25% {
    transform: translateY(-20%);
  }
  75% {
    transform: translateY(23%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes height-100-0 {
  from {
    height: 100%;
  }
  to {
    height: 0;
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes trans-opacity-top-100 {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes trans-opacity-bot-100 {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes trans-opacity-left-100 {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes trans-opacity-right-100 {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes rebond-start {
  from {
    opacity: 0;
    transform: scale(1);
  }
  33% {
    opacity: 1;
    transform: scale(1.15);
  }
  66% {
    opacity: 1;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.c-transition {
  position: fixed;
  z-index: 10000;
  top: 0;
  right: 0;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  transition: all 0.5s ease;
}

.c-transition:after {
  display: block;
  content: "";
  height: 100%;
  width: 100%;
  background-color: #000000;
  position: absolute;
  opacity: 0;
  top: 0;
  right: 100%;
  z-index: 1;
}

.c-transition:before {
  display: block;
  content: "";
  height: 100%;
  width: 100%;
  background-color: #000000;
  position: absolute;
  opacity: 0;
  top: 0;
  right: 100%;
  z-index: 0;
}

.c-transition_wrapper {
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
}

.c-transition_wrapper_sigle {
  position: relative;
  width: 5vw;
  opacity: 0;
  z-index: 4;
  top: 0;
}

@media screen and (max-width: 992px) {
  .c-transition_wrapper_sigle {
    width: 60px;
    max-width: 80%;
  }
}

.c-transition_wrapper_sigle img {
  width: 100%;
  height: auto;
}

.c-transition.under {
  z-index: -999;
  opacity: 0;
}

.c-transition.first:after {
  background-color: #000000;
  top: 0;
  right: 0;
  opacity: 1;
}

.c-transition.first:before {
  top: 0;
  opacity: 1;
  right: 0;
  background-color: #000000;
}

.c-transition.first.anim .c-transition_wrapper_sigle {
  animation: rebond-start 1s linear forwards 0.2s;
}

.c-transition.first.remove {
  top: -100%;
  opacity: 0;
  transition: top 0.5s ease 0.9s, opacity 0.1s linear 1s;
}

.c-transition.first.remove:after {
  top: -100%;
  transition: top 0.8s ease;
}

.c-transition.first.remove:before {
  top: -100%;
  transition: top 0.8s ease;
}

.c-transition.first.remove .c-transition_wrapper_sigle {
  top: -100%;
  transition: top 0.7s ease 0.15s;
}

.parallax {
  transition: all 0.5s linear;
}

.parallax.speed2 {
  transition: all 0.6s linear;
}

.anim-opacity {
  position: relative;
  transition: all 1s;
}

.anim-opacity.masked {
  transition: none;
  opacity: 0;
}

.anim-bottom {
  position: relative;
  transition: all 1s;
}

.anim-bottom.masked {
  transition: none;
  transform: translateY(50%);
  opacity: 0;
}

.anim-right {
  position: relative;
  transition: all 1s;
}

.anim-right.masked {
  transition: none;
  transform: translateX(50%);
  opacity: 0;
}

.anim-left {
  position: relative;
  transition: all 1s;
}

.anim-left.masked {
  transition: none;
  transform: translateX(-50%);
  opacity: 0;
}

.anim-delay--1 {
  transition-delay: 0.2s;
  z-index: 1;
}

.anim-delay--2 {
  transition-delay: 0.4s;
  z-index: 2;
}

.anim-delay--3 {
  transition-delay: 0.6s;
  z-index: 3;
}

.anim-delay--4 {
  transition-delay: 0.8s;
  z-index: 4;
}

.anim-delay--5 {
  transition-delay: 1s;
  z-index: 5;
}

.loader {
  z-index: 999;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  transition: all .5s ease;
}

.loader.hide {
  opacity: 0;
  z-index: -999;
  pointer-events: none;
}

.loader .barres {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: all 1s ease 1s;
}

.loader .barres.hide {
  opacity: 0;
  gap: 5px;
}

.loader .barres .barre {
  /* will-change: transform; */
  background-color: #FFF852;
  width: 4px;
  height: 10px;
  border-radius: 100vmax;
  animation: oscillation 0.8s linear alternate infinite;
  transition: all 1s ease;
}

.loader .barres .barre.active {
  height: 2px !important;
}

.loader .barres .barre:nth-child(1) {
  transition-delay: 1.2s;
  animation-delay: 1.2s;
}

.loader .barres .barre:nth-child(2) {
  transition-delay: 0.9s;
  animation-delay: 0.9s;
}

.loader .barres .barre:nth-child(3) {
  transition-delay: 0.6s;
  animation-delay: 0.6s;
}

.loader .barres .barre:nth-child(4) {
  transition-delay: 0.3s;
  animation-delay: 0.3s;
}

.loader .barres .barre:nth-child(5) {
  transition-delay: 0.6s;
  animation-delay: 0.6s;
}

.loader .barres .barre:nth-child(6) {
  transition-delay: 0.9s;
  animation-delay: 0.9s;
}

.loader .barres .barre:nth-child(7) {
  transition-delay: 1.2s;
  animation-delay: 1.2s;
}

.loader .loading-bar {
  will-change: transform;
  background-color: #FFF852;
  width: 100%;
  transform: scaleX(0);
  transform-origin: top left;
  height: 5px;
  z-index: 1;
  border-radius: 100vmax;
  transition: transform 0s, opacity 1s ease;
}

.loader .loading-bar_container {
  overflow: hidden;
  z-index: 0;
  background-color: rgba(255, 255, 0, 0.5);
  width: 30%;
  border-radius: 100vmax;
  height: 5px;
  position: absolute;
  top: 50%;
  transform: translateY(100px);
  transition: all 0.3s ease;
}

.loader .loading-bar_container.hide {
  opacity: 0;
}

.loader .button-enter {
  color: #FFF852;
  border: 2px solid #FFF852;
  border-radius: 20px;
  padding: 10px 20px;
  position: absolute;
  top: 50%;
  transform: translateY(calc(100px - 50%));
  transition: all 0.3s ease 0.2s;
  cursor: pointer;
}

.loader .button-enter.hide {
  opacity: 0;
  pointer-events: none;
}

@keyframes oscillation {
  from {
    height: 2px;
  }
  to {
    height: 40px;
  }
}

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon/icomoon.eot?2iteac");
  src: url("../fonts/icomoon/icomoon.eot?2iteac#iefix") format("embedded-opentype"), url("../fonts/icomoon/icomoon.ttf?2iteac") format("truetype"), url("../fonts/icomoon/icomoon.woff?2iteac") format("woff"), url("../fonts/icomoon/icomoon.svg?2iteac#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-youtube:before {
  content: "\e900";
}

.icon-twitter:before {
  content: "\ea96";
}

.icon-pdf:before {
  content: "\eadf";
}

.icon-arrow:before {
  content: "\e902";
}

.icon-download:before {
  content: "\e903";
}

.icon-facebook:before {
  content: "\e904";
}

.icon-instagram:before {
  content: "\e905";
}

.icon-itineraire:before {
  content: "\e906";
}

.icon-linkedin:before {
  content: "\e907";
}

.icon-piece-jointe:before {
  content: "\e908";
}

.icon-refresh:before {
  content: "\e909";
}

.icon-tiktok:before {
  content: "\e90a";
}

.icon-triangle:before {
  content: "\e90b";
}

.icon-arrow-plain:before {
  content: "\e901";
}

.mag #section-top {
  overflow: hidden;
  position: relative;
}

.mag .imgFloat {
  position: absolute;
  top: 10vw;
  right: -8vw;
  width: 50.98958vw;
}

.mag .imgFloat img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 992px) {
  .mag .imgFloat {
    display: none;
  }
}

.mag_content {
  background-color: #000000;
  position: relative;
  padding-top: 15.625vw;
  padding-bottom: 10rem;
}

@media screen and (max-width: 992px) {
  .mag_content {
    padding-top: 200px;
    padding-bottom: 50px;
  }
}

.mag_content_title {
  margin-bottom: 5rem;
  font-size: 4.5rem;
  font-family: "transducer-extended";
  color: #000000;
}

.mag_content_title_sub {
  display: block;
  margin-top: 10px;
  font-size: 50%;
  color: #7a8285;
}

.c-form-sort {
  width: 100%;
  position: fixed;
  top: 9.4rem;
  padding-top: 3rem;
  padding-bottom: 2rem;
  left: 0;
  z-index: 102;
  transition: all .3s ease;
}

@media screen and (max-width: 992px) {
  .c-form-sort {
    top: 98px;
  }
}

@media screen and (max-width: 992px) {
  .c-form-sort .simple-container {
    padding: 0;
  }
}

@media screen and (max-width: 992px) {
  .c-form-sort_top {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.c-form-sort_selectors {
  overflow-x: auto;
  overflow-y: visible;
  width: auto;
  height: auto;
}

@media screen and (max-width: 992px) {
  .c-form-sort_selectors {
    justify-content: space-between;
    width: 100%;
  }
}

.c-form-sort_select {
  width: 100%;
  position: relative;
  margin-top: 5rem;
}

@media (min-width: 760px) {
  .c-form-sort_select {
    width: auto;
    margin-top: 0;
  }
}

.c-form-sort_select_title {
  position: relative;
  text-align: right;
  margin-bottom: 10px;
  font-size: 12px;
}

@media (min-width: 760px) {
  .c-form-sort_select_title {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    text-align: left;
    z-index: 1;
    transform: translateY(-120%);
  }
}

.c-form-sort__search {
  width: 100%;
  border: solid 1px #ffffff;
  color: #ffffff;
  height: 35px;
  margin-bottom: 5px;
  border-radius: 2.5rem;
  margin-top: 1rem;
  padding: 0 15px;
  font-size: 14px;
}

@media (min-width: 760px) {
  .c-form-sort__search {
    width: 250px;
    margin-top: 10px;
  }
}

.c-form-sort__search::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.c-form-sort__search::-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}

.c-form-sort__search::-ms-placeholder {
  color: #ffffff;
  opacity: 1;
}

.c-form-sort__search::-ms-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.c-form-sort__search:-ms-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.c-form-sort__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: "transducer-extended";
  font-weight: 600;
  padding: 0 2.5rem;
  height: 5.5rem;
  color: #ffffff;
  background-color: #000000;
  font-size: 1.8rem;
  border-radius: 2.8rem;
  border: 1px solid #FFF852;
}

@media screen and (max-width: 992px) {
  .c-form-sort__button {
    font-size: 14px;
    height: 50px;
    width: calc((100% - 50px - 3rem) - 30%);
    padding: 0 5px;
    border-radius: 25px;
  }
}

.c-form-sort__button:not(:first-child) {
  margin-left: 3rem;
}

.c-form-sort__button.button-home {
  display: none;
}

@media screen and (max-width: 992px) {
  .c-form-sort__button:nth-child(2) {
    width: calc((100% - 50px - 6rem) - 50%);
  }
}

@media screen and (max-width: 992px) {
  .c-form-sort__button:last-child:not(.c-form-sort__button--small) {
    width: 50px;
    height: 50px;
  }
}

.c-form-sort__button .text {
  display: inline-block;
}

@media screen and (max-width: 992px) {
  .c-form-sort__button .text {
    margin-right: 5px;
    max-width: 115px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@media screen and (max-width: 992px) {
  .c-form-sort__button .reset {
    display: none;
  }
}

.c-form-sort__button .icons {
  margin-left: 10px;
}

.c-form-sort__button .icons.invers {
  margin-left: 0;
  margin-right: 10px;
}

@media screen and (max-width: 992px) {
  .c-form-sort__button .icons.invers {
    margin-right: 0;
  }
}

.c-form-sort__button .icon-arrow {
  display: inline-flex;
  font-size: 1.8rem;
  align-items: center;
  justify-content: center;
  width: 1.8rem;
  height: 1.8rem;
  color: #ffffff;
  transform: rotate(0);
  transition: all .3s ease-out;
}

@media screen and (max-width: 1200px) {
  .c-form-sort__button .icon-arrow {
    display: inline-flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
  }
}

.c-form-sort__button .icon-refresh {
  display: inline-flex;
  font-size: 2.6rem;
  align-items: center;
  justify-content: center;
  width: 2.6rem;
  height: 2.6rem;
  transform: rotate(-45deg) scaleX(-1);
  transition: all .3s ease-out;
  margin: 0;
}

@media screen and (max-width: 992px) {
  .c-form-sort__button .icon-refresh {
    display: inline-flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
  }
}

.c-form-sort__button:after, .c-form-sort__button:before {
  content: normal;
  display: none;
}

.c-form-sort__button--reset {
  border-radius: 100%;
  padding: 0;
  width: 5.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-form-sort__button--small {
  position: relative;
  min-width: 0;
  font-family: "transducer-extended";
  font-weight: 400;
  text-transform: none;
  font-size: 1.4rem;
  border: 1px solid #000000;
  background: #000000;
  color: #ffffff;
  height: auto;
  border-radius: 0;
  padding: 0;
  margin: 1rem;
}

@media screen and (max-width: 992px) {
  .c-form-sort__button--small {
    font-size: 16px;
    height: 30px;
    margin: 0;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 15px;
    width: auto !important;
  }
}

.c-form-sort__button--small:last-child {
  margin-right: 0;
}

.c-form-sort__button--small:not(:first-child) {
  margin-left: 0;
}

.c-form-sort__button--small:hover {
  background: transparent !important;
  color: #FFF852 !important;
}

.c-form-sort__button--small.active {
  background: transparent !important;
  color: #FFF852 !important;
}

.c-form-sort__button:hover, .c-form-sort__button.active {
  background: #FFF852;
  color: #000000;
}

.c-form-sort__button:hover .icon-arrow, .c-form-sort__button.active .icon-arrow {
  color: #000000;
}

.c-form-sort__button.active {
  background: #FFF852;
}

.c-form-sort__button.active .icon-arrow {
  color: #000000;
  transform: rotate(90deg);
}

.c-form-sort_list {
  visibility: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 50vh;
  opacity: 0;
  transition: all .3s ease-out;
  padding: 1.5rem 0;
  position: absolute;
  top: 100%;
  bottom: auto;
  left: 0;
  right: 0;
  z-index: 1;
}

@media screen and (max-width: 992px) {
  .c-form-sort_list {
    padding: 15px;
  }
}

.c-form-sort_list .wrap {
  display: inline-block;
  border: 1px solid #FFF852;
  border-radius: 2.8rem;
  background-color: #000000;
  padding: 0 2rem;
}

@media screen and (max-width: 992px) {
  .c-form-sort_list .wrap {
    padding: 0 10px;
    border-radius: 25px;
  }
}

.c-form-sort_list.active {
  opacity: 1;
  visibility: visible;
}

.c-form-sort_list_items {
  width: auto;
}

.c-form-sort_list_notags {
  font-size: 12px;
  margin: 10px 0;
  color: #ffffff;
}

.c-form-sort.masked {
  top: 9.4rem;
  transition: all .3s ease;
}

@media screen and (max-width: 992px) {
  .c-form-sort.masked {
    top: 98px;
  }
}

.c-actu {
  position: relative;
  padding-bottom: 5rem !important;
}

@media screen and (max-width: 992px) {
  .c-actu {
    padding-bottom: 50px !important;
  }
}

.c-actu__cover {
  position: relative;
  overflow: hidden;
  margin-top: 4vw;
  margin-bottom: 6rem;
}

@media screen and (max-width: 992px) {
  .c-actu__cover {
    margin-bottom: 50px;
    margin-top: 0;
  }
}

.c-actu__cover_text {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 4rem;
}

@media screen and (max-width: 992px) {
  .c-actu__cover_text {
    display: block;
    margin-bottom: 40px;
  }
}

.c-actu__cover_img {
  width: 100%;
  margin-top: 5vw;
  border: 1px solid #000000;
}

@media screen and (max-width: 992px) {
  .c-actu__cover_img {
    margin-top: 40px;
  }
}

.c-actu__cover_img img {
  width: 100%;
  height: auto;
}

.c-actu__title {
  margin: 0;
  margin-bottom: 0;
  color: #FFF852;
  font-size: 4.5rem;
}

@media screen and (max-width: 992px) {
  .c-actu__title {
    width: 100%;
    font-size: 36px;
    margin-bottom: 40px;
  }
}

.c-actu__date {
  display: block;
  font-size: 16px;
  font-family: "transducer-extended";
  font-weight: 500;
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .c-actu__date {
    font-size: 2rem;
    margin-top: 0rem;
    margin-bottom: 4rem;
  }
}

.c-actu__tags {
  margin-top: 15px;
}

.c-actu__tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  padding: 0 3rem;
  font-family: "transducer-extended";
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.6rem;
  border: 1px solid #ffffff;
  color: #ffffff;
  margin-right: 1rem;
  border-radius: 2rem;
  margin-bottom: 1rem;
  transition: all .3s ease;
}

@media screen and (max-width: 992px) {
  .c-actu__tag {
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    height: 40px;
    border-radius: 20px;
    padding: 0 20px;
  }
}

.c-actu__category {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  padding: 0 3rem;
  font-family: "transducer-extended";
  font-weight: 400;
  text-transform: uppercase;
  font-size: 1.6rem;
  border: 1px solid #ffffff;
  color: #ffffff;
  margin-right: 1rem;
  margin-bottom: 1rem;
  transition: all .3s ease;
}

@media screen and (max-width: 992px) {
  .c-actu__category {
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    height: 40px;
    padding: 0 10px;
  }
}

.c-actu__preview {
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 50px;
}

.c-actu__preview br {
  display: none !important;
}

@media (min-width: 992px) {
  .c-actu__preview {
    font-size: 1.8rem;
    margin-bottom: 9rem;
  }
}

.c-actu__subtitle {
  font-size: 30px;
  color: #FFF852;
  margin: 0;
  font-family: "transducer-extended";
  font-weight: 600;
  margin-top: 50px;
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .c-actu__subtitle {
    font-size: 4.5rem;
    margin-bottom: 5rem;
    margin-top: 5rem;
  }
}

.c-actu__text {
  font-size: 16px;
  margin-bottom: 50px;
  line-height: 1.6;
  color: #ffffff;
}

@media (min-width: 992px) {
  .c-actu__text {
    font-size: 1.6rem;
    margin-bottom: 6rem;
  }
}

.c-actu__text strong {
  font-weight: 600;
}

.c-actu__text ul li {
  padding-left: 30px;
  list-style: inside;
}

.c-actu__text br {
  display: block !important;
}

.c-actu__text a {
  text-decoration: underline;
  color: #FFF852;
  font-weight: 600;
}

.c-actu__text p + p {
  margin-top: 1.5rem;
}

@media screen and (max-width: 992px) {
  .c-actu__text p + p {
    margin-top: 15px;
  }
}

.c-actu__image {
  margin-bottom: 50px;
}

@media screen and (min-width: 992px) {
  .c-actu__image {
    margin-bottom: 6rem;
    display: flex;
    justify-content: center;
  }
}

.c-actu__image img {
  width: 100%;
  height: auto;
}

@media screen and (min-width: 992px) {
  .c-actu__image img {
    max-width: 100%;
    width: auto;
    max-height: 800px;
  }
}

.c-actu__video {
  margin-bottom: 50px;
  padding-bottom: 58%;
  position: relative;
}

@media screen and (min-width: 992px) {
  .c-actu__video {
    margin-bottom: 6rem;
  }
}

.c-actu__video iframe {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.c-actu__button {
  text-align: center;
  margin-bottom: 50px;
}

@media screen and (min-width: 992px) {
  .c-actu__button {
    margin-bottom: 6rem;
  }
}

.c-actu__cta {
  background-color: #000000;
  margin-bottom: 6rem;
  border: 1px solid #FFF852;
  border-radius: 2rem;
  padding: 5rem;
  display: flex;
}

@media screen and (max-width: 992px) {
  .c-actu__cta {
    margin-bottom: 50px;
    display: block;
    border-radius: 20px;
  }
}

.c-actu__cta_img {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
}

@media (min-width: 760px) {
  .c-actu__cta_img {
    width: 25.520833333333332vw;
    overflow: hidden;
    position: relative;
    padding: 0;
    margin-bottom: 0;
  }
}

.c-actu__cta_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.c-actu__cta_text {
  display: block;
  width: 100%;
  font-family: "transducer-extended";
  font-weight: 400;
  line-height: 1.4;
  color: #ffffff;
  padding: 25px;
  font-size: 16px;
}

@media (min-width: 760px) {
  .c-actu__cta_text {
    width: calc(100% - 25.520833333333332vw);
    padding: 0 4rem;
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.c-actu__cta__title {
  font-size: 3rem;
  display: inline-block;
  margin-bottom: 4rem;
  color: #FFF852;
  font-weight: 600;
}

@media screen and (max-width: 992px) {
  .c-actu__cta__title {
    font-size: 20px;
  }
}

.c-actu__cta__content {
  display: block;
  margin-bottom: 4rem;
}

@media screen and (max-width: 992px) {
  .c-actu__cta__content {
    margin-bottom: 30px;
  }
}

.c-actu__cta__button {
  text-align: right;
}

@media screen and (max-width: 992px) {
  .c-actu__cta__button {
    text-align: center;
  }
}

.c-actu__pdf {
  margin-bottom: 6rem;
  padding-top: 3rem;
  text-align: center;
}

@media screen and (max-width: 992px) {
  .c-actu__pdf {
    margin-bottom: 50px;
  }
}

.c-actu__pdf_title {
  display: inline-block;
  color: #ffffff;
  font-weight: 900;
  font-size: 2.5rem;
  margin-bottom: 3rem;
}

@media screen and (max-width: 992px) {
  .c-actu__pdf_title {
    font-size: 18px;
  }
}

.c-actu__pdf_button .icon-download {
  display: inline-flex;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.c-actu__html-element {
  margin-bottom: 6rem;
}

@media screen and (max-width: 992px) {
  .c-actu__html-element {
    margin-bottom: 50px;
  }
}

.c-actu__gallery {
  height: auto;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: 6rem;
}

@media screen and (max-width: 992px) {
  .c-actu__gallery {
    overflow: visible;
  }
}

.c-actu__gallery .slider-prev,
.c-actu__gallery .slider-next {
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  background: #000000;
  border: 1px solid #FFF852;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 3rem;
  right: auto;
  z-index: 5;
  left: 4rem;
  margin: auto;
  cursor: pointer;
  font-size: 0;
  transform: rotate(90deg);
  transition: all .3s ease;
}

@media screen and (max-width: 992px) {
  .c-actu__gallery .slider-prev,
  .c-actu__gallery .slider-next {
    width: 40px;
    height: 40px;
    left: -20px;
  }
}

.c-actu__gallery .slider-prev:before,
.c-actu__gallery .slider-next:before {
  display: none;
}

.c-actu__gallery .slider-prev:after,
.c-actu__gallery .slider-next:after {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e902";
  display: inline-flex;
  font-size: 1.6rem;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  height: 1.6rem;
  color: #ffffff;
  display: block;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(90deg);
}

.c-actu__gallery .slider-prev:hover,
.c-actu__gallery .slider-next:hover {
  background-color: #FFF852;
  transition: all .3s ease;
}

.c-actu__gallery .slider-prev:hover::after,
.c-actu__gallery .slider-next:hover::after {
  color: #000000;
}

.c-actu__gallery .slider-next {
  right: 4rem;
  left: auto;
  transform: none;
  transform: rotate(-90deg);
}

@media screen and (max-width: 992px) {
  .c-actu__gallery .slider-next {
    right: -20px;
    left: auto;
  }
}

.c-actu__gallery .swiper-slide .wrapper {
  width: 100%;
  padding-bottom: 51%;
  height: 0;
  position: relative;
  overflow: hidden;
}

.c-actu__gallery .swiper-slide .wrapper img {
  position: absolute;
  height: 100%;
  width: auto;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
}

.c-actu__social {
  margin-top: 8rem;
}

@media screen and (max-width: 992px) {
  .c-actu__social {
    width: 100%;
    margin-top: 80px;
  }
}

.c-actu__social p {
  font-weight: 600;
}

.c-actu__social_links {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

.c-actu__social_links .bt-share {
  width: 4.5rem;
  height: 4.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid #000000;
  color: #ffffff;
  border-radius: 100%;
  position: relative;
}

@media screen and (max-width: 992px) {
  .c-actu__social_links .bt-share {
    width: 40px;
    height: 40px;
  }
}

.c-actu__social_links .bt-share [class^="icon-"] {
  display: inline-flex;
  font-size: 2.3rem;
  align-items: center;
  justify-content: center;
  width: 2.3rem;
  height: 2.3rem;
}

@media screen and (max-width: 992px) {
  .c-actu__social_links .bt-share [class^="icon-"] {
    display: inline-flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
  }
}

.c-actu__social_links .bt-share + .bt-share {
  margin-left: 20px;
}

@media (min-width: 992px) {
  .c-actu__social_links .bt-share + .bt-share {
    margin-left: 2rem;
  }
}

.c-actu__social_links .bt-share:hover {
  opacity: 1 !important;
  background-color: #000000;
  color: #ffffff;
  transition: all .3s ease;
}

.c-actu__social_links:hover .bt-share {
  opacity: .5;
}

.c-actu_similar {
  padding-top: 4.94792vw;
  padding-bottom: 4.16667vw;
  border-top: 1px solid #FFF852;
}

@media screen and (max-width: 992px) {
  .c-actu_similar {
    padding-top: 50px;
    padding-bottom: 20px;
  }
}

.c-actu_similar_head {
  margin-bottom: 4.6875vw;
}

@media screen and (max-width: 992px) {
  .c-actu_similar_head {
    margin-bottom: 30px;
  }
}

.c-actu_similar__title {
  color: #FFF852;
  font-size: 30px;
  line-height: 1.2;
}

@media (min-width: 991px) {
  .c-actu_similar__title {
    font-size: calc(1.61464vw + 13.99892px);
  }
}

@media (min-width: 1920px) {
  .c-actu_similar__title {
    font-size: 45px;
  }
}

@media screen and (max-width: 992px) {
  .c-actu_similar__title {
    font-size: 30px;
  }
}

.article .c-form-sort__button--reset {
  display: none;
}

.c-articles_container {
  margin: 0 -15px;
  width: auto;
}

.c-articles--no-articles {
  text-align: center;
  font-size: 14px;
  margin-top: 5rem;
}

@media (min-width: 760px) {
  .c-articles--no-articles {
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  .c-articles--no-articles {
    font-size: 18px;
  }
}

.c-articles_see-more {
  width: 100%;
  text-align: center;
  margin-top: 4rem;
}

@media screen and (max-width: 992px) {
  .c-articles_see-more {
    margin-top: 0;
  }
}

.c-article__img {
  width: 100%;
  height: 15.625vw;
  overflow: hidden;
}

@media screen and (max-width: 992px) {
  .c-article__img {
    height: 58.666666666666664vw;
  }
}

.c-article__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transform: scale(1);
  transition: all .3s ease;
}

.c-article__infos {
  background-color: #222222;
  position: relative;
  height: calc(100% - 15.625vw);
  padding: 2.6041666666666665vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

@media screen and (max-width: 992px) {
  .c-article__infos {
    height: auto;
    padding: 25px 15px;
  }
}

.c-article__infos .arrow {
  position: absolute;
  bottom: 1.5625vw;
  right: 1.5625vw;
  z-index: 3;
}

@media screen and (max-width: 992px) {
  .c-article__infos .arrow {
    bottom: 20px;
    right: 20px;
  }
}

.c-article__infos .arrow .icon-arrow {
  display: inline-flex;
  font-size: 1.5625vw;
  align-items: center;
  justify-content: center;
  width: 1.5625vw;
  height: 1.5625vw;
  color: #000000;
}

@media screen and (max-width: 992px) {
  .c-article__infos .arrow .icon-arrow {
    display: inline-flex;
    font-size: 25px;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
  }
}

.c-article__category {
  display: block;
  font-family: "transducer-extended";
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  line-height: 1;
  margin-top: 15px;
  margin-bottom: 25px;
}

@media (min-width: 992px) {
  .c-article__category {
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.c-article__title {
  font-size: 18px;
  line-height: 1.2;
  color: #FFF852;
  text-align: center;
  margin: 0;
  margin-bottom: 10px;
  transition: all .25s ease-out;
  margin-top: 10px;
}

@media (min-width: 991px) {
  .c-article__title {
    font-size: calc(1.29171vw + 5.19914px);
  }
}

@media (min-width: 1920px) {
  .c-article__title {
    font-size: 30px;
  }
}

@media screen and (max-width: 992px) {
  .c-article__title {
    font-size: 25px;
  }
}

.c-article__bouton {
  text-align: center;
  margin-top: 20px;
}

.c-article__date {
  display: block;
  font-size: 1.4rem;
  font-family: "transducer-extended";
  font-weight: 400;
  text-align: center;
  margin-bottom: 2.08333vw;
  color: #ffffff;
  transition: all .25s ease-out;
}

@media screen and (max-width: 992px) {
  .c-article__date {
    font-size: 14px;
    margin-bottom: 35px;
  }
}

.c-article__preview {
  display: inline-block;
  font-size: 1.4rem;
  font-family: "transducer-extended";
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  margin-bottom: 1.82292vw;
  transition: all .25s ease-out;
}

@media screen and (max-width: 992px) {
  .c-article__preview {
    font-size: 14px;
    margin-bottom: 25px;
  }
}

.c-article--card {
  position: relative;
  margin-bottom: 50px;
  margin-left: 15px;
  margin-right: 15px;
  min-height: 26.5625vw;
  width: calc(100% - 30px);
  background-color: #222222;
  transition: all .25s ease-out .25s, width .25s ease-out 0s, padding .25s ease-out 0s, max-height .25s ease-out 0s;
  opacity: 1;
  transform: scale(1);
  overflow: hidden;
  z-index: 7;
}

@media (min-width: 992px) {
  .c-article--card {
    width: calc(33.333% - 30px);
    max-height: 100vh;
  }
}

.c-article--card.inactive {
  opacity: 0;
  transform: scale(0.5);
  width: 0;
  max-height: 0;
  margin: 0;
  padding: 0;
  visibility: hidden;
  transition: all .3s ease-out 0s, width .1s linear .25s, padding .1s linear .25s, max-height .1s linear .25s;
}

.c-article--card a {
  display: block;
  height: 100%;
  background: #000000;
  border: 2px solid #000000;
}

.c-article--card:hover .c-article__infos .arrow {
  animation: arrow-mag 1s ease infinite;
}

.c-article--featured {
  width: 100%;
  background-color: #000000;
}

@media (min-width: 992px) {
  .c-article--featured {
    width: calc((33.333% - 15px) * 2);
  }
}

@media screen and (min-width: 992px) {
  .c-article--featured a {
    display: flex;
  }
}

@media (min-width: 760px) {
  .c-article--featured .c-article__img {
    height: 100%;
    width: 35.9375vw;
  }
}

.c-article--featured .c-article__infos {
  background-color: #000000;
}

@media (min-width: 760px) {
  .c-article--featured .c-article__infos {
    width: calc(100% - 35.9375vw);
    height: 100%;
  }
}

.c-article--featured .c-article__infos .arrow .icon-arrow {
  color: #000000;
}

.c-article--featured .c-article__title {
  color: #000000;
}

.c-article--small {
  background: #ffffff;
  margin-bottom: 30px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  margin-left: 15px;
  margin-right: 15px;
  width: calc(100% - 30px);
}

@media (min-width: 760px) {
  .c-article--small {
    width: calc(50% - 30px);
  }
}

@media (min-width: 1200px) {
  .c-article--small {
    width: calc(33.333% - 30px);
  }
}

.c-article--small .c-article__category {
  padding: 5px 10px;
  font-size: 12px;
  position: absolute;
  top: 1.5rem;
  bottom: auto;
  left: auto;
  right: 1.5rem;
  z-index: 10;
}

.c-article--small .c-article__infos {
  padding: 30px 20px;
}

.c-article--small .c-article__title {
  font-size: 16px;
  margin-top: 10px;
}

.c-article--small .c-article__date {
  font-size: 12px;
  margin-top: 0;
}

.articlesUne {
  position: relative;
  overflow: hidden;
  border-top: 1px solid #FFF852;
  padding-top: 4.42708vw;
  padding-bottom: 3.85417vw;
}

@media screen and (max-width: 992px) {
  .articlesUne {
    padding-top: 60px;
    padding-bottom: 50px;
  }
}

.articlesUne_title {
  color: #FFF852;
  text-align: center;
  margin-bottom: 5.20833vw;
}

@media screen and (max-width: 992px) {
  .articlesUne_title {
    margin-bottom: 50px;
  }
}

.carte {
  padding-top: 10rem;
}

@media screen and (max-width: 992px) {
  .carte {
    padding-top: 100px;
  }
}

.carte .bigTitle {
  font-size: 4rem;
}

@media screen and (max-width: 992px) {
  .carte .bigTitle {
    font-size: 30px;
  }
}

.carte .simple-container {
  padding-bottom: 10rem;
}

@media screen and (max-width: 992px) {
  .carte .simple-container {
    padding-bottom: 100px;
  }
}

.carte_content {
  padding-top: 10rem;
}

@media screen and (max-width: 992px) {
  .carte_content {
    padding-top: 100px;
  }
}

.carte_content .carte_group {
  border-bottom: 1px solid #000000;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media screen and (max-width: 992px) {
  .carte_content .carte_group {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.carte_content .carte_group:first-child {
  border-top: 1px solid #000000;
}

.carte_content .carte_group_btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.carte_content .carte_group_btn .plus {
  display: block;
  width: 3rem;
  height: 3rem;
  position: relative;
}

@media screen and (max-width: 992px) {
  .carte_content .carte_group_btn .plus {
    width: 20px;
    height: 20px;
  }
}

.carte_content .carte_group_btn .plus::after, .carte_content .carte_group_btn .plus::before {
  display: block;
  width: 100%;
  height: 3px;
  background-color: #000000;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.carte_content .carte_group_btn .plus::after {
  transform: translateX(-50%) translateY(-50%) rotate(90deg);
}

.carte_content .carte_group_content {
  overflow: hidden;
}

@media screen and (max-width: 992px) {
  .carte_content .carte_group_content {
    padding-top: 20px;
  }
}

.carte_content .carte_group_title {
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
}

@media screen and (max-width: 992px) {
  .carte_content .carte_group_title {
    font-size: 18px;
  }
}

.carte_content .carte_group .carte_product {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 2rem;
}

@media screen and (max-width: 992px) {
  .carte_content .carte_group .carte_product {
    margin-bottom: 10px;
  }
}

.carte_content .carte_group .carte_product:last-child {
  margin-bottom: 0;
}

.carte_content .carte_group .carte_product .name {
  font-size: 1.8rem;
}

@media screen and (max-width: 992px) {
  .carte_content .carte_group .carte_product .name {
    font-size: 14px;
  }
}

.carte_content .carte_group .carte_product .name .option {
  font-size: 1.2rem;
  font-style: italic;
}

@media screen and (max-width: 992px) {
  .carte_content .carte_group .carte_product .name .option {
    font-size: 12px;
  }
}

.carte_content .carte_group .carte_product .desc {
  font-size: 1.4rem;
  margin-top: 1rem;
}

@media screen and (max-width: 992px) {
  .carte_content .carte_group .carte_product .desc {
    font-size: 14px;
  }
}

.carte_content .carte_group .carte_product .price {
  font-size: 1.6rem;
  font-weight: bold;
}

@media screen and (max-width: 992px) {
  .carte_content .carte_group .carte_product .price {
    font-size: 14px;
  }
}

.carte_content .carte_menu .carte_selection {
  margin-top: 3rem;
}

@media screen and (max-width: 992px) {
  .carte_content .carte_menu .carte_selection {
    margin-top: 20px;
  }
}

.carte_content .carte_menu .carte_selection .title {
  font-size: 2.2rem;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #000000;
}

@media screen and (max-width: 992px) {
  .carte_content .carte_menu .carte_selection .title {
    font-size: 14px;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }
}

.carte_content .colum-prices_name {
  margin-bottom: 2rem;
}

@media screen and (max-width: 992px) {
  .carte_content .colum-prices_name {
    margin-bottom: 20px;
  }
}

.carte_content .colum-prices_name, .carte_content .colum-prices_content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.carte_content .colum-prices_name span, .carte_content .colum-prices_content span {
  width: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 992px) {
  .carte_content .colum-prices_name span, .carte_content .colum-prices_content span {
    width: 60px;
  }
}

.carte_content .categorieTitles {
  margin-bottom: 5rem;
  padding-top: 3rem;
}

@media screen and (max-width: 992px) {
  .carte_content .categorieTitles {
    margin-bottom: 40px;
  }
}

.carte_content .categorieTitles_title1 {
  font-size: 2.2rem;
  margin-bottom: 2rem;
  font-weight: 600;
  color: #000000;
}

@media screen and (max-width: 992px) {
  .carte_content .categorieTitles_title1 {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.carte_content .categorieTitles_title2 {
  font-size: 1.8rem;
  margin-bottom: 3rem;
}

@media screen and (max-width: 992px) {
  .carte_content .categorieTitles_title2 {
    font-size: 14px;
    margin-bottom: 15px;
  }
}

.carte_content .vMenu {
  padding-top: 3rem;
}

.carte_content .vMenu_title1 {
  font-size: 2.2rem;
  margin-bottom: 2rem;
  font-weight: 600;
  color: #000000;
}

@media screen and (max-width: 992px) {
  .carte_content .vMenu_title1 {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.carte_content .vMenu_title2 {
  font-size: 1.8rem;
  margin-bottom: 3rem;
}

@media screen and (max-width: 992px) {
  .carte_content .vMenu_title2 {
    font-size: 14px;
    margin-bottom: 15px;
  }
}

.carte_content .vMenu_price {
  font-size: 2.4rem;
  font-weight: 600;
  color: #000000;
}

@media screen and (max-width: 992px) {
  .carte_content .vMenu_price {
    font-size: 18px;
  }
}
