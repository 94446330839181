.simple-reference {
    position: relative;
    overflow: hidden;
    padding-top: vw(150px);
    padding-bottom: vw(90px);

    @media screen and (max-width:$screen-md) {
        padding-top: 45px;
        padding-bottom: 50px;
    }

    &_title {
        margin-bottom: 3rem;

        @media screen and (max-width:$screen-md) {
            margin-bottom: 25px;
        }
    }

    &_subtitle {
        margin-bottom: vw(50px);

        @media screen and (max-width:$screen-md) {
            margin-bottom: 50px;
        }
    }

    &_logos {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        border-radius: 5px;
        background-color: $noir2;
        padding: vw(50px);

        @media screen and (max-width:$screen-md) {
            flex-direction: column;
            padding: 50px 0;
            width: 215px;
            margin: 0 auto;
        }

        img {
            max-width: 18rem;
            max-height: 6rem;
            width: auto;
            height: auto;

            @media screen and (max-width:$screen-md) {
                margin-bottom: 80px;
                max-width: 145px;
                max-height: 60px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &.v-radio {

            img {
                max-width: 100%;
                max-height: 8.6rem;
                width: auto;
                height: auto;

                @media screen and (max-width:$screen-md) {
                    margin-bottom: 80px;
                    max-height: 80px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    &_arrows {

        @media screen and (max-width:$screen-md) {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: 40px;
        }

        img {
            position: absolute;
            top: 50%;
            left: 0;
            width: vw(70px);
            height: auto;
            transform: translateY(-50%) rotate(90deg);
            opacity: 1;
            cursor: pointer;
            transition: all .3s ease;

            @media screen and (max-width:$screen-md) {
                position: relative;
                top: 0;
                transform: rotate(90deg);
                width: 70px;
            }

            &.slider-next {
                left: auto;
                right: 0;
                transform: translateY(-50%) rotate(-90deg);

                @media screen and (max-width:$screen-md) {
                    transform: rotate(-90deg);
                    margin-left: 15px;
                }
            }

            &:hover {
                opacity: .6;
                transition: all .3s ease;
            }
        }
    }

    .swiper-references {
        width: 100%;
        overflow: hidden;
        padding-left: 0;
        padding-right: 0;

        .swiper-slide {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .grid-container {
        &.width-slider {
            @media screen and (max-width:$screen-md) {
                padding: 0;
            }
        }
    }
}