.accelerateurs_big {
    display: flex;
    flex-direction: column;
    &-item {
        img {
            max-width: 70vw;
            max-height: 70vw;
            object-fit: contain;
        }
        &+& {
            border-top: 1px solid $jaune;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: vw(30px) 0;
        @media screen and (max-width:$screen-md) {
            flex-direction: column;
            text-align: center;
            padding: 30px 0;
        }
        &_left {
            display: flex;
            align-items: center;
            gap: vw(70px);
            @media screen and (max-width:$screen-md) {
                flex-direction: column;
                gap: 35px;
            }
            .text {
                display: flex;
                flex-direction: column;
                gap: 20px;
                @media screen and (max-width:$screen-md) {
                    align-items: center;
                }
                p {
                    width: vw(532px);
                    white-space: normal;
                    @media screen and (max-width:$screen-md) {
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }
        .play {
            border: 1px solid $jaune;
            border-radius: 100vmax;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 64px;
            aspect-ratio: 1;
            transition: all 0.3s ease;
            margin: 30px 0;
            background-color: $noir;
            &:hover {
                background-color: $jaune;
                svg path {
                    fill: $noir;
                }
            }
            svg {
                width: 20px;
                margin-left: 6px;
            }
        }
    }
}