.composant-3{
    display : flex;
    @media screen and (max-width:$screen-md) {
        flex-direction: column;
    }

    &_txt{ 
        display : flex;
        flex-direction: column;
        justify-content: center;
        gap : 7.8vh;
        width : 50%;
        padding : 22.2vh 8vw;
        background-color: $jaune;
        @media screen and (max-width:$screen-md) {
            gap : 4.6vh;
            width : 100%;
            padding : 13.1vh 30px;
        }
        
        .text-top{
            position : relative;
            @include fluid-text(25px, 45px, 1.3, 991px, 1920px);
            @include fluid-text(30px, 30px, 1.3, 320px, 990px); 
            font-weight : $bold;
            color : $noir;
            transition : all 1s;

            &.masked{
                transform: translateY(50%);
                opacity: 0; 
                transition: none;        
            }
        }

        .text-bottom{
            position : relative;
            width : calc(100% - 3vw);
            color : $noir; 
            line-height : 2;
            transition : all 1s;
            @media screen and (max-width:$screen-md) {
                width : 100%;
            }

            span{
                font-weight : $bold;
            }

            &.masked{
                transform: translateY(50%);
                opacity: 0; 
                transition: none;        
            }
        }
    }

    &_img{
        width : 50%;
        overflow : hidden;
        @media screen and (max-width:$screen-md) {
            width : 100%;
        }

        img{
            position : relative;
            width : 100%;
            height : 90.3vh;
            min-height : 100%;
            object-fit: cover;
            object-position: center;
            transition : all 1s;
            @media screen and (max-width:$screen-md) {
                height : 75.2vh;
            }

            &.masked{
                transform: scale(1.2);
                transition: none;        
            }
        }
    }
}