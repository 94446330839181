.c-formats {
    position: relative;
    overflow: hidden;
    padding-top: vw(170px);
    padding-bottom: vw(90px);

    @media screen and (max-width:$screen-md) {
        padding-top: 70px;
        padding-bottom: 45px;
    }

    &_bigTitle {
        margin-bottom: vw(90px);

        @media screen and (max-width:$screen-md) {
            margin-bottom: 40px;
            text-align: center;
        }
    }

    .forme {
        position: absolute;
        top: vw(-50px);
        right: vw(-110px);
        width: vw(980px);
        z-index: 0;

        @media screen and (max-width:$screen-md) {
            width: 420px;
            top: -120px;
            right: -90px;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &_content {
        margin: 0 auto;
        position: relative;
        z-index: 5;
        padding: 0 30px;

        @media screen and (min-width:$screen-md) {
            width: 79.6875vw;
            column-gap: 30px;
            row-gap: vw(45px);
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            padding-left: 0;
            padding-right: 0;
        }

        .item {
            position: relative;
            border-radius: 2rem;
            border: 1px solid $jaune;
            padding: vw(55px) vw(75px);

            @media screen and (max-width:$screen-md) {
                padding: 35px 25px;
                margin-bottom: 30px;
                border-radius: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &_title {
                margin-bottom: vw(40px);

                @media screen and (max-width:$screen-md) {
                    margin-bottom: 25px;
                }
            }
        }
    }
}