$screen-xl: 1500px;
$screen-lg: 1200px;
$screen-md: 992px;
$screen-sm: 760px;
$screen-xs: 480px;
$screen-xss: 340px;

/*************************************************
// BOOTSTRAP & VARIABLES
*************************************************/

$font-family-sans-serif: 'Open Sans',
Arial,
sans-serif;
$font-size-base: 10px;
$line-height-base: 1;
$link-hover-color:inherit;
$link-hover-decoration:none;
/*************************************************
// GRID
*************************************************/

@import 'base/_grid';

/*************************************************
// FONTS
*************************************************/

@import 'utilities/_fonts';

/*************************************************
// MIXINS
*************************************************/

@import 'base/_mixins';

/*************************************************
// COULEURS DU SITE
*************************************************/

@import 'utilities/_colors';

/*************************************************
// BASE
*************************************************/


* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-variant-ligatures: common-ligatures;
    -webkit-font-variant-ligatures: common-ligatures;
}

/*************************************************
// BASE
*************************************************/

html {
    font-size: 5.2px;
    font-family: $font;
    color: $blanc;
    background-color: $noir;
    width: 100%;
    min-height: 100%;
    overflow-x: hidden;
    font-kerning: none;

    &.noscroll {
        overflow: hidden;
    }

    @media (min-width: $screen-sm) {
        font-size: 6.1px;
    }

    @media (min-width: $screen-md) {
        font-size: 7.2px;
    }

    @media (min-width: $screen-lg) {
        font-size: 8.5px;
    }

    @media (min-width: $screen-xl) {
        font-size: 10px;
    }
}

body {
    min-height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;

    @media screen and (max-width:$screen-md) {
        overflow: scroll;
    }
}

#wrapper {
    overflow: hidden;
    height : 100vh;

    @media screen and (max-width:$screen-md) {
        overflow: scroll;
        height: auto;
    }
}

#wrapper-scroll {
    z-index: 1;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0;
    margin-left: 0;
    white-space: nowrap;
    height: 100vh;
    position: relative;
    overflow: visible;
    transition: all .85s cubic-bezier(0, 0, .25, 1);

    @media screen and (max-width:$screen-md) {
        height: auto;
        white-space: initial;
    }

    .section {
        position: relative;
        display: inline-flex;
        align-items: center;
        vertical-align: top;
        white-space: nowrap;
        height: 100vh;
        overflow: visible; 
    
        @media screen and (max-width:$screen-md) {
            height: auto;
            width: 100%;
            overflow: hidden;
            display: block;
        }
    
        &>section {
            white-space: normal;
        }
    }
}


button,
input,
textarea {
    border: none;
    background: none;
}

a,
button {
    transition: all .3s ease;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

a img {
    border: 0;
}

a:hover,
button:hover {
    text-decoration: none;
}

img,
svg {
    vertical-align: middle;
    border: none;
}

b,
strong {
    font-family: $font;

}

ul,
li {
    list-style: none;
    list-style-type: none;
}

textarea {
    overflow: auto;
    resize: none;
}

/*************************************************
// STYLES DE CARATERES
*************************************************/

@import 'utilities/_styles-carateres';


/*************************************************
// PROPRIETES TEXTE
*************************************************/

.underline {
    text-decoration: underline;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

.left {
    text-align: left;
}

.justify {
    text-align: justify;
}

.uppercase {
    text-transform: uppercase;
}

br:not(.keep) {
    @media screen and (max-width:$screen-md) {
        display: none;
    }
}

/*************************************************
// BOUTONS
*************************************************/

@import 'utilities/_boutons';

/*************************************************
// CLASS UTILITAIRE
*************************************************/

.os-theme-dark>.os-scrollbar>.os-scrollbar-track>.os-scrollbar-handle {
    background: rgba($color: $noir, $alpha: 1) !important;

    &.noscroll {
        opacity: 0 !important;
        pointer-events: none;
    }

    @media screen and (max-width:$screen-md) {
        opacity: 0 !important;
        pointer-events: none;
    }
}

.no-link-desktop {
    @media screen and (min-width:$screen-md) {
        pointer-events: none;
        cursor: initial;
    }
}

.hidden {
    display: none;
}

.hidden-pc {
    @media screen and (min-width:$screen-md) {
        display: none!important;
    }
}

.hidden-mobile {
    @media screen and (max-width:$screen-md) {
        display: none !important;
    }
}

.overflow-hidden {
    overflow: hidden;
}

.page-attente {
    height: 100vh;
    display: flex;
    align-items: center;
    position: relative;

    @media screen and (max-width: $screen-md) {
        overflow: scroll;
        display: block;
        padding-bottom: 50px;
        background-color: $noir;
    }

    &_left,
    &_right {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: $screen-md) {
            width: 100%;
            height: auto;
            padding: 0 30px;
        }
    }

    &_left {
        background-color: $jaune;
        padding-right: calc(17.447916666666668vw / 2);

        @media screen and (max-width: $screen-md) {
            padding-top: 60px;
            height: 425px;
            padding-bottom: calc(235px / 2 + 40px);
        }

        .content {
            width: 43rem;

            @media screen and (max-width: $screen-md) {
                width: 100%;
            }

            &_title {
                margin-bottom: vw(50px);
                transform: translateY(100%);
                opacity: 0;
                animation: trans-opacity-top-100 0.4s linear forwards 0.2s;

                @media screen and (max-width: $screen-md) {
                    margin-bottom: 25px;

                    br {
                        display: block !important;
                    }
                }
            }

            &_paragraphe {
                transform: translateY(100%);
                opacity: 0;
                animation: trans-opacity-top-100 0.5s ease forwards 0.2s;
            }
        }
    }

    &_right {
        background-color: $noir;
        padding-left: calc(17.447916666666668vw / 2);

        @media screen and (max-width: $screen-md) {
            min-height: calc(var(--heightJs) - 425px);
            padding-top: calc(235px / 2 + 20px);
            padding-bottom: 50px;
            align-items: center;
        }

        .content {
            width: 49rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            row-gap: vw(75px);

            @media screen and (max-width: $screen-md) {
                width: 100%;
                row-gap: 35px;
            }

            &_row {
                width: 50%;
                display: flex;
                align-items: center;
                transform: translateY(100%);
                opacity: 0;
                animation: trans-opacity-top-100 0.5s ease forwards 0.4s;

                @media screen and (max-width: $screen-md) {
                    flex-direction: column;
                }

                .icon {
                    width: 8rem;

                    @media screen and (max-width: $screen-md) {
                        width: auto;
                        margin-bottom: 15px;
                    }

                    img {
                        height: vw(50px);

                        @media screen and (max-width: $screen-md) {
                            height: 50px;
                        }

                        img {
                            height: 100%;
                            width: auto;
                        }
                    }
                }

                .title {
                    font-family: $font;
                    font-weight: $bold;
                    @include fluid-text(14px, 18px, 1, 991px, 1920px);
                    color: $blanc;

                    @media screen and (max-width: $screen-md) {
                        font-size: 16px;
                        text-align: center;

                        br {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    &_middle {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        overflow: hidden;
        width: 17.447916666666668vw;
        height: 17.447916666666668vw;
        border-radius: 100%;

        @media screen and (max-width: $screen-md) {
            width: 235px;
            height: 235px;
            top: 425px;
        }

        .video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            min-width: 100%;
            min-height: 100%;
            z-index: -1;
            object-position: center;
            object-fit: cover;
        }
    }
}

@media screen and (max-width:991px) {
    canvas {
        pointer-events: none;
        display: none
    }
}

// .no-transition {
//     transition: transform .3s, opacity 0s;
// }
// .lite-transition {
//     transition: all 0.5s ease-out !important;
// }

// .stop-scrolling {
//     height: 100%;
//     overflow: hidden;
//     touch-action: none;
// }


/*************************************************
// IMPORT DEPENDENCIES
*************************************************/

// Dependencies
@import 'vendor/swiper-bundle.css';
@import 'vendor/OverlayScrollbars.css';

// Partials
@import 'partials/_header';
@import 'partials/_footer';
@import 'partials/_nav';
@import 'partials/_menu';

// Pages
@import 'pages/_accueil';
@import 'pages/_concept';
@import 'pages/_podcast';
@import 'pages/_radio';
@import 'pages/_formation';
@import 'pages/_animation';
@import 'pages/_teambuilding';
@import 'pages/_contact';
@import 'pages/_legals';
@import 'pages/_error404';

// Components
@import 'components/_cookies';
@import 'components/_formulaire';
@import 'components/_collapseDiv';
@import 'components/_accelerateurs';
@import 'components/_accelerateurs_big';
@import 'components/_composant1';
@import 'components/_composant2';
@import 'components/_composant3';
@import 'components/_references';
@import 'components/_modal-references';
@import 'components/_simpleReference';
@import 'components/_formats';
@import 'components/_blocQuestion';

// Utilities
@import 'utilities/_fonts';
@import 'utilities/_animations';
@import 'utilities/_icons';

// Magazine
@import 'mag/_mag';
@import 'mag/_form-sort';
@import 'mag/_actu';
@import 'mag/_article';
@import 'mag/_articleune';

// Carte / Menu restaurant
@import 'carte/_carte';