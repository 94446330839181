.c-form-sort {
    width: 100%;
    position: fixed;
    top: 9.4rem;
    padding-top: 3rem;
    padding-bottom: 2rem;
    left: 0;
    z-index: 102;
    transition: all .3s ease;

    @media screen and (max-width:$screen-md) {
        top: 98px;
    }

    .simple-container {
        @media screen and (max-width:$screen-md) {
            padding: 0;
        }
    }

    &_top {
        @media screen and (max-width:$screen-md) {
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    &_selectors {
        overflow-x: auto;
        overflow-y: visible;
        width: auto;
        height: auto;

        @media screen and (max-width:$screen-md) {
            justify-content: space-between;
            width: 100%;
        }
    }

    &_select {
        width: 100%;
        position: relative;
        margin-top: 5rem;

        @media (min-width:$screen-sm) {
            width: auto;
            margin-top: 0;
        }

        &_title {
            position: relative;
            text-align: right;
            margin-bottom: 10px;
            font-size: 12px;

            @media (min-width:$screen-sm) {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                text-align: left;
                z-index: 1;
                transform: translateY(-120%);
            }
        }
    }

    // *** Input search part
    &__search {
        width: 100%;
        border: solid 1px $blanc;
        color: $blanc;
        height: 35px;
        margin-bottom: 5px;
        border-radius: 2.5rem;
        margin-top: 1rem;
        padding: 0 15px;
        font-size: 14px;

        @media (min-width:$screen-sm) {
            width: 250px;
            margin-top: 10px;
        }

        &::-webkit-input-placeholder {
            color: $blanc;
            opacity: 1;
        }

        &::-moz-placeholder {
            color: $blanc;
            opacity: 1;
        }

        &::-ms-placeholder {
            color: $blanc;
            opacity: 1;
        }

        &::-ms-input-placeholder {
            color: $blanc;
            opacity: 1;
        }

        &:-ms-input-placeholder {
            color: $blanc;
            opacity: 1;
        }
    }

    // *** Button part
    &__button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-family: $font;
        font-weight: $bold;
        padding: 0 2.5rem;
        height: 5.5rem;
        color: $blanc;
        background-color: $noir;
        font-size: 1.8rem;
        border-radius: 2.8rem;
        border: 1px solid $jaune;

        @media screen and (max-width:$screen-md) {
            font-size: 14px;
            height: 50px;
            width: calc((100% - 50px - 3rem) - 30%);
            padding: 0 5px;
            border-radius: 25px;
        }

        &:not(:first-child) {
            margin-left: 3rem;
        }

        &.button-home {
            display: none;
        }

        &:nth-child(2) {
            @media screen and (max-width:$screen-md) {
                width: calc((100% - 50px - 6rem) - 50%);
            }
        }

        &:last-child:not(.c-form-sort__button--small) {

            @media screen and (max-width:$screen-md) {
                width: 50px;
                height: 50px;
            }
        }

        .text {
            display: inline-block;

            @media screen and (max-width:$screen-md) {
                margin-right: 5px;
                max-width: 115px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden
            }
        }

        .reset {
            @media screen and (max-width:$screen-md) {
                display: none;
            }
        }

        .icons {
            margin-left: 10px;

            &.invers {
                margin-left: 0;
                margin-right: 10px;

                @media screen and (max-width:$screen-md) {
                    margin-right: 0;
                }
            }
        }

        .icon-arrow {
            @include icon(1.8rem);
            color: $blanc;
            transform: rotate(0);
            transition: all .3s ease-out;

            @media screen and (max-width:$screen-lg) {
                @include icon(14px);
            }
        }

        .icon-refresh {
            @include icon(2.6rem);
            transform: rotate(-45deg)scaleX(-1);
            transition: all .3s ease-out;
            margin: 0;

            @media screen and (max-width:$screen-md) {
                @include icon(14px);
            }
        }

        // Bug flex with safari
        &:after,
        &:before {
            content: normal;
            display: none;
        }

        &--reset {
            border-radius: 100%;
            padding: 0;
            width: 5.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        // *** Small version
        &--small {
            position: relative;
            min-width: 0;
            font-family: $font;
            font-weight: $regular;
            text-transform: none;
            font-size: 1.4rem;
            border: 1px solid $noir;
            background: $noir;
            color: $blanc;
            height: auto;
            border-radius: 0;
            padding: 0;
            margin: 1rem;

            @media screen and (max-width:$screen-md) {
                font-size: 16px;
                height: 30px;
                margin: 0;
                margin-right: 10px;
                margin-top: 10px;
                margin-bottom: 10px;
                padding: 0 15px;
                width: auto !important;
            }

            &:last-child {
                margin-right: 0;
            }

            &:not(:first-child) {
                margin-left: 0;
            }

            &:hover {
                background: transparent !important;
                color: $jaune !important;
            }

            &.active {
                background: transparent !important;
                color: $jaune !important;
            }
        }

        &:hover,
        &.active {
            background: $jaune;
            color: $noir;

            .icon-arrow {
                color: $noir;
            }
        }

        &.active {
            background: $jaune;

            .icon-arrow {
                color: $noir;
                transform: rotate(90deg);
            }
        }
    }


    // *** Input search part
    &_list {
        visibility: hidden;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 50vh;
        opacity: 0;
        transition: all .3s ease-out;
        padding: 1.5rem 0;
        @include position(absolute, 1, 100%, 0, auto, 0);

        @media screen and (max-width:$screen-md) {
            padding: 15px;
        }

        .wrap {
            display: inline-block;
            border: 1px solid $jaune;
            border-radius: 2.8rem;
            background-color: $noir;
            padding: 0 2rem;
            
            @media screen and (max-width:$screen-md) {
                padding: 0 10px;
                border-radius: 25px;
            }
        }

        &.active {
            opacity: 1;
            visibility: visible;
        }

        // *** Items container

        &_items {
            width: auto;
        }

        // *** No results message

        &_notags {
            font-size: 12px;
            margin: 10px 0;
            color: $blanc;
        }
    }

    &.masked {
        top: 9.4rem;
        transition: all .3s ease;

        @media screen and (max-width:$screen-md) {
            top: 98px;
        }
    }

}