.c-references {
    position: relative;
    overflow: hidden;
    padding-top: vw(150px);
    padding-bottom: vw(150px);

    @media screen and (max-width:$screen-md) {
        padding-top: 45px;
        padding-bottom: 45px;
    }

    &_title {
        margin-bottom: vw(25px);

        @media screen and (max-width:$screen-md) {
            margin-bottom: 35px;
        }
    }

    &_slider {
        position: relative;
        width: 100%;
        margin-top: vw(105px);
        display: flex;
        align-items: center;
        padding: 0 vw(130px);

        @media screen and (max-width:$screen-md) {
            padding: 0;
            width: 100vw;
            left: -30px;
            margin-top: 45px;
        }

        &_arrows {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%) rotate(90deg);
            width: vw(70px);
            cursor: pointer;

            @media screen and (max-width:$screen-md) {
                display: none;
            }

            &.slider-next {
                left: auto;
                right: 0;
                transform: translateY(-50%) rotate(-90deg);
            }

            &.swiper-button-disabled {
                opacity: 0;
                pointer-events: none;
            }

            img {
                width: 100%;
                height: auto;
                transform: scale(1);
                transition: all .3s ease;
            }

            &:hover {
                img {
                    transform: scale(1.1);
                    transition: all .3s ease;
                }
            }
        }

        .swiper-references {
            width: 100%;
            overflow: hidden;

            @media screen and (max-width:$screen-md) {
                padding-left: 30px;
                padding-right: 30px;
                overflow: visible;
            }

            .swiper-slide {
                height: auto;

                .item {
                    position: relative;
                    display: block;
                    width: 100%;
                    height: 100%;
                    padding: 2rem;
                    border-radius: 5px;
                    background-color: $noir2;

                    @media screen and (max-width:$screen-md) {
                        padding: 15px;
                    }

                    .img {
                        width: 100%;
                        aspect-ratio: 1 / 1;
                        margin-bottom: 2.5rem;
                        z-index: 1;

                        @media screen and (min-width:$screen-md) {
                            position: relative;
                        }

                        @media screen and (max-width:$screen-md) {
                            margin-bottom: 10px;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }

                    .play {
                        position: absolute;
                        bottom: 15px;
                        right: 15px;
                        z-index: 2;
                        display: block;
                        width: vw(48px);
                        opacity: 0;
                        transition: all .3s ease;

                        @media screen and (max-width:$screen-md) {
                            opacity: 1;
                            width: 35px;
                            bottom: calc(-35px / 2);
                        }

                        img {
                            width: 100%;
                            height: auto;
                        }
                    }

                    .title,
                    .subtitle {
                        margin: 0;
                        font-family: $font;
                        font-weight: $bold;
                        @include fluid-text(11px, 13px, 1.6, 991px, 1920px);

                        @media screen and (max-width:$screen-md) {
                            font-size: 11px;
                        }
                    }

                    .subtitle {
                        color: $jaune;
                        margin-bottom: 5px;
                    }

                    &:hover:not(.modal-empty) {

                        @media screen and (min-width:$screen-md) {
                            .play {
                                opacity: 1;
                                transition: all .3s ease;
                            }
                        }
                    }

                    &.no-link {
                        pointer-events: none;

                        .play {
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }
}