.concept {
    p {
        white-space: normal;
    }
    .p-header {
        background-image : url(../img/concept/header.jpg);
        @media screen and (max-width:$screen-md) {
            background-image : url(../img/concept/header-s.jpg);
        }
    }

    .p-nav_bottom{
        .social{
            visibility : hidden;
        }
    }

    .composant-2{
        background-image : url(../img/concept/header.jpg);
        @media screen and (max-width:$screen-md) {
            background-image : url(../img/concept/header-s.jpg);
        }
    }
    .sticky {
        position: relative;
        z-index: 100;
        overflow: hidden;
        @media screen and (max-width:$screen-md) {
            padding: 100px 30px 30px;
        }

        audio {
            pointer-events: all;
            max-width: 100%;
        }

        .logomark {
            width: vw(980px);
            position: absolute;
            left: 30px;
            @media screen and (max-width:$screen-md) {
                display: none;
            }
        }

        &_etapes {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .fixed-elem {
            @media screen and (min-width:$screen-md) {
                position: absolute;
                top: 0;
                bottom: auto;
                width: 100%;
                z-index: 1;
                display: flex;
                align-items: center;
                justify-content: center;
    
                &.fixed {
                    position: fixed;
                    pointer-events: none;
                    top: auto;
                    bottom: 0;
                    // width: calc(100vw - 10.1vw * 2);
                    @media screen and (max-width: $screen-md) {
                        width: calc(100vw - 30px * 2);
                    }
                }
    
                &.absolute-end {
                    position: absolute;
                    top: auto;
                    bottom: 0;
                }
            }
            @media screen and (max-width:$screen-md) {
                max-width: 100%;
            }

            .item {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100vh;
                transform-origin: center 200vw;
                transform: rotate(20deg);
                @media screen and (max-width:$screen-md) {
                    transform-origin: center 200vh;
                    transform: none !important;
                    flex-direction: column;
                    height: unset;
                    gap: 80px;
                }
                .personne {
                    display: flex;
                    gap: 3.2vw;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100vh;
                    margin: 0;
                    transform-origin: center;
                    transform: rotate(-20deg);
                    @media screen and (max-width:$screen-md) {
                        flex-direction: column;
                        align-items: start;
                        transform: none !important;
                        height: unset;
                    }

                    &_container {
                        position: absolute;
                        transform-origin: center;
                        @media screen and (max-width:$screen-md) {
                            transform: none!important;
                            position: relative;
                            max-width: 100%;
                        }
                        &:nth-child(1) {
                            transform: translate(-58vw, 10vw);
                        }

                        &:nth-child(3) {
                            transform: translate(58vw, 10vw);
                        }
                    }

                    .img {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: auto;
                        border: 1px solid $jaune;
                        padding: vw(25px);
                        border-radius: 100vmax;
                        @media screen and (max-width:$screen-md) {
                            padding: 17px;
                        }

                        img {
                            width: 23.28vw;
                            min-width: 235px;
                        }
                    }

                    .txt {
                        width: 21.8vw;
                        transition: 0.5s ease;
                        p {
                            margin-top: vw(40px);
                            @media screen and (max-width:$screen-md) {
                                margin-top: 40px;
                            }
                        }
                        @media screen and (max-width:$screen-md) {
                            max-width: calc(100vw - 60px);
                            width: 300px;
                            border-left: none;
                            padding-left: 0;
                            margin-top: 5.9vh;
                        }
                        &-container {
                            display: flex;
                            flex-direction: column;
                            gap: vw(40px);
                            @media screen and (max-width:$screen-md) {
                                gap: 15px;
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        }

        .etape {
            overflow: hidden;

            &-part {
                position: relative;
                height: 100vh;
                z-index: 2;
                @media screen and (max-width:$screen-md) {
                    height: unset;
                }
            }
        }
    }
}