.animation {
    .p-header {
        background-image: url(../img/animation/header.jpg);

        @media screen and (max-width:$screen-md) {
            background-image: url(../img/animation/header-s.jpg);
        }
    }

    .p-nav_bottom {
        .social {
            visibility: hidden;
        }
    }

    .composant-2 {
        background-image: url(../img/animation/header.jpg);

        @media screen and (max-width:$screen-md) {
            background-image: url(../img/animation/header-s.jpg);
        }

        &_txt-p {
            white-space: normal;
            text-align: center;
        }
    }

    .c-formats {
        overflow: visible;
    }

    .croire {
        padding: 26.3vh 16.9vw;
        background-color: $noir;

        @media screen and (max-width:$screen-md) {
            display: flex;
            flex-direction: column;
            gap: 2vh;
            padding: 10vh 0;
        }

        &_title {
            @include fluid-text(20px, 25px, 1.8, 991px, 1920px);
            @include fluid-text(20px, 25px, 1.8, 320px, 990px);
            font-weight: $bold;
            width: vw(485px);
            margin-bottom: 14.8vh;
            margin-left: vw(130px);

            @media screen and (max-width:$screen-md) {
                width: 100%;
                padding: 0 30px;
                margin-left: 0;
            }

            @media screen and (max-width:1500px) {
                width: 35vw;
            }

            span {
                color: $jaune;
            }
        }

        &_img {
            position: relative;
            width: fit-content;

            &-container {
                overflow: hidden;

                &.container1 {
                    margin-left: vw(130px);

                    @media screen and (max-width:$screen-md) {
                        margin-left: 0;
                    }
                }

                &.container2 {
                    margin-top: -17.1vh;
                    margin-left: vw(520px);

                    @media screen and (max-width:$screen-md) {
                        display: flex;
                        justify-content: flex-end;
                        margin-left: 0;
                        margin-top: 0;
                    }
                }

                &.container3 {
                    margin-top: -7.4vh;

                    @media screen and (max-width:$screen-md) {
                        margin-top: 0;
                    }
                }

                img {
                    position: relative;
                    height: auto;
                    transition: all 1s;

                    &.masked {
                        transform: scale(1.2);
                        transition: none;
                    }

                    &.img1 {
                        width: vw(490px);

                        @media screen and (max-width:$screen-md) {
                            width: calc(100% - 30px);
                        }

                    }

                    &.img2 {
                        width: vw(750px);

                        @media screen and (max-width:$screen-md) {
                            width: calc(100% - 30px);
                        }
                    }

                    &.img3 {
                        width: vw(750px);

                        @media screen and (max-width:$screen-md) {
                            width: calc(100% - 30px);
                        }
                    }
                }
            }

            &-pastille {
                position: absolute;
                right: vw(-63px);
                top: vw(-63px);

                @media screen and (max-width:$screen-md) {
                    right: 30px;
                    top: -13.2vw;
                }

                img {
                    width: vw(126px);
                    height: vw(126px);

                    @media screen and (max-width:$screen-md) {
                        width: 26.4vw;
                        height: 26.4vw;
                    }

                    &.croire_img-pastille--v {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
            }
        }
    }

    .simple-reference {

        &_logos {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            border-radius: 5px;
            background-color: $noir2;
            padding: vw(50px);

            @media screen and (max-width:$screen-md) {
                flex-direction: row;
                padding: 0;
                padding-left: 30px;
                padding-top: 45px;
                padding-bottom: 45px;
                width: 100%;
                margin: 0 auto;
            }

            img {
                max-width: initial;
                max-height: initial;
                width: auto;
                height: 100%;

                @media screen and (max-width:$screen-md) {
                    height: 100%;
                }
            }
        }
    }
}