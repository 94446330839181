.c-formulaire {
    position: relative;
    display : flex;
    flex-direction: column;
    gap : 5vh;
    overflow: hidden;
    width : 70vw;
    @media screen and (max-width:$screen-md) {
        gap : 4.6vh;
        width : 100%;
    }

    &_bloc{
        display : flex;
        justify-content: space-between;
        gap : 1.5vw;
        width : 100%;
        @media screen and (max-width:$screen-md) {
            flex-direction: column;
            gap : 4.6vh;
        }

        &-content{
            position : relative;
            display : flex;
            flex-direction: column;
            gap : 3.2vh;
            width : 50%;
            @media screen and (max-width:$screen-md) {
                gap : 4.6vh;
                width : 100%;
            }
        }
    }

    .bouton {
        @include fluid-text(14px, 18px, 1.1, 991px, 1920px);
        @include fluid-text(14px, 16px, 1.1, 320px, 990px);
        font-weight : $bold;
        background-color: transparent;
        color: $blanc;
        font-weight: $regular;
        padding-top: 10px;
        padding-bottom: 10px;
        
        @media screen and (max-width:$screen-md) {
            width : fit-content;
            align-self : center;
        }

        &:hover {
            background-color: $jaune;
            color: $noir;
        }
    }

    &_field {
        position: relative;
        display : flex;
        flex-direction: column;      
        
        &.message{
            height : 100%;
        }

        &_label {
            @include fluid-text(14px, 18px, 1.5, 991px, 1920px);
            @include fluid-text(16px, 18px, 1.5, 320px, 990px);
            margin-bottom: 10px;
        }

        .form-radio {
            position: relative;
            padding-left: 50px;
            margin-bottom: 10px;

            input[type=radio],
            input[type=checkbox] {
                appearance: none;
                opacity: 0;
                width: 0;
                height: 0;
            }

            label {
                @include fluid-text(10px, 10px, 1.5, 991px, 1920px);
                display: inline-block;
                font-family: $font;        
                font-weight: $regular;
                cursor: pointer;
                margin: 0;
                width : 70%;
                color : $blanc;

                @media screen and (max-width:1500px) {
                    width : 100%;
                }

                @media screen and (max-width:$screen-md) {
                    width : 100%;
                }

                &:after {
                    display: block;
                    content: '';
                    width: 20px;
                    height: 20px;
                    border: solid 2px $jaune;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    z-index: 1;
                    transition: all .2s ease;
                }

                &:before {
                    display: block;
                    content: '';
                    width: 12px;
                    height: 12px;
                    background: $jaune;
                    position: absolute;
                    left: 6px;
                    top: 0;
                    bottom: 0;
                    z-index: 2;
                    margin: auto;
                    transition: all .2s ease;
                    opacity: 0;
                }
            }

            input[type=radio]:checked+label,
            input[type=checkbox]:checked+label {
                &:before {
                    opacity: 1;
                }
            }
        }

        ::-webkit-input-placeholder {
            color: $noir;
            opacity: 1;
        }

        ::-moz-placeholder {
            color: $noir;
            opacity: 1;
        }

        ::-ms-placeholder {
            color: $noir;
            opacity: 1;
        }

        ::-ms-input-placeholder {
            color: $noir;
            opacity: 1;
        }

        :-ms-input-placeholder {
            color: $noir;
            opacity: 1;
        }

        input[type=tel],
        input[type=email],
        input[type=text],
        textarea {
            width: 100%;
            transition: all .5s ease;
            border: solid 1px $jaune;
            border-radius : 100vmax;
            color: $jaune;
            font-family : $font;
            @include fluid-text(14px, 14px, 1.5, 991px, 1920px);
            padding: 0 15px;
            height: 50px;

            &:focus {
                background: rgba(255, 255, 255, .15);
            }
        }

        textarea {
            padding-top: 10px;
            height: 100%;
            border-radius : 25px;
            @media screen and (max-width:$screen-md) {
                height : 200px;
                border-radius : 15px;
            }
        }

        .dropdown-toggle {
            display: block;
            width: 100%;
            position: relative;

            .dropdown-toggle-btn {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                justify-content: space-between;
                width: 100%;
                vertical-align: middle;
                transition: all .5s ease 0s;
                border: solid 1px $noir;
                color: $noir;
                font-size: 14px;
                height: 8rem;
                max-height: 60px;
                padding: 0 42px 0 15px;
                position: relative;

                @media (min-width:$screen-lg) {
                    font-size: 16px;
                }

                &:focus {
                    color: $blanc;
                    background: $noir;
                    border-color: $noir;
                }

                .icon-cursor {
                    @include icon(2rem);
                }
            }

            ul.dropdown {
                @include position(absolute, 2, 100%, 0, auto, 0);
                background: $noir;
                overflow: hidden;
                max-height: 0;
                transition: all .5s ease;
                font-size: 14px;

                @media (min-width:$screen-lg) {
                    font-size: 16px;
                }

                li {
                    max-height: 0;
                    padding: 0 15px;
                    line-height: 50px;
                    transition: all .5s ease;
                    color: $blanc;

                    a:hover {
                        opacity: .5;
                    }
                }

            }

            &.open {
                a.toggle {
                    transition-delay: 0s;
                }

                ul.dropdown {
                    max-height: 300px;
                    overflow: auto;
                    transition: all .5s ease 0s;

                    li {
                        max-height: 50px;
                        transition-delay: 0s;
                    }
                }
            }
        }

        &::after {
            margin-top: 0;
            display: block;
            content: "";
            height: 0;
            opacity: 0;
            transition: 0.5s all ease-out;
        }

        &.error::after {
            height: 15px;
            margin: 5px;
            content: attr(data-message);
            color: $jaune;
            opacity: 1;
            font-size: 11px;
            line-height: 15px;
            letter-spacing: 0.05em;
            top: 0;
            text-align: left;
        }
    }

    // FORM FILE UPLOAD
    &_file {
        input[type=file] {
            opacity: 0;
            width: 0;
            height: 0;
            appearance: none;
            position: absolute;
        }

        label {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background: $noir;
            color: $blanc;
            cursor: pointer;
            height: 40px;
            padding: 0 20px;
            font-size: 14px;

            @media (min-width:$screen-sm) {
                font-size: 15px;
                padding: 0 25px;
                height: 50px;
            }

            @media (min-width:$screen-lg) {
                font-size: 16px;
                padding: 0 30px;
                height: 60px;
            }

            &:after,
            &:before {
                content: normal;
                display: none;
            }
        }

        .name-file {
            display: block;
            max-width: 150px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    /*************************************************
    // FORMULAIRE / VALIDATION
    *************************************************/

    &_overlay {
        @include position(fixed, 1000, 0, 0, 0, 0);
        background-color: rgba($color: $noir, $alpha: .8);
        width: auto;
        transition: all .6s ease;
        cursor: pointer;

        @media (max-width:$screen-md) and (min-width: $screen-sm) {
            width: calc(100% - 30px);
            margin: auto;
        }

        &_text {
            @include position(absolute, 100, 40%, 0, auto, 0);
            margin: auto;
            width: 100%;
            height: auto;
            min-height: auto;
            display: block;
            opacity: 0;
            transform: translateY(-50%);
            transition: all .5s ease;

            @media (min-width:$screen-sm) {
                width: 75%;
            }

            @media (min-width:$screen-lg) {
                width: 60%;
            }

            p {
                line-height: 1.33;
                text-align: center;
                color: $noir;
                font-weight: 600;
                font-size: 16px;
                padding: 5vw 30px;
                background: rgba(255, 255, 255, .85);

                @media (min-width:$screen-md) {
                    font-size: 18px;
                }

                @media (min-width:$screen-lg) {
                    font-size: 20px;
                }

                @media (min-width:$screen-xl) {
                    font-size: 24px;
                }

                .icon {
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    width: 25px;
                    height: auto;
                }
            }

            &.active {
                opacity: 1;
                top: 50%;
            }
        }

        &.close {
            opacity: 0;

            .form-msg {
                opacity: 0;
                top: 60%;
            }
        }

        &.under {
            z-index: -1;
        }
    }
}