/*************************************************
// COULEURS DU SITE
*************************************************/

$blanc : #ffffff;
$noir : #000000;
$gris : #7a8285;
$jaune : #FFF852;
$gris-dark : #171313;
$noir2: #1A1A1A;
$gris-light : #d5d5d5;
$noir3 : #222222;

/*************************************************
// CLASS COLORS
*************************************************/

.jaune {
    color: $jaune !important;

    &-bg {
        background: $jaune;
        color: inherit !important;
    }
}

.blanc {
    color: $blanc !important;

    &-bg {
        background: $blanc;
        color: inherit !important;
    }
}

.gris {
    color: $gris !important;

    &-bg {
        background: $gris;
        color: inherit !important;
    }
}

.noir {
    color: $noir !important;

    &-bg {
        background: $noir;
        color: inherit !important;
    }
}