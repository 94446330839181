.p-footer {
    position: relative;
    z-index: 106;
    padding: vw(77px) 0;
    background-color: $noir;
    border-top: 1px solid $jaune;
    @media screen and (max-width:$screen-md) {
        padding: 80px 0 180px;
    }
    &_flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width:$screen-md) {
            flex-direction: column;
            gap: 48px;
        }
    }
    &_links {
        display: flex;
        align-items: center;
        gap: 18px;
        @media screen and (max-width:$screen-md) {
            flex-direction: column;
            gap: 48px;
        }
        .bouton {
            background-color: transparent;
            color: $blanc;
            font-weight: $regular;
            padding-top: 10px;
            padding-bottom: 10px;
            display: inline-flex;
            gap: vw(14px);
            height: 55px;
            font-size: 1.4rem;
            &:hover {
                background-color: $jaune;
                color: $noir;
                p {
                    color: $noir;
                }
                svg g {
                    fill: $noir;
                }
            }
        }
        .social {
            display: flex;
            align-items: center;
            gap: 18px;
        }
        .item {
            border: 1px solid $jaune;
            border-radius: 100vmax;
            width: vw(56px);
            aspect-ratio: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s ease;
            min-width: 40px;
            span {
                @include fluid-text(14px, 19px, 1, 991px, 1920px);
            }
            &:hover {
                background-color: $jaune;
                color: $noir;
            }
        }
    }
    .copyright {
        @include fluid-text(8px, 10px, 1.5, 991px, 1920px);
        @media screen and (max-width:$screen-md) {
            font-size: 10px;
        }
        a:hover {
            color: $jaune;
        }
    }
    .logo {
        width: vw(100px);
        min-width: 70px;
        @media screen and (max-width:$screen-md) {
            min-width: 100px;
        }
    }
}
