.radio {
    .p-header {
        background-image: url(../img/radio/header.jpg);

        @media screen and (max-width:$screen-md) {
            background-image: url(../img/radio/header-s.jpg);
        }
    }

    .p-nav_bottom {
        .social {
            visibility: hidden;
        }
    }

    .composant-2 {
        background-image: url(../img/radio/header.jpg);

        @media screen and (max-width:$screen-md) {
            background-image: url(../img/radio/header-s.jpg);
        }

        &_txt-p {
            white-space: normal;
            text-align: center;
        }
    }

    .creations-radios {
        position: relative;
        overflow: hidden;
        padding-top: vw(70px);
        padding-bottom: vw(30px);

        @media screen and (max-width:$screen-md) {
            padding-bottom: 40px;
            padding-top: 40px;
        }

        &_title {
            margin-bottom: vw(130px);

            @media screen and (max-width:$screen-md) {
                margin-bottom: 40px;
            }
        }

        &_iframe {
            width: 100%;

            iframe {
                border: 1px solid $noir2;
                height: 145px;
                width: 100%;
                margin-bottom: 50px;

                @media screen and (max-width:$screen-md) {
                    height: 365px;
                }
            }
        }
    }

    .savoir-faire {
        position: relative;
        padding-top: vw(145px);
        padding-bottom: vw(160px);
        overflow: hidden;



        &_title {
            margin-bottom: vw(130px);

            @media screen and (max-width:$screen-md) {
                margin-bottom: 40px;
                text-align: center;
            }
        }

        &_content {

            @media screen and (min-width:$screen-md) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                column-gap: vw(150px);
                row-gap: vw(100px);
            }

            &_item {
                @media screen and (max-width:$screen-md) {

                    &:not(:last-child) {
                        margin-bottom: 50px;
                    }
                }

                p {
                    margin-top: 2rem;

                    @media screen and (max-width:$screen-md) {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}