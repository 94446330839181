.c-blocQuestion {
    position: relative;
    overflow: hidden;
    padding-top: vw(100px);
    padding-bottom: vw(100px);

    @media screen and (max-width:$screen-md) {
        padding-top: 40px;
        padding-bottom: 40px;
        background-color: $noir;
    }

    &_content {
        position: relative;
        width: 100%;
        display: flex;
        border-radius: 2.5rem;
        overflow: hidden;

        @media screen and (max-width:$screen-md) {
            display: block;
            border-radius: 20px;
        }

        &_img {
            width: vw(635px);
            position: relative;

            @media screen and (max-width:$screen-md) {
                width: 100%;
                height: 68.23529411764706vw;
            }

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        &_textes {
            width: calc(100% - 33.072916666666664vw);
            padding: vw(70px) vw(60px);
            min-height: 29rem;

            @media screen and (max-width:$screen-md) {
                width: 100%;
                min-height: 0;
                padding: 40px 25px;
            }

            .title {
                margin-bottom: 3rem;

                @media screen and (max-width:$screen-md) {
                    margin-bottom: 30px;
                }
            }
        }
    }
}