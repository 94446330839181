.composant-1 {
    display: flex;
    flex-direction: column;
    gap: 6.9vh;
    width: 100%;
    padding: 27.7vh 23.6vw;

    @media screen and (max-width:$screen-md) {
        align-items: center;
        gap: 4.6vh;
        padding: 11.8vh 30px;
    }


    .text-top {
        position: relative;
        @include fluid-text(20px, 25px, 1.8, 991px, 1920px);
        @include fluid-text(16px, 16px, 1.8, 320px, 990px);
        margin-left: 6.7vw;
        transition: all 1s;

        @media screen and (max-width:$screen-md) {
            text-align: center;
            margin-left: 0;
        }

        &.masked {
            transform: translateY(50%);
            opacity: 0;
            transition: none;
        }
    }

    .text-bottom {
        position: relative;
        @include fluid-text(16px, 18px, 1.5, 991px, 1920px);
        @include fluid-text(14px, 14px, 1.5, 320px, 990px);
        margin-left: 20.3vw;
        width: 26.1vw;
        transition: all 1s;

        @media screen and (max-width:$screen-md) {
            text-align: center;
            margin-left: 0;
            width: 100%;
        }

        @media screen and (max-width:1500px) {
            width: 36vw;
        }

        &.masked {
            transform: translateY(50%);
            opacity: 0;
            transition: none;
        }
    }

    .title {
        position: relative;

        @media screen and (max-width:$screen-md) {
            text-align: center;
        }
    }
}