.p-menu {
    position: fixed;
    top: -140%;
    left: 0;
    z-index: 103;
    width: 100%;
    transition: all .4s ease .3s;
    background-color: $jaune;
    padding-top: 100px;

    &_content {
        height: 100%;
        padding: 9.5vh;
    }

    &_nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 5.9vh;
        @include fluid-text(16px, 26px, 1, 320px, 424px);
        font-weight: $bold;
        color: $noir;
        .bouton {
            background-color: $noir;
            color: $blanc;
            font-weight: $regular;
            padding-top: 10px;
            padding-bottom: 10px;
            display: inline-flex;
            justify-content: center;
            gap: 14px;
            font-size: 14px!important;
            width: 270px;
            height: 55px;
            img {
                width: 21.6px;
            }
            p {
                width: 145px;
                font-size: 14px!important;
            }
        }
    }

    //* Animation d'apparition des links dans le menu
    .anim-link {
        transform: translateY(100%);
        opacity: 0;
        transition: all 0.3s ease;
    }

    //* Animation à l'ouverture du menu
    &.open {
        top: 0;
        transition: all .4s ease .2s;

        .anim-link {
            transform: translateY(0);
            opacity: 1;
            transition: all 0.3s ease;

            @for $i from 1 through 30 {
                &:nth-child(#{$i}) {
                    transition-delay: 0.3s+$i*0.1s;
                }
            }
        }
    }
}