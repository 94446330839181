.articlesUne {
  position: relative;
  overflow: hidden;
  border-top: 1px solid $jaune;
  padding-top: vw(85px);
  padding-bottom: vw(74px);

  @media screen and (max-width:$screen-md) {
    padding-top: 60px;
    padding-bottom: 50px;
  }

  &_title {
    @extend .h1;
    color: $jaune;
    text-align: center;
    margin-bottom: vw(100px);

    @media screen and (max-width:$screen-md) {
      margin-bottom: 50px;
    }
  }
}