.composant-2 {
    width: 100%;
    padding: 27.7vh 16.9vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;

    @media screen and (max-width:$screen-md) {
        padding: 16.2vh 30px;
    }

    &_txt {
        @media screen and (max-width:$screen-md) {
            display: flex;
            flex-direction: column;
            gap: 5.9vh;
        }

        &-p {
            position: relative;
            @include fluid-text(25px, 45px, 1.5, 991px, 1920px);
            @include fluid-text(25px, 25px, 1.5, 320px, 990px);
            font-weight: $bold;
            color: $jaune;
            white-space: nowrap;
            transition: all 1s;

            @media screen and (max-width:$screen-md) {
                text-align: center;
                white-space: inherit;
            }

            &.masked {
                transform: translateY(50%);
                opacity: 0;
                transition: none;
            }
        }
    }
}