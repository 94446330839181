.modal-reference {
    display: block;
    bottom: calc(100% + 20px);
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    opacity: 0;
    z-index: -1;

    &_content {
        background-color: $noir;
        border: 1px solid $jaune;
        padding: vw(50px) vw(100px);
        display: flex;
        border-radius: 2rem;
        overflow: hidden;
        align-items: center;
        justify-content: center;

        .h2 {
            width: vw(250px);
            padding-right: vw(35px);
            border-right: 1px solid $jaune;
        }

        p {
            width: vw(510px);
            margin-left: vw(35px);
        }
    }

    &.open {
        opacity: 1;
        z-index: 10000;
        transition: all .3s ease;
    }
}