.accelerateurs {
    display: flex;
    align-items: end;
    justify-content: center;
    gap: vw(130px);
    padding: vw(80px) 0 vw(160px);
    @media screen and (max-width: $screen-md) {
        padding: 45px 0 90px;
        flex-direction: column;
        align-items: center;
    }
    &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 190px;
        img {
            max-width: 70vw;
            max-height: 70vw;
            object-fit: contain;
            margin-top: 60px
        }
        .h2 {
            @include fluid-text(16px, 20px, 1.1, 991px, 1920px);
            white-space: nowrap;
            @media screen and (max-width: $screen-md) {
                font-size: 20px;
            }
        }
        .play {
            border: 1px solid $jaune;
            border-radius: 100vmax;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 64px;
            aspect-ratio: 1;
            transition: all 0.3s ease;
            margin: 30px 0;
            background-color: $noir;
            &:hover {
                background-color: $jaune;
                svg path {
                    fill: $noir;
                }
            }
            svg {
                width: 20px;
                margin-left: 6px;
            }
            @media screen and (max-width: $screen-md) {
                // position: relative;
                &::before {
                    position: absolute;
                    z-index: -1;
                    content: "";
                    height: 1px;
                    width: 100vw;
                    left: 0;
                    background-color: $jaune;
                }
            }
        }
        &:first-child {
            .play::before {
                position: absolute;
                z-index: -1;
                content: "";
                height: 1px;
                width: 100vw;
                left: 0;
                background-color: $jaune;
            }
        }
    }
}
