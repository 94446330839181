.podcast {
    .p-header {
        background-image: url(../img/podcast/header.jpg);

        @media screen and (max-width:$screen-md) {
            background-image: url(../img/podcast/header-s.jpg);
        }
    }

    .p-nav_bottom {
        .social {
            visibility: hidden;
        }
    }

    .composant-2 {
        background-image: url(../img/podcast/header.jpg);

        @media screen and (max-width:$screen-md) {
            background-image: url(../img/podcast/header-s.jpg);
        }

        &_txt-p {
            white-space: normal;
            text-align: center;
        }
    }

    .derniers-podcasts {
        position: relative;
        overflow: hidden;
        padding-top: vw(70px);
        padding-bottom: vw(70px);

        @media screen and (max-width:$screen-md) {
            padding-bottom: 40px;
            padding-top: 40px;
        }

        &_title {
            margin-bottom: vw(130px);

            @media screen and (max-width:$screen-md) {
                margin-bottom: 40px;
            }
        }

        &_iframe {
            height: 480px;
        }
    }
}