.contact {

    .p-nav_bottom {
        .social {
            visibility: hidden;
        }
    }


    &_container {
        .simple-container {
            display: flex;
            padding-top: 26vh;
            padding-bottom: 20.5vh;

            @media screen and (max-width:$screen-md) {
                flex-direction: column;
                gap: 4.6vh;
                padding-bottom: 11.8vh;
            }
        }


        &-titre {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 4.6vh;
            background-color: $noir;
            width: 30vw;
            padding-right: 1.3vw;

            @media screen and (max-width:$screen-md) {
                width: 100%;
            }

            &--title {
                @include fluid-text(20px, 45px, 1.1, 991px, 1920px);
                @include fluid-text(30px, 35px, 1.1, 320px, 990px);
                color: $jaune;

                @media screen and (max-width:$screen-md) {
                    text-align: center;
                }
            }

            &--subtitle {
                @include fluid-text(16px, 25px, 1.5, 991px, 1920px);
                @include fluid-text(14px, 14px, 1.5, 320px, 990px);
                width: 18.4vw;

                @media screen and (max-width:$screen-md) {
                    width: 100%;
                    text-align: center;
                }
            }

            &--arrow {
                margin-top: auto;

                @media screen and (max-width:$screen-md) {
                    display: none;
                }

                .icon {
                    display: inline-block;
                    position: relative;
                    animation: upDown 2.5s linear infinite;
                }

                img {
                    width: 3.6vw;

                    @media screen and (max-width:$screen-md) {
                        width: 70px;
                    }
                }
            }
        }
    }

    &_map {
        display: flex;
        width: 100%;
        background-color: $jaune;

        @media screen and (max-width:$screen-md) {
            flex-direction: column;
        }

        &-titre {
            display: flex;
            flex-direction: column;
            gap: 4.1vh;
            padding: 12vh 7vw 12vh 10.1vw;
            width: 34.5vw;

            @media screen and (max-width:1500px) {
                width: 42vw;
            }

            @media screen and (max-width:$screen-md) {
                width: 100%;
                padding: 7.6vh 30px;
            }

            &--forme {
                img {
                    width: 5.2vw;
                    height: auto;

                    @media screen and (max-width:$screen-md) {
                        width: 23.5vw;
                    }
                }
            }

            &--title {
                @include fluid-text(25px, 45px, 1.1, 991px, 1920px);
                @include fluid-text(25px, 30px, 1.1, 320px, 990px);
                color: $noir;
            }

            &--adresse {
                @include fluid-text(14px, 16px, 1.5, 991px, 1920px);
                color: $noir;

                span {
                    font-weight: $bold;
                }
            }
        }

        &-map {
            display: block;
            width: 65.5vw;

            @media screen and (max-width:1500px) {
                width: 58vw;
                height: 59.2vh;
            }

            @media screen and (max-width:$screen-md) {
                width: 100%;
            }
        }
    }
}