.p-nav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 104;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;

    .logo {
        img {
            width: vw(126px);
            min-width: 72px;
        }
    }

    &_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: vw(23px) 0;
        pointer-events: all;

        @media screen and (max-width: $screen-md) {
            padding: 25px 0;
        }

        &_container {
            border-bottom: 1px solid $jaune;
            width: 100vw;
            background-color: $noir;
            opacity: 0;
            transform: translateY(-100%);
            animation: trans-opacity-bot-100 1s ease .6s forwards;
        }

        &_right {
            display: flex;
            align-items: center;
            gap: vw(26px);

            @media screen and (max-width: $screen-md) {
                gap: 20px;
            }

            .bouton {
                background-color: transparent;
                color: $blanc;
                font-weight: $regular;
                padding-top: 10px;
                padding-bottom: 10px;
                display: inline-flex;
                gap: vw(14px);
                font-size: 1.4rem;

                &:hover {
                    background-color: $jaune;
                    color: $noir;

                    p {
                        color: $noir;
                    }

                    svg g {
                        fill: $noir;
                    }
                }

                &.v-index-blog {
                    padding: 7px;

                    span {
                        height: 100%;
                        padding-left: 2.5rem;
                        padding-right: 2.5rem;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $jaune;
                        color: $noir;
                        border-radius: 2.8rem;
                    }
                }
            }

            .phone {
                border: 1px solid $jaune;
                border-radius: 100vmax;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 48px;
                aspect-ratio: 1;
            }
        }

        &_center {
            display: flex;
            align-items: center;
            gap: vw(43px);
            font-size: 1.4rem;

            @media screen and (max-width: $screen-md) {
                display: none;
            }

            li {
                a {
                    transition: all .3s ease !important;
                }

                &:hover {
                    color: $jaune;
                }
            }
        }
    }

    &_bottom {
        display: flex;
        justify-content: space-between;
        align-items: end;
        padding-bottom: vw(56px);
        opacity: 0;
        transform: translateY(100%);
        animation: trans-opacity-top-100 1s ease .6s forwards;

        @media screen and (max-width: $screen-md) {
            padding-bottom: 5vh;
        }

        .social {
            display: flex;
            align-items: center;
            gap: 18px;
            pointer-events: all;

            .item {
                border: 1px solid $jaune;
                border-radius: 100vmax;
                width: vw(56px);
                aspect-ratio: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.3s ease;
                min-width: 40px;

                @media screen and (max-width: $screen-md) {
                    display: none;
                }

                span {
                    @include fluid-text(14px, 19px, 1, 991px, 1920px);
                }

                &:hover {
                    background-color: $jaune;
                    color: $noir;
                }
            }
        }

        .contact {
            display: flex;
            align-items: center;
            justify-content: center;
            pointer-events: all;

            @media screen and (max-width: $screen-md) {
                border: 1px solid $jaune;
                background-color: $noir;
                border-radius: 100vmax;
                width: 70px;
                aspect-ratio: 1;
            }

            &-logo {
                position: absolute;
                width: vw(75px);

                @media screen and (max-width: $screen-md) {
                    width: 39px;
                }

                img{
                    width: 100%;
                    height: auto;
                }
            }

            &-text {
                width: vw(140px);
                @media screen and (max-width: $screen-md) {
                    display: none;
                }

                img{
                    width: 100%;
                    height: auto;
                }
            }

            .contact-text {
                animation: rotation 20s linear forwards infinite;
            }
        }
    }

    .bt-menu {
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        z-index: 1;

        @media screen and (min-width: $screen-md) {
            display: none;
        }

        &:after {
            padding-top: 100%;
        }

        .barre {
            transform: rotate(0deg);
            width: 32px;
            height: 2px;
            position: relative;
            transition: all 0.3s ease 0s;

            &-mid {
                position: absolute;
                width: 44%;
                height: 2px;
                background: $blanc;
                border-radius: 2px;

                &_1 {
                    left: 0;
                }

                &_2 {
                    right: 0;
                }
            }

            &::before,
            &::after {
                display: inline-block;
                width: 100%;
                height: 2px;
                top: -10px;
                background: $blanc;
                border-radius: 2px;
                content: "";
                position: absolute;
                left: 0%;
                transition: top 0.3s ease 0.3s, transform 0.3s ease;
            }

            &::after {
                top: 10px;
            }
        }

        &.masked {}

        &.open {
            .barre {
                transform: rotate(-45deg);
                transition: all 0.3s ease 0.3s;

                &-mid {
                    transition: all 0.3s ease 0.3s;

                    &_1 {
                        width: 40%;
                    }

                    &_2 {
                        width: 50%;
                    }
                }

                &::after,
                &::before {
                    top: 0;
                    transform: rotate(90deg);
                    transition: top 0.3s ease 0s, transform 0.3s ease 0.3s;
                }
            }
        }
    }
}