.error404 {

    .p-header,
    .p-nav,
    .p-footer {
        display: none;
    }

    .error{
        display : flex;
        justify-content: center;
        align-items : center;
        width : 100%;
        height : 100vh;
        background-image : url(../img/concept/header.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;


        @media screen and (max-width:$screen-md) {
            background-image : url(../img/concept/header-s.jpg);
        }

        &_container{
            display : flex;
            flex-direction: column;
            align-items : center;
            gap : 4vh;
            @media screen and (max-width:$screen-md) {
                padding : 0 30px;
            }

            &-title{
                @include fluid-text(200px, 224px, 1.1, 991px, 1920px);
                @include fluid-text(90px, 130px, 1.1, 320px, 990px);
                color : $jaune;
                text-align : center;
            }

            &-subtitle{
                @include fluid-text(30px, 30px, 1.5, 991px, 1920px);
                @include fluid-text(16px, 18px, 1.5, 320px, 990px);
                margin-bottom : 5vh;
                text-align : center;
            }

            .bouton {
                background-color: transparent;
                color: $blanc;
                font-weight: $regular;
                padding-top: 10px;
                padding-bottom: 10px;
                
                span{
                    @include fluid-text(18px, 18px, 1.1, 991px, 1920px);
                    @include fluid-text(14px, 16px, 1.1, 320px, 990px);
                    font-weight : $bold;
                }

                &:hover {
                    background-color: $jaune;
                    color: $noir;
                }
            }
        }
    } 
}