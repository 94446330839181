.mag {

    #section-top {
        overflow: hidden;
        position: relative;
    }

    .imgFloat {
        position: absolute;
        top: 10vw;
        right: -8vw;
        width: vw(979px);
        
        img{
            width: 100%;
            height: auto;
        }

        @media screen and (max-width:$screen-md) {
            display: none;
        }
    }

    &_content {
        background-color: $noir;
        position: relative;
        padding-top: 15.625vw;
        padding-bottom: 10rem;

        @media screen and (max-width:$screen-md) {
            padding-top: 200px;
            padding-bottom: 50px;
        }

        &_title {
            margin-bottom: 5rem;
            font-size: 4.5rem;
            @extend %font-bold;
            font-family: $font;
            color: $noir;

            &_sub {
                display: block;
                margin-top: 10px;
                font-size: 50%;
                color: $gris;
                @extend %font-bold;
            }
        }
    }
}