/*************************************************
// STYLES DE CARATERES
*************************************************/

.paragr,
p {
    @include fluid-text(13px, 16px, 1.5, 991px, 1920px);
    font-family: $font;
    font-weight: $regular;
    text-align: left;
    color: $blanc;
    margin: 0;

    strong {
        font-weight: $bold;
    }

    @media(max-width:$screen-md) {
        font-size: 13px;
    }

    @media(max-width:$screen-xs) {
        font-size: 12px;
    }
}

/*************************************************
// TITRES H1/H2/H3
*************************************************/


.h1 {
    margin: 0;
    font-family: $font;
    font-weight: $bold;
    @include fluid-text(30px, 45px, 1.1, 991px, 1920px);
    @include fluid-text(23px, 33px, 1.1, 320px, 390px);
}

.h2 {
    margin: 0;
    font-family: $font;
    font-weight: $bold;
    @include fluid-text(20px, 30px, 1.1, 991px, 1920px);
    @include fluid-text(20px, 30px, 1.1, 320px, 390px);
}

.h2-variable {
    font-family: $font-variable;
    margin: 0;
    @include fluid-text(50px, 115px, 1.1, 991px, 1920px);
    @include fluid-text(20px, 30px, 1.1, 320px, 390px);
    color: $jaune;
    font-variation-settings: 'wght'600, 'wdth'200;
    transition: all .5s linear;

    &.masked {
        font-variation-settings: 'wght'100, 'wdth'200;
    }
}

.h3 {
    margin: 0;
    font-family: $font;
    font-weight: $bold;
    @include fluid-text(45px, 65px, 1.1, 991px, 1920px);
    @include fluid-text(20px, 30px, 1.4, 320px, 390px);
}

.h4 {
    margin: 0;
    font-family: $font;
    font-weight: $bold;
    @include fluid-text(19px, 25px, 1.4, 991px, 1920px);
    @include fluid-text(18px, 20px, 1.4, 320px, 390px);
}