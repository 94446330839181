.carte {
    padding-top: 10rem;

    @media screen and (max-width:$screen-md) {
        padding-top: 100px;
    }

    .bigTitle {
        font-size: 4rem;

        @media screen and (max-width:$screen-md) {
            font-size: 30px;
        }
    }

    .simple-container {
        padding-bottom: 10rem;

        @media screen and (max-width:$screen-md) {
            padding-bottom: 100px;
        }
    }

    &_content {
        padding-top: 10rem;

        @media screen and (max-width:$screen-md) {
            padding-top: 100px;
        }

        .carte_group {
            border-bottom: 1px solid $noir;
            padding-top: 3rem;
            padding-bottom: 3rem;

            @media screen and (max-width:$screen-md) {
                padding-top: 20px;
                padding-bottom: 20px;
            }

            &:first-child {
                border-top: 1px solid $noir;
            }

            &_btn {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;

                .plus {
                    display: block;
                    width: 3rem;
                    height: 3rem;
                    position: relative;

                    @media screen and (max-width:$screen-md) {
                        width: 20px;
                        height: 20px;
                    }

                    &::after,
                    &::before {
                        display: block;
                        width: 100%;
                        height: 3px;
                        background-color: $noir;
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%);
                    }

                    &::after {
                        transform: translateX(-50%) translateY(-50%) rotate(90deg);
                    }
                }
            }

            &_content {
                overflow: hidden;

                @media screen and (max-width:$screen-md) {
                    padding-top: 20px;
                }
            }

            &_title {
                font-size: 3rem;
                font-weight: bold;
                margin: 0;

                @media screen and (max-width:$screen-md) {
                    font-size: 18px;
                }
            }

            .carte_product {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                margin-bottom: 2rem;

                @media screen and (max-width:$screen-md) {
                    margin-bottom: 10px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                .name {
                    font-size: 1.8rem;

                    @media screen and (max-width:$screen-md) {
                        font-size: 14px;
                    }

                    .option {
                        font-size: 1.2rem;
                        font-style: italic;

                        @media screen and (max-width:$screen-md) {
                            font-size: 12px;
                        }
                    }
                }

                .desc {
                    font-size: 1.4rem;
                    margin-top: 1rem;

                    @media screen and (max-width:$screen-md) {
                        font-size: 14px;
                    }
                }

                .price {
                    font-size: 1.6rem;
                    font-weight: bold;

                    @media screen and (max-width:$screen-md) {
                        font-size: 14px;
                    }
                }
            }
        }

        .carte_menu {
            .carte_selection {
                margin-top: 3rem;

                @media screen and (max-width:$screen-md) {
                    margin-top: 20px;
                }

                .title {
                    font-size: 2.2rem;
                    margin-bottom: 2rem;
                    padding-bottom: 1rem;
                    border-bottom: 1px solid $noir;

                    @media screen and (max-width:$screen-md) {
                        font-size: 14px;
                        margin-bottom: 20px;
                        padding-bottom: 10px;
                    }
                }
            }
        }

        .colum-prices {

            &_name {
                margin-bottom: 2rem;

                @media screen and (max-width:$screen-md) {
                    margin-bottom: 20px;
                }
            }

            &_name,
            &_content {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                span {
                    width: 10rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @media screen and (max-width:$screen-md) {
                        width: 60px;
                    }
                }
            }
        }

        .categorieTitles {
            margin-bottom: 5rem;
            padding-top: 3rem;

            @media screen and (max-width:$screen-md) {
                margin-bottom: 40px;
            }

            &_title1 {
                font-size: 2.2rem;
                margin-bottom: 2rem;
                font-weight: $bold;
                color: $noir;

                @media screen and (max-width:$screen-md) {
                    font-size: 16px;
                    margin-bottom: 20px;
                }
            }

            &_title2 {
                font-size: 1.8rem;
                margin-bottom: 3rem;

                @media screen and (max-width:$screen-md) {
                    font-size: 14px;
                    margin-bottom: 15px;
                }
            }
        }

        .vMenu {
            padding-top: 3rem;

            &_title1 {
                font-size: 2.2rem;
                margin-bottom: 2rem;
                font-weight: $bold;
                color: $noir;

                @media screen and (max-width:$screen-md) {
                    font-size: 16px;
                    margin-bottom: 20px;
                }
            }

            &_title2 {
                font-size: 1.8rem;
                margin-bottom: 3rem;

                @media screen and (max-width:$screen-md) {
                    font-size: 14px;
                    margin-bottom: 15px;
                }
            }

            &_price {
                font-size: 2.4rem;
                font-weight: $bold;
                color: $noir;

                @media screen and (max-width:$screen-md) {
                    font-size: 18px;
                }
            }
        }

    }
}
