.legals {

    #section-top {
        padding: 20vh 0;
    }

    .simple-container {
        display: flex;
        flex-direction: column;
        gap: 20vh;
    }

    &_container {
        display: flex;
        flex-direction: column;
        gap: 10vh;
    }

    &_titre {
        font-weight: $bold;
        font-size: 50px;
        color: $jaune;
        margin-bottom: 5vh;
        text-align: center;

        @media screen and (max-width:$screen-md) {
            font-size: 30px;
        }
    }

    &_subtitle {
        font-weight: $bold;
        font-size: 35px;
        color: $blanc;
        margin-bottom: 2.5vh;

        @media screen and (max-width:$screen-md) {
            font-size: 25px;
        }
    }

    &_bloc {
        display: flex;
        flex-direction: column;
        gap: 2.5vh;

        p {
            color: $blanc;

            &.legals_bloc-code {
                font-weight: $bold;
                color: $blanc;
                font-size: 25px;
                font-style: italic;

                @media screen and (max-width:$screen-md) {
                    font-size: 20px;
                }
            }

            span {
                &.strong {
                    font-weight: $bold;
                }
            }
        }
    }


}