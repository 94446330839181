.p-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    animation: opacity 1.5s linear forwards;
    z-index: 100;

    @media screen and (max-width:$screen-md) {
        height: var(--heightJs);
        padding: 0 30px;
    }

    &_titre {
        display: flex;
        flex-direction: column;
        gap: 2.3vh;

        &-subtitle {
            @include fluid-text(20px, 30px, 1.1, 991px, 1920px);
            @include fluid-text(12px, 14px, 1.1, 320px, 990px);
            font-weight: $regular;
            text-align: center;
            opacity: 0;
            animation: trans-opacity-bot-100 .7s ease .3s forwards;
        }

        &-title {
            @include fluid-text(70px, 100px, 1.1, 991px, 1920px);
            @include fluid-text(30px, 36px, 1.1, 320px, 990px);
            font-weight: $black;
            color: $jaune;
            text-align: center;
            opacity: 0;
            animation: trans-opacity-top-100 .7s ease .8s forwards;
        }
    }

    &_arrow {
        position: absolute;
        bottom: 11.1vh;
        left: 50%;
        transform: translateX(-50%);

        @media screen and (max-width:$screen-md) {
            left: 30px;
            bottom: 5vh;
            transform: none;
        }

        .icon {
            display: inline-block;

            @media screen and (min-width:$screen-md) {
                animation: upDown 2s linear infinite;
            }
        }

        img {
            width: 3.6vw;
            transform: scale(1);
            transition: all .3s ease;

            @media screen and (max-width:$screen-md) {
                width: 70px;
            }
        }

        &:hover {
            img {
                transform: scale(1.1);
                transition: all .3s ease;
            }
        }
    }
}